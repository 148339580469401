// 'Clinburg': { 'cor_s': 0, 'cor_g':120, 'laa':4.5, 'lag':4.5, 'lbt':2, 'lcr':2.5, 'lca':1.2, 'lcl':1.5, 'lcw':1, 'lvc':2.2, 'lck':2.8, 'lsf':2, 'lmc':2.8, 'lpf':1.5, 'lcs':2.5, 'gor':4.4, 'grc':4.4, 'gti':4.2, 'gbb':4.1, 'gbs':4.4, 'gbd':4.1, 'gbk':2.1, 'gbm':4, 'gdt':1.9, 'gfc':2.4, 'gfm':3.9, 'gtf':4.1, 'gft':5.1, 'gge':4.3, 'ghf':3.9, 'gin':3.4, 'gpm':2.4, 'gri':3.7, 'grp':3.9, 'gsw':4.4, 'gtm':4.1, 'chy':3.6, 'rtp':1.9},
// 'Histic Humaquep': { 'cor_s': 0, 'cor_g':150, 'laa':2.5, 'lag':2, 'lbt':0.2, 'lcr':0.2, 'lca':0.3, 'lcl':0.2, 'lcw':0.2, 'lvc':0.2, 'lck':1, 'lsf':0.1, 'lmc':0.5, 'lpf':0.5, 'lcs':0.5, 'gor':4.5, 'grc':4.8, 'gti':4.8, 'gbb':4.3, 'gbs':3.9, 'gbd':3.5, 'gbk':3, 'gbm':4, 'gdt':3, 'gfc':4, 'gfm':4, 'gtf':4.7, 'gft':4.9, 'gge':4.4, 'ghf':4.3, 'gin':3.5, 'gpm':2.8, 'gri':4.3, 'grp':4.3, 'gsw':4.4, 'gtm':4.2, 'chy':4, 'rtp':3},
// 'Housatonic': { 'cor_s': 0, 'cor_g':115, 'laa':4.5, 'lag':4.5, 'lbt':2, 'lcr':3, 'lca':3, 'lcl':2.5, 'lcw':2, 'lvc':2, 'lck':3, 'lsf':2, 'lmc':3, 'lpf':1.5, 'lcs':2.5, 'gor':4.2, 'grc':4.2, 'gti':4.2, 'gbb':3.9, 'gbs':4.1, 'gbd':3.9, 'gbk':1.9, 'gbm':3.8, 'gdt':2.2, 'gfc':2.7, 'gfm':3.7, 'gtf':3.9, 'gft':4.9, 'gge':4.1, 'ghf':3.7, 'gin':3.2, 'gpm':2.4, 'gri':3.5, 'grp':3.7, 'gsw':4.2, 'gtm':3.9, 'chy':3.4, 'rtp':1.9},
// 'Humaquepts': { 'cor_s': 0, 'cor_g':150, 'laa':2.5, 'lag':2, 'lbt':0.2, 'lcr':0.2, 'lca':0.3, 'lcl':0.2, 'lcw':0.2, 'lvc':0.2, 'lck':1, 'lsf':0.1, 'lmc':0.5, 'lpf':0.5, 'lcs':0.5, 'gor':4.5, 'grc':4.8, 'gti':4.8, 'gbb':4.3, 'gbs':3.9, 'gbd':3.5, 'gbk':3, 'gbm':4, 'gdt':3, 'gfc':4, 'gfm':4, 'gtf':4.7, 'gft':4.9, 'gge':4.4, 'ghf':4.3, 'gin':3.5, 'gpm':2.8, 'gri':4.3, 'grp':4.3, 'gsw':4.4, 'gtm':4.2, 'chy':4, 'rtp':3},
// 'Humaquepts-Fibr': { 'cor_s': 0, 'cor_g':150, 'laa':2.5, 'lag':2, 'lbt':0.2, 'lcr':0.2, 'lca':0.3, 'lcl':0.2, 'lcw':0.2, 'lvc':0.2, 'lck':1, 'lsf':0.1, 'lmc':0.5, 'lpf':0.5, 'lcs':0.5, 'gor':4.5, 'grc':4.8, 'gti':4.8, 'gbb':4.3, 'gbs':3.9, 'gbd':3.5, 'gbk':3, 'gbm':4, 'gdt':3, 'gfc':4, 'gfm':4, 'gtf':4.7, 'gft':4.9, 'gge':4.4, 'ghf':4.3, 'gin':3.5, 'gpm':2.8, 'gri':4.3, 'grp':4.3, 'gsw':4.4, 'gtm':4.2, 'chy':4, 'rtp':3},
// 'Hunter': { 'cor_s': 0, 'cor_g':125, 'laa':4.5, 'lag':4.5, 'lbt':2, 'lcr':2.5, 'lca':1.2, 'lcl':1.5, 'lcw':1, 'lvc':2.2, 'lck':2.8, 'lsf':2, 'lmc':2.8, 'lpf':1.5, 'lcs':2.5, 'gor':4.6, 'grc':4.6, 'gti':4.4, 'gbb':4.3, 'gbs':4.6, 'gbd':4.3, 'gbk':2.3, 'gbm':4.2, 'gdt':2.1, 'gfc':2.6, 'gfm':4.1, 'gtf':4.3, 'gft':5.3, 'gge':4.5, 'ghf':4.1, 'gin':3.6, 'gpm':2.6, 'gri':3.9, 'grp':4.1, 'gsw':4.6, 'gtm':4.3, 'chy':3.8, 'rtp':2.1},
// 'Hydraquents': { 'cor_s': 0, 'cor_g':150, 'laa':2.5, 'lag':2, 'lbt':0.2, 'lcr':0.2, 'lca':0.3, 'lcl':0.2, 'lcw':0.2, 'lvc':0.2, 'lck':1, 'lsf':0.1, 'lmc':0.5, 'lpf':0.5, 'lcs':0.5, 'gor':4.5, 'grc':4.8, 'gti':4.8, 'gbb':4.3, 'gbs':3.9, 'gbd':3.5, 'gbk':3, 'gbm':4, 'gdt':3, 'gfc':4, 'gfm':4, 'gtf':4.7, 'gft':4.9, 'gge':4.4, 'ghf':4.3, 'gin':3.5, 'gpm':2.8, 'gri':4.3, 'grp':4.3, 'gsw':4.4, 'gtm':4.2, 'chy':4, 'rtp':3},
// 'Hydraquents-Med': { 'cor_s': 0, 'cor_g':150, 'laa':2.5, 'lag':2, 'lbt':0.2, 'lcr':0.2, 'lca':0.3, 'lcl':0.2, 'lcw':0.2, 'lvc':0.2, 'lck':1, 'lsf':0.1, 'lmc':0.5, 'lpf':0.5, 'lcs':0.5, 'gor':4.5, 'grc':4.8, 'gti':4.8, 'gbb':4.3, 'gbs':3.9, 'gbd':3.5, 'gbk':3, 'gbm':4, 'gdt':3, 'gfc':4, 'gfm':4, 'gtf':4.7, 'gft':4.9, 'gge':4.4, 'ghf':4.3, 'gin':3.5, 'gpm':2.8, 'gri':4.3, 'grp':4.3, 'gsw':4.4, 'gtm':4.2, 'chy':4, 'rtp':3},
// 'Londonderry': { 'cor_s': 0, 'cor_g':null, 'laa':0, 'lag':0, 'lbt':0.2, 'lcr':0.2, 'lca':0.2, 'lcl':0.1, 'lcw':0.1, 'lvc':0.1, 'lck':0.1, 'lsf':0.1, 'lmc':0.1, 'lpf':0.3, 'lcs':0, 'gor':0.1, 'grc':0.1, 'gti':0.1, 'gbb':0.1, 'gbs':0.1, 'gbd':0.1, 'gbk':0.1, 'gbm':0.1, 'gdt':2, 'gfc':0.2, 'gfm':0.1, 'gtf':0.3, 'gft':0.1, 'gge':0.1, 'ghf':0.1, 'gin':2.5, 'gpm':0.1, 'gri':0.1, 'grp':0.1, 'gsw':0.1, 'gtm':0.1, 'chy':0.1, 'rtp':0.1},
// 'Made Land': { 'cor_s': 0, 'cor_g':115, 'laa':4.3, 'lag':0, 'lbt':1.8, 'lcr':2.8, 'lca':2.8, 'lcl':2.3, 'lcw':1.8, 'lvc':1.8, 'lck':2.8, 'lsf':1.8, 'lmc':2.8, 'lpf':1.3, 'lcs':2.3, 'gor':4.2, 'grc':4.2, 'gti':4.2, 'gbb':3.9, 'gbs':4.1, 'gbd':0.1, 'gbk':1.9, 'gbm':3.8, 'gdt':2.2, 'gfc':2.7, 'gfm':3.7, 'gtf':3.9, 'gft':4.9, 'gge':4.1, 'ghf':3.7, 'gin':3.2, 'gpm':2.4, 'gri':3.5, 'grp':3.7, 'gsw':4.2, 'gtm':3.9, 'chy':3.4, 'rtp':1.9},
// 'Medihmeists-Hyd': { 'cor_s': 0, 'cor_g':150, 'laa':2.5, 'lag':2, 'lbt':0.2, 'lcr':0.2, 'lca':0.3, 'lcl':0.2, 'lcw':0.2, 'lvc':0.2, 'lck':1, 'lsf':0.1, 'lmc':0.5, 'lpf':0.5, 'lcs':0.5, 'gor':4.5, 'grc':4.8, 'gti':4.8, 'gbb':4.3, 'gbs':3.9, 'gbd':3.5, 'gbk':3, 'gbm':4, 'gdt':3, 'gfc':4, 'gfm':4, 'gtf':4.7, 'gft':4.9, 'gge':4.4, 'ghf':4.3, 'gin':3.5, 'gpm':2.8, 'gri':4.3, 'grp':4.3, 'gsw':4.4, 'gtm':4.2, 'chy':4, 'rtp':3},
// 'Mohican': { 'cor_s': 0, 'cor_g':140, 'laa':5.5, 'lag':6, 'lbt':3, 'lcr':3.5, 'lca':2.2, 'lcl':2.5, 'lcw':2.5, 'lvc':3.2, 'lck':3.8, 'lsf':3, 'lmc':3.8, 'lpf':2.5, 'lcs':3.5, 'gor':5.1, 'grc':5.1, 'gti':4.9, 'gbb':4.8, 'gbs':5.1, 'gbd':4.8, 'gbk':2.8, 'gbm':4.7, 'gdt':2.6, 'gfc':3.1, 'gfm':4.6, 'gtf':4.8, 'gft':5.8, 'gge':5, 'ghf':4.6, 'gin':4.1, 'gpm':3.1, 'gri':4.4, 'grp':4.6, 'gsw':5.1, 'gtm':4.8, 'chy':4.3, 'rtp':2.6},
// 'Otsego': { 'cor_s': 0, 'cor_g':120, 'laa':5, 'lag':5, 'lbt':2.5, 'lcr':3, 'lca':1.7, 'lcl':2, 'lcw':2, 'lvc':2.7, 'lck':3.3, 'lsf':2.5, 'lmc':3.3, 'lpf':2, 'lcs':3, 'gor':4.4, 'grc':4.4, 'gti':4.2, 'gbb':4.1, 'gbs':4.4, 'gbd':4.1, 'gbk':2.1, 'gbm':4, 'gdt':1.9, 'gfc':2.4, 'gfm':3.9, 'gtf':4.1, 'gft':5.1, 'gge':4.3, 'ghf':3.9, 'gin':3.4, 'gpm':2.4, 'gri':3.7, 'grp':3.9, 'gsw':4.4, 'gtm':4.1, 'chy':3.6, 'rtp':1.9},
// 'Rock-Londonderr': { 'cor_s': 0, 'cor_g':60, 'laa':2.5, 'lag':2.5, 'lbt':0.2, 'lcr':0.2, 'lca':0.2, 'lcl':0.1, 'lcw':0.1, 'lvc':0.5, 'lck':0.8, 'lsf':1, 'lmc':0.8, 'lpf':0.5, 'lcs':1.5, 'gor':2, 'grc':2.7, 'gti':1.9, 'gbb':2.1, 'gbs':2.5, 'gbd':0.1, 'gbk':0.1, 'gbm':1.8, 'gdt':0.2, 'gfc':0.2, 'gfm':1.2, 'gtf':1.7, 'gft':2.7, 'gge':1.9, 'ghf':1.9, 'gin':1.4, 'gpm':0.5, 'gri':1.7, 'grp':1.9, 'gsw':2.5, 'gtm':1.7, 'chy':1.8, 'rtp':0.1},
// 'Rock-Ricker-Hog': { 'cor_s': 0, 'cor_g':60, 'laa':2.5, 'lag':2.5, 'lbt':0.2, 'lcr':0.2, 'lca':0.2, 'lcl':0.1, 'lcw':0.1, 'lvc':0.5, 'lck':0.8, 'lsf':1, 'lmc':0.8, 'lpf':0.5, 'lcs':1.5, 'gor':2, 'grc':2.7, 'gti':1.9, 'gbb':2.1, 'gbs':2.5, 'gbd':0.1, 'gbk':0.1, 'gbm':1.8, 'gdt':0.2, 'gfc':0.2, 'gfm':1.2, 'gtf':1.7, 'gft':2.7, 'gge':1.9, 'ghf':1.9, 'gin':1.4, 'gpm':0.5, 'gri':1.7, 'grp':1.9, 'gsw':2.5, 'gtm':1.7, 'chy':1.8, 'rtp':0.1},
// 'Rock-Ricker-Lym': { 'cor_s': 0, 'cor_g':60, 'laa':2.5, 'lag':2.5, 'lbt':0.2, 'lcr':0.2, 'lca':0.2, 'lcl':0.1, 'lcw':0.1, 'lvc':0.5, 'lck':0.8, 'lsf':1, 'lmc':0.8, 'lpf':0.5, 'lcs':1.5, 'gor':2, 'grc':2.7, 'gti':1.9, 'gbb':2.1, 'gbs':2.5, 'gbd':0.1, 'gbk':0.1, 'gbm':1.8, 'gdt':0.2, 'gfc':0.2, 'gfm':1.2, 'gtf':1.7, 'gft':2.7, 'gge':1.9, 'ghf':1.9, 'gin':1.4, 'gpm':0.5, 'gri':1.7, 'grp':1.9, 'gsw':2.5, 'gtm':1.7, 'chy':1.8, 'rtp':0.1},
// 'Waumbeck-Hermon': { 'cor_s': 0, 'cor_g':105, 'laa':5, 'lag':0, 'lbt':2.5, 'lcr':3, 'lca':1.7, 'lcl':2, 'lcw':2, 'lvc':2.7, 'lck':3.3, 'lsf':2.5, 'lmc':3.3, 'lpf':2, 'lcs':3, 'gor':3.9, 'grc':3.9, 'gti':3.7, 'gbb':3.6, 'gbs':3.9, 'gbd':0.1, 'gbk':1.6, 'gbm':3.5, 'gdt':1.4, 'gfc':1.9, 'gfm':3.4, 'gtf':3.6, 'gft':4.6, 'gge':3.8, 'ghf':3.4, 'gin':2.9, 'gpm':1.9, 'gri':3.2, 'grp':3.4, 'gsw':3.9, 'gtm':3.6, 'chy':3.1, 'rtp':1.4},
// 'Woodland': { 'cor_s': 0, 'cor_g':100, 'laa':4.5, 'lag':5, 'lbt':2, 'lcr':2.5, 'lca':1.2, 'lcl':1.5, 'lcw':1, 'lvc':2.2, 'lck':2.8, 'lsf':2, 'lmc':2.8, 'lpf':1.5, 'lcs':2.5, 'gor':3.7, 'grc':3.7, 'gti':3.5, 'gbb':3.4, 'gbs':3.7, 'gbd':3.4, 'gbk':1.4, 'gbm':3.3, 'gdt':1.2, 'gfc':1.7, 'gfm':3.2, 'gtf':3.4, 'gft':4.4, 'gge':3.6, 'ghf':3.2, 'gin':2.7, 'gpm':1.7, 'gri':3, 'grp':3.2, 'gsw':3.7, 'gtm':3.4, 'chy':2.9, 'rtp':1.2},

//********************************************************************************************************************************************************************************** *//
// During the corn yield update of this database on 04/20/2022 it was discovered that the above soils were outdated or not found in NY. The records have been kept just in case, but are no longer active in the tool.

const y_drained = {
  Acton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Adams: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.5,
    grc: 3.5,
    gti: 3.3,
    gbb: 3.2,
    gbs: 3.5,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1,
    gfc: 1.5,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.5,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1,
  },
  Adirondack: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Adjidaumo: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Adrian: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 2.5,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.4,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.2,
    gbs: 2.8,
    gbd: 2.4,
    gbk: 1.9,
    gbm: 2.9,
    gdt: 1.9,
    gfc: 2.9,
    gfm: 2.9,
    gtf: 3.6,
    gft: 3.8,
    gge: 3.3,
    ghf: 3.2,
    gin: 2.4,
    gpm: 1.7,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.3,
    gtm: 3.1,
    chy: 2.9,
    rtp: 1.9,
  },
  Agawam: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Albia: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Albrights: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Alden: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Allagash: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Allard: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Allendale: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.4,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Allis: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4.5,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  'Alluvial Land': {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Almond: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1.5,
    lca: 1.5,
    lcl: 1,
    lcw: 0.5,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.3,
    lcs: 1,
    gor: 3.5,
    grc: 3.5,
    gti: 3.5,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 2,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.7,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1.2,
  },
  Alps: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Altmar: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Alton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Amboy: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Amenia: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Angola: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Appleton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Arkport: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Armagh: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Arnot: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Ashville: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 4,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.5,
    grc: 2.8,
    gti: 2.8,
    gbb: 2.3,
    gbs: 1.9,
    gbd: 2.8,
    gbk: 1,
    gbm: 2,
    gdt: 1,
    gfc: 2,
    gfm: 2,
    gtf: 2.7,
    gft: 2.9,
    gge: 2.4,
    ghf: 2.3,
    gin: 1.5,
    gpm: 0.8,
    gri: 2.3,
    grp: 2.3,
    gsw: 2.4,
    gtm: 2.2,
    chy: 2,
    rtp: 1,
  },
  Atherton: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Atkins: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.9,
    grc: 3.2,
    gti: 3.2,
    gbb: 2.7,
    gbs: 2.3,
    gbd: 3.2,
    gbk: 1.4,
    gbm: 2.4,
    gdt: 1.4,
    gfc: 2.4,
    gfm: 2.4,
    gtf: 3.1,
    gft: 3.3,
    gge: 2.8,
    ghf: 2.7,
    gin: 1.9,
    gpm: 1.2,
    gri: 2.7,
    grp: 2.7,
    gsw: 2.8,
    gtm: 2.6,
    chy: 2.4,
    rtp: 1.4,
  },
  Atsion: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  'Au Gres': {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Aurelie: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Aurora: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Barbour: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Barcelona: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Barre: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.9,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Bash: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.5,
    gbs: 4.7,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.8,
    gfc: 3.3,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 3,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.5,
  },
  Basher: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 3,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Bath: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Becket: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },

  Becraft: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 5.5,
    lag: 6,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.5,
    grc: 5.5,
    gti: 5.3,
    gbb: 5.2,
    gbs: 5.5,
    gbd: 5.2,
    gbk: 3.2,
    gbm: 5.1,
    gdt: 3,
    gfc: 3.5,
    gfm: 5,
    gtf: 5.2,
    gft: 6.2,
    gge: 5.4,
    ghf: 5,
    gin: 4.5,
    gpm: 3.5,
    gri: 4.8,
    grp: 5,
    gsw: 5.5,
    gtm: 5.2,
    chy: 4.7,
    rtp: 3,
  },
  Belgrade: {
    cor_s: 19.0,
    cor_g: 170,
    laa: 5.5,
    lag: 6,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.3,
    grc: 5.3,
    gti: 5.1,
    gbb: 5,
    gbs: 5.3,
    gbd: 5,
    gbk: 3,
    gbm: 4.9,
    gdt: 2.8,
    gfc: 3.3,
    gfm: 4.8,
    gtf: 5,
    gft: 6,
    gge: 5.2,
    ghf: 4.8,
    gin: 4.3,
    gpm: 3.3,
    gri: 4.6,
    grp: 4.8,
    gsw: 5.3,
    gtm: 5,
    chy: 4.5,
    rtp: 2.8,
  },
  Benson: {
    cor_s: 13.0,
    cor_g: 120,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.7,
    grc: 3.4,
    gti: 2.6,
    gbb: 2.8,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 0.4,
    gbm: 2.5,
    gdt: 0.9,
    gfc: 0.9,
    gfm: 1.9,
    gtf: 2.4,
    gft: 3.4,
    gge: 2.6,
    ghf: 2.6,
    gin: 2.1,
    gpm: 1.2,
    gri: 2.4,
    grp: 2.6,
    gsw: 3.2,
    gtm: 2.4,
    chy: 2.5,
    rtp: 0.7,
  },
  Berkshire: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Bernardston: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Berrien: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Berryland: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Beseman: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Bice: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Biddeford: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.5,
    grc: 2.8,
    gti: 2.8,
    gbb: 2.3,
    gbs: 1.9,
    gbd: 2.8,
    gbk: 1,
    gbm: 2,
    gdt: 1,
    gfc: 2,
    gfm: 2,
    gtf: 2.7,
    gft: 2.9,
    gge: 2.4,
    ghf: 2.3,
    gin: 1.5,
    gpm: 0.8,
    gri: 2.3,
    grp: 2.3,
    gsw: 2.4,
    gtm: 2.2,
    chy: 2,
    rtp: 1,
  },
  Birdsall: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Blasdell: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Bombay: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Bonaparte: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.5,
    grc: 4.2,
    gti: 3.4,
    gbb: 3.6,
    gbs: 4,
    gbd: 3.6,
    gbk: 1.2,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 1.7,
    gfm: 2.7,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2,
    gri: 3.2,
    grp: 3.4,
    gsw: 4,
    gtm: 3.2,
    chy: 3.3,
    rtp: 1.5,
  },
  Bono: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 2.5,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 2.7,
    grc: 3,
    gti: 3,
    gbb: 2.5,
    gbs: 2.1,
    gbd: 1.7,
    gbk: 1.2,
    gbm: 2.2,
    gdt: 1.2,
    gfc: 2.2,
    gfm: 2.2,
    gtf: 2.9,
    gft: 3.1,
    gge: 2.6,
    ghf: 2.5,
    gin: 1.7,
    gpm: 1,
    gri: 2.5,
    grp: 2.5,
    gsw: 2.6,
    gtm: 2.4,
    chy: 2.2,
    rtp: 1.2,
  },
  Boots: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Borosaprists: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Boynton: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Braceville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Brayton: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Bridgehampton: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.5,
    grc: 5.5,
    gti: 5.3,
    gbb: 5.2,
    gbs: 5.5,
    gbd: 5.2,
    gbk: 3.2,
    gbm: 5.1,
    gdt: 3,
    gfc: 3.5,
    gfm: 5,
    gtf: 5.2,
    gft: 6.2,
    gge: 5.4,
    ghf: 5,
    gin: 4.5,
    gpm: 3.5,
    gri: 4.8,
    grp: 5,
    gsw: 5.5,
    gtm: 5.2,
    chy: 4.7,
    rtp: 3,
  },
  Bridport: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Briggs: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Brinkerton: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Broadalbin: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Brockport: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Brookfield: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Buckland: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 1,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.3,
    grc: 3.3,
    gti: 3.1,
    gbb: 3,
    gbs: 3.3,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 0.8,
  },
  Bucksport: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Budd: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Burdett: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Burnham: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Busti: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Buxton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Cambria: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Cambridge: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Camillus: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Camroden: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Canaan: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4.5,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.5,
    lck: 2.8,
    lsf: 3,
    lmc: 2.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },

  'Canaan-Rock Out': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  Canadice: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.6,
    grc: 4.2,
    gti: 4,
    gbb: 3.3,
    gbs: 3.5,
    gbd: 3.3,
    gbk: 2,
    gbm: 3.6,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.5,
    gtf: 3.7,
    gft: 3.8,
    gge: 3.5,
    ghf: 3.3,
    gin: 2.9,
    gpm: 2,
    gri: 3.3,
    grp: 3.3,
    gsw: 4,
    gtm: 3.7,
    chy: 3,
    rtp: 1.9,
  },
  Canandaigua: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.6,
    grc: 4.2,
    gti: 4,
    gbb: 3.3,
    gbs: 3.5,
    gbd: 3.3,
    gbk: 2,
    gbm: 3.6,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.5,
    gtf: 3.7,
    gft: 3.8,
    gge: 3.5,
    ghf: 3.3,
    gin: 2.9,
    gpm: 2,
    gri: 3.3,
    grp: 3.3,
    gsw: 4,
    gtm: 3.7,
    chy: 3,
    rtp: 1.9,
  },
  Canaseraga: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Canastota: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Caneadea: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Canfield: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Canton: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Carbondale: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Carlisle: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 2.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Carrollton: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Carver: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  'Carver-Plymouth': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  Castile: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Cathro: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  'Cathro-Greenwoo': {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Cattaraugus: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Cavode: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Cayuga: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Cazenovia: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Ceresco: {
    cor_s: 19.0,
    cor_g: 170,
    laa: 5.5,
    lag: 6,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.3,
    grc: 5.3,
    gti: 5.1,
    gbb: 5,
    gbs: 5.3,
    gbd: 5,
    gbk: 3,
    gbm: 4.9,
    gdt: 2.8,
    gfc: 3.3,
    gfm: 4.8,
    gtf: 5,
    gft: 6,
    gge: 5.2,
    ghf: 4.8,
    gin: 4.3,
    gpm: 3.3,
    gri: 4.6,
    grp: 4.8,
    gsw: 5.3,
    gtm: 5,
    chy: 4.5,
    rtp: 2.8,
  },
  Chadakoin: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Chagrin: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Champlain: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  Charles: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3.3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },
  Charlton: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Chatfield: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.5,
    grc: 4.2,
    gti: 3.4,
    gbb: 3.6,
    gbs: 4,
    gbd: 3.6,
    gbk: 1.2,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 1.7,
    gfm: 2.7,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2,
    gri: 3.2,
    grp: 3.4,
    gsw: 4,
    gtm: 3.2,
    chy: 3.3,
    rtp: 1.5,
  },
  Chatfield2: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Chaumont: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1.5,
    lca: 1.5,
    lcl: 1,
    lcw: 0.5,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.3,
    lcs: 1,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Chautauqua: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Cheektowaga: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 4,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Chenango: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Cheshire: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Chippeny: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 4.4,
    grc: 5,
    gti: 4.8,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.8,
    gbm: 4.4,
    gdt: 2.7,
    gfc: 3.2,
    gfm: 4.3,
    gtf: 4.5,
    gft: 4.6,
    gge: 4.3,
    ghf: 4.1,
    gin: 3.7,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.1,
    gsw: 4.8,
    gtm: 4.5,
    chy: 3.8,
    rtp: 2.7,
  },
  Chippewa: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Churchville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Cicero: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Clarkson: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 3,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Claverack: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },

  Clymer: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Cohoctah: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 4,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.4,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Collamer: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 3,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Colonie: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Colosse: {
    cor_s: 11.0,
    cor_g: 105,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.4,
    grc: 3.1,
    gti: 2.3,
    gbb: 2.5,
    gbs: 2.9,
    gbd: 2.5,
    gbk: 0.1,
    gbm: 2.2,
    gdt: 0.6,
    gfc: 0.6,
    gfm: 1.6,
    gtf: 2.1,
    gft: 3.1,
    gge: 2.3,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.9,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.9,
    gtm: 2.1,
    chy: 2.2,
    rtp: 0.4,
  },
  Colrain: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Colton: {
    cor_s: 13.5,
    cor_g: 125,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.9,
    grc: 3.6,
    gti: 2.8,
    gbb: 3,
    gbs: 3.4,
    gbd: 3,
    gbk: 0.6,
    gbm: 2.7,
    gdt: 1.1,
    gfc: 1.1,
    gfm: 2.1,
    gtf: 2.6,
    gft: 3.6,
    gge: 2.8,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.4,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.4,
    gtm: 2.6,
    chy: 2.7,
    rtp: 0.9,
  },
  Colwood: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.6,
    grc: 4.2,
    gti: 4,
    gbb: 3.3,
    gbs: 3.5,
    gbd: 3.3,
    gbk: 2,
    gbm: 3.6,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.5,
    gtf: 3.7,
    gft: 3.8,
    gge: 3.5,
    ghf: 3.3,
    gin: 2.9,
    gpm: 2,
    gri: 3.3,
    grp: 3.3,
    gsw: 3.7,
    gtm: 3.7,
    chy: 3,
    rtp: 1.9,
  },
  Conesus: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Conotton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Constable: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Cook: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Copake: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 3,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Cornish: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Cosad: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Cossayuna: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Covert: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Coveytown: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Covington: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 2.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1,
    lca: 0.5,
    lcl: 1,
    lcw: 1,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.5,
    lcs: 1,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Crary: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Croghan: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Culvers: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Dalbo: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Dalton: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Danley: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Dannemora: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3.3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },
  Darien: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Dawson: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Deerfield: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Deford: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.4,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Dekalb: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Depeyster: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 3,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Deposit: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Derb: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Dixmont: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Dorval: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Dover: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Duane: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.5,
    grc: 3.5,
    gti: 3.3,
    gbb: 3.2,
    gbs: 3.5,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1,
    gfc: 1.5,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.5,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1,
  },
  Dunkirk: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },

  Dutchess: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Duxbury: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 3.5,
    grc: 3.5,
    gti: 3.3,
    gbb: 3.2,
    gbs: 3.5,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1,
    gfc: 1.5,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.5,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1,
  },
  Edwards: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 4.1,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Eel: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Eelweir: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Elka: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Ellery: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Elmridge: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Elmwood: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Elnora: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Empeyville: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Enfield: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5.5,
    grc: 5.5,
    gti: 5.3,
    gbb: 5.2,
    gbs: 5.5,
    gbd: 5.2,
    gbk: 3.2,
    gbm: 5.1,
    gdt: 3,
    gfc: 3.5,
    gfm: 5,
    gtf: 5.2,
    gft: 6.2,
    gge: 5.4,
    ghf: 5,
    gin: 4.5,
    gpm: 3.5,
    gri: 4.8,
    grp: 5,
    gsw: 5.5,
    gtm: 5.2,
    chy: 4.7,
    rtp: 3,
  },
  Ensley: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.2,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Erie: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Ernest: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Essex: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.5,
    grc: 3.5,
    gti: 3.3,
    gbb: 3.2,
    gbs: 3.5,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1,
    gfc: 1.5,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.5,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1,
  },
  Fahey: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Farmington: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 1,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.3,
    grc: 3.3,
    gti: 3.1,
    gbb: 3,
    gbs: 3.3,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 0.8,
  },
  Farnham: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Fernlake: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  Flackville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Fonda: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.7,
    grc: 3,
    gti: 3,
    gbb: 2.5,
    gbs: 2.1,
    gbd: 3,
    gbk: 1.2,
    gbm: 2.2,
    gdt: 1.2,
    gfc: 2.2,
    gfm: 2.2,
    gtf: 2.9,
    gft: 3.1,
    gge: 2.6,
    ghf: 2.5,
    gin: 1.7,
    gpm: 1,
    gri: 2.5,
    grp: 2.5,
    gsw: 2.6,
    gtm: 2.4,
    chy: 2.2,
    rtp: 1.2,
  },
  Franklinville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Fredon: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.8,
    grc: 4.4,
    gti: 4.2,
    gbb: 3.5,
    gbs: 3.7,
    gbd: 3.5,
    gbk: 2.2,
    gbm: 3.8,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4,
    gge: 3.7,
    ghf: 3.5,
    gin: 3.1,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.5,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.2,
    rtp: 2.1,
  },
  Freetown: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Fremont: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Frenchtown: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Frewsburg: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.5,
    grc: 3.5,
    gti: 3.5,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 2,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.7,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1.2,
  },
  Fryeburg: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.5,
    grc: 3.5,
    gti: 3.3,
    gbb: 3.2,
    gbs: 3.5,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1,
    gfc: 1.5,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.5,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1,
  },
  Fulton: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Gage: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Galen: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Galestown: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.1,
    grc: 3.8,
    gti: 3,
    gbb: 3.2,
    gbs: 3.6,
    gbd: 3.2,
    gbk: 0.8,
    gbm: 2.9,
    gdt: 1.3,
    gfc: 1.3,
    gfm: 2.3,
    gtf: 2.8,
    gft: 3.8,
    gge: 3,
    ghf: 3,
    gin: 2.5,
    gpm: 1.6,
    gri: 2.8,
    grp: 3,
    gsw: 3.6,
    gtm: 2.8,
    chy: 2.9,
    rtp: 1.1,
  },
  Galoo: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.5,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  'Galoo-Rock Outc': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.5,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Galway: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Genesee: {
    cor_s: 20.5,
    cor_g: 180,
    laa: 6.5,
    lag: 6.5,
    lbt: 4,
    lcr: 4.5,
    lca: 3.2,
    lcl: 3.5,
    lcw: 3,
    lvc: 4.2,
    lck: 4.8,
    lsf: 4,
    lmc: 4.8,
    lpf: 3.5,
    lcs: 4.5,
    gor: 5.7,
    grc: 5.7,
    gti: 5.5,
    gbb: 5.4,
    gbs: 5.7,
    gbd: 5.4,
    gbk: 3.4,
    gbm: 5.3,
    gdt: 3.2,
    gfc: 3.7,
    gfm: 5.2,
    gtf: 5.4,
    gft: 6.4,
    gge: 5.6,
    ghf: 5.2,
    gin: 4.7,
    gpm: 3.7,
    gri: 5,
    grp: 5.2,
    gsw: 5.7,
    gtm: 5.4,
    chy: 4.9,
    rtp: 3.2,
  },
  Georgia: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Getzville: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },

  Gilpen: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Gilpin: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Glebe: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1,
    lca: 0.2,
    lcl: 0.1,
    lcw: 0.1,
    lvc: 0.7,
    lck: 1.3,
    lsf: 0.5,
    lmc: 1.3,
    lpf: 0.3,
    lcs: 1,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  'Glebe-Saddlebac': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1,
    lca: 0.2,
    lcl: 0.1,
    lcw: 0.1,
    lvc: 0.7,
    lck: 1.3,
    lsf: 0.5,
    lmc: 1.3,
    lpf: 0.3,
    lcs: 1,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Glendora: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Glenfield: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 3,
    grc: 3.3,
    gti: 3.3,
    gbb: 2.8,
    gbs: 2.4,
    gbd: 3.3,
    gbk: 1.5,
    gbm: 2.5,
    gdt: 1.5,
    gfc: 2.5,
    gfm: 2.5,
    gtf: 3.2,
    gft: 3.4,
    gge: 2.9,
    ghf: 2.8,
    gin: 2,
    gpm: 1.3,
    gri: 2.8,
    grp: 2.8,
    gsw: 2.9,
    gtm: 2.7,
    chy: 2.5,
    rtp: 1.5,
  },
  Gloucester: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.9,
    gti: 4.1,
    gbb: 4.3,
    gbs: 4.7,
    gbd: 4.3,
    gbk: 1.9,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.7,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.7,
    gtm: 3.9,
    chy: 4,
    rtp: 2.2,
  },
  Glover: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.1,
    grc: 3.8,
    gti: 3,
    gbb: 3.2,
    gbs: 3.6,
    gbd: 3.2,
    gbk: 0.8,
    gbm: 2.9,
    gdt: 1.3,
    gfc: 1.3,
    gfm: 2.3,
    gtf: 2.8,
    gft: 3.8,
    gge: 3,
    ghf: 3,
    gin: 2.5,
    gpm: 1.6,
    gri: 2.8,
    grp: 3,
    gsw: 3.6,
    gtm: 2.8,
    chy: 2.9,
    rtp: 1.1,
  },
  Gougeville: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 1.7,
    lca: 1.8,
    lcl: 1.7,
    lcw: 1.7,
    lvc: 1.7,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2,
    lpf: 2,
    lcs: 2,
    gor: 2.7,
    grc: 3,
    gti: 3,
    gbb: 2.5,
    gbs: 2.1,
    gbd: 3,
    gbk: 1.2,
    gbm: 2.2,
    gdt: 1.2,
    gfc: 2.2,
    gfm: 2.2,
    gtf: 2.9,
    gft: 3.1,
    gge: 2.6,
    ghf: 2.5,
    gin: 1.7,
    gpm: 1,
    gri: 2.5,
    grp: 2.5,
    gsw: 2.6,
    gtm: 2.4,
    chy: 2.2,
    rtp: 1.2,
  },
  Granby: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.4,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Grattan: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 4.4,
    gti: 3.6,
    gbb: 3.8,
    gbs: 4.2,
    gbd: 3.8,
    gbk: 1.4,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 1.9,
    gfm: 2.9,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.6,
    gin: 3.1,
    gpm: 2.2,
    gri: 3.4,
    grp: 3.6,
    gsw: 4.2,
    gtm: 3.4,
    chy: 3.5,
    rtp: 1.7,
  },
  Greene: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Greenwood: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Grenville: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Gretor: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1.5,
    lca: 1.5,
    lcl: 1,
    lcw: 0.5,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.3,
    lcs: 1,
    gor: 3.3,
    grc: 3.3,
    gti: 3.3,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 1.3,
    gfc: 1.8,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.5,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 1,
  },
  Groton: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Groveton: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.3,
    grc: 4,
    gti: 3.2,
    gbb: 3.4,
    gbs: 3.8,
    gbd: 3.4,
    gbk: 1,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 1.5,
    gfm: 2.5,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.8,
    gri: 3,
    grp: 3.2,
    gsw: 3.8,
    gtm: 3.2,
    chy: 3.1,
    rtp: 1.3,
  },
  Guff: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 2.5,
    lag: 3,
    lbt: 1,
    lcr: 1,
    lca: 0.5,
    lcl: 1,
    lcw: 1,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.5,
    lcs: 1,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },
  Guffin: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 2.5,
    lag: 3,
    lbt: 1,
    lcr: 1,
    lca: 0.5,
    lcl: 1,
    lcw: 1,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.5,
    lcs: 1,
    gor: 2.4,
    grc: 3,
    gti: 2.8,
    gbb: 2.1,
    gbs: 2.3,
    gbd: 2.1,
    gbk: 0.8,
    gbm: 2.4,
    gdt: 0.7,
    gfc: 1.2,
    gfm: 2.3,
    gtf: 2.5,
    gft: 2.6,
    gge: 2.3,
    ghf: 2.1,
    gin: 1.7,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.5,
    gtm: 2.5,
    chy: 1.8,
    rtp: 0.7,
  },
  Gulf: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 2.5,
    lag: 2.5,
    lbt: 1,
    lcr: 1,
    lca: 0.5,
    lcl: 1,
    lcw: 1,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.5,
    lcs: 1,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },
  Hadley: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Haights: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.1,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  'Haights-Gulf': {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.1,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Hailesboro: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Halcott: {
    cor_s: 13.0,
    cor_g: 120,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.5,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 2.9,
    grc: 2.9,
    gti: 2.7,
    gbb: 2.6,
    gbs: 2.9,
    gbd: 2.6,
    gbk: 0.6,
    gbm: 2.5,
    gdt: 0.4,
    gfc: 0.9,
    gfm: 2.4,
    gtf: 2.6,
    gft: 3.6,
    gge: 2.8,
    ghf: 2.4,
    gin: 1.9,
    gpm: 0.9,
    gri: 2.2,
    grp: 2.4,
    gsw: 2.9,
    gtm: 2.6,
    chy: 2.1,
    rtp: 0.4,
  },
  Halsey: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.7,
    grc: 3,
    gti: 3,
    gbb: 2.5,
    gbs: 2.1,
    gbd: 3,
    gbk: 1.2,
    gbm: 2.2,
    gdt: 1.2,
    gfc: 2.2,
    gfm: 2.2,
    gtf: 2.9,
    gft: 3.1,
    gge: 2.6,
    ghf: 2.5,
    gin: 1.7,
    gpm: 1,
    gri: 2.5,
    grp: 2.5,
    gsw: 2.6,
    gtm: 2.4,
    chy: 2.2,
    rtp: 1.2,
  },
  Hamlin: {
    cor_s: 20.5,
    cor_g: 180,
    laa: 6.5,
    lag: 6.5,
    lbt: 4,
    lcr: 4.5,
    lca: 3.2,
    lcl: 3.5,
    lcw: 3,
    lvc: 4.2,
    lck: 4.8,
    lsf: 4,
    lmc: 4.8,
    lpf: 3.5,
    lcs: 4.5,
    gor: 5.7,
    grc: 5.7,
    gti: 5.5,
    gbb: 5.4,
    gbs: 5.7,
    gbd: 5.4,
    gbk: 3.4,
    gbm: 5.3,
    gdt: 3.2,
    gfc: 3.7,
    gfm: 5.2,
    gtf: 5.4,
    gft: 6.4,
    gge: 5.6,
    ghf: 5.2,
    gin: 4.7,
    gpm: 3.7,
    gri: 5,
    grp: 5.2,
    gsw: 5.7,
    gtm: 5.4,
    chy: 4.9,
    rtp: 3.2,
  },
  Hamplain: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.5,
    grc: 5.5,
    gti: 5.3,
    gbb: 5.2,
    gbs: 5.5,
    gbd: 5.2,
    gbk: 3.2,
    gbm: 5.1,
    gdt: 3,
    gfc: 3.5,
    gfm: 5,
    gtf: 5.2,
    gft: 6.2,
    gge: 5.4,
    ghf: 5,
    gin: 4.5,
    gpm: 3.5,
    gri: 4.8,
    grp: 5,
    gsw: 5.5,
    gtm: 5.2,
    chy: 4.7,
    rtp: 3,
  },
  Hannawa: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Hartland: {
    cor_s: 20.5,
    cor_g: 180,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.7,
    grc: 5.7,
    gti: 5.5,
    gbb: 5.4,
    gbs: 5.7,
    gbd: 5.4,
    gbk: 3.4,
    gbm: 5.3,
    gdt: 3.2,
    gfc: 3.7,
    gfm: 5.2,
    gtf: 5.4,
    gft: 6.4,
    gge: 5.6,
    ghf: 5.2,
    gin: 4.7,
    gpm: 3.7,
    gri: 5,
    grp: 5.2,
    gsw: 5.7,
    gtm: 5.4,
    chy: 4.9,
    rtp: 3.2,
  },
  Haven: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.5,
    grc: 5.5,
    gti: 5.3,
    gbb: 5.2,
    gbs: 5.5,
    gbd: 5.2,
    gbk: 3.2,
    gbm: 5.1,
    gdt: 3,
    gfc: 3.5,
    gfm: 5,
    gtf: 5.2,
    gft: 6.2,
    gge: 5.4,
    ghf: 5,
    gin: 4.5,
    gpm: 3.5,
    gri: 4.8,
    grp: 5,
    gsw: 5.5,
    gtm: 5.2,
    chy: 4.7,
    rtp: 3,
  },
  Hawksnest: {
    cor_s: 13.0,
    cor_g: 120,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1,
    lca: 0.2,
    lcl: 0.1,
    lcw: 0.1,
    lvc: 0.7,
    lck: 1.3,
    lsf: 0.5,
    lmc: 1.3,
    lpf: 0.3,
    lcs: 1,
    gor: 2.9,
    grc: 2.9,
    gti: 2.7,
    gbb: 2.6,
    gbs: 2.9,
    gbd: 2.6,
    gbk: 0.6,
    gbm: 2.5,
    gdt: 0.4,
    gfc: 0.9,
    gfm: 2.4,
    gtf: 2.6,
    gft: 3.6,
    gge: 2.8,
    ghf: 2.4,
    gin: 1.9,
    gpm: 0.9,
    gri: 2.2,
    grp: 2.4,
    gsw: 2.9,
    gtm: 2.6,
    chy: 2.1,
    rtp: 0.4,
  },
  Hempstead: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.5,
    grc: 5.5,
    gti: 5.3,
    gbb: 5.2,
    gbs: 5.5,
    gbd: 5.2,
    gbk: 3.2,
    gbm: 5.1,
    gdt: 3,
    gfc: 3.5,
    gfm: 5,
    gtf: 5.2,
    gft: 6.2,
    gge: 5.4,
    ghf: 5,
    gin: 4.5,
    gpm: 3.5,
    gri: 4.8,
    grp: 5,
    gsw: 5.5,
    gtm: 5.2,
    chy: 4.7,
    rtp: 3,
  },
  Henrietta: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Herkimer: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Hermon: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Hero: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 6,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Heuvelton: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Hilton: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 3,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },

  Hinckley: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.3,
    grc: 4,
    gti: 3.2,
    gbb: 3.4,
    gbs: 3.8,
    gbd: 3.4,
    gbk: 1,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 1.5,
    gfm: 2.5,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.8,
    gri: 3,
    grp: 3.2,
    gsw: 3.8,
    gtm: 3,
    chy: 3.1,
    rtp: 1.3,
  },
  Hinesburg: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Hogansburg: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Hogback: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 1,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  'Hogback-Ricker': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Holderton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Hollis: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.5,
    grc: 3.5,
    gti: 3.5,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 2,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.7,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1.2,
  },
  Holly: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.2,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Holyoke: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 1,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  'Holyoke-Rock Ou': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 1,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Homer: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Honeoye: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Hoosic: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.5,
    lck: 2.8,
    lsf: 3,
    lmc: 2.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 3.7,
    grc: 4.4,
    gti: 3.6,
    gbb: 3.8,
    gbs: 4.2,
    gbd: 3.8,
    gbk: 1.4,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 1.9,
    gfm: 2.9,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.6,
    gin: 3.1,
    gpm: 2.2,
    gri: 3.4,
    grp: 3.6,
    gsw: 4.2,
    gtm: 3.6,
    chy: 3.5,
    rtp: 1.7,
  },
  Hornell: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Hornellsville: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1.5,
    lca: 1.5,
    lcl: 1,
    lcw: 0.5,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.3,
    lcs: 1,
    gor: 3.5,
    grc: 3.5,
    gti: 3.5,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 2,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.7,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1.2,
  },
  Houghtonville: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  'Houghtonville-R': {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Houseville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Howard: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Hudson: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Hulberton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Ilion: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.9,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Insula: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1,
    lca: 0.2,
    lcl: 0.1,
    lcw: 0.1,
    lvc: 0.7,
    lck: 1.3,
    lsf: 0.5,
    lmc: 1.3,
    lpf: 0.3,
    lcs: 1,
    gor: 3.3,
    grc: 3.3,
    gti: 3.1,
    gbb: 3,
    gbs: 3.3,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 0.8,
  },
  Ipswich: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 4,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Ira: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Ischua: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Ivory: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1.5,
    lca: 1.5,
    lcl: 1,
    lcw: 0.5,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.3,
    lcs: 1,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Jebavy: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Joliet: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Junius: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Kalurah: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Kanona: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Kars: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Kearsarge: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3,
    lbt: 0.2,
    lcr: 0.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.1,
    lvc: 1,
    lck: 1.3,
    lsf: 1.5,
    lmc: 1.3,
    lpf: 1,
    lcs: 2,
    gor: 3.1,
    grc: 3.8,
    gti: 3,
    gbb: 3.2,
    gbs: 3.6,
    gbd: 3.2,
    gbk: 0.8,
    gbm: 2.9,
    gdt: 1.3,
    gfc: 1.3,
    gfm: 2.3,
    gtf: 2.8,
    gft: 3.8,
    gge: 3,
    ghf: 3,
    gin: 2.5,
    gpm: 1.6,
    gri: 2.8,
    grp: 3,
    gsw: 3.6,
    gtm: 2.8,
    chy: 2.9,
    rtp: 1.1,
  },
  Kendaia: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Kibbie: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Kingsbury: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Kinzua: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Knickerbocker: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.7,
    grc: 4.4,
    gti: 3.6,
    gbb: 3.8,
    gbs: 4.2,
    gbd: 3.8,
    gbk: 1.4,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 1.9,
    gfm: 2.9,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.6,
    gin: 3.1,
    gpm: 2.2,
    gri: 3.4,
    grp: 3.6,
    gsw: 4.2,
    gtm: 3.4,
    chy: 3.5,
    rtp: 1.7,
  },

  Lackawanna: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Lagross: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  'Lagross-Haights': {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Lairdsville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Lakemont: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Lakewood: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  Lamson: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.6,
    grc: 4.2,
    gti: 4,
    gbb: 3.3,
    gbs: 3.5,
    gbd: 3.3,
    gbk: 2,
    gbm: 3.6,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.5,
    gtf: 3.7,
    gft: 3.8,
    gge: 3.5,
    ghf: 3.3,
    gin: 2.9,
    gpm: 2,
    gri: 3.3,
    grp: 3.3,
    gsw: 3.7,
    gtm: 3.7,
    chy: 3,
    rtp: 1.9,
  },
  Lanesboro: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 1,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Langford: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Lansing: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  'Leck Kill': {
    cor_s: 18.0,
    cor_g: 165,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.1,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Leicester: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.9,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Leon: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Lewbath: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.5,
    grc: 3.5,
    gti: 3.3,
    gbb: 3.2,
    gbs: 3.5,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1,
    gfc: 1.5,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.5,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1,
  },
  Lewbeach: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Leyden: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Lima: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Limerick: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 0,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Linden: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Linlithgo: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Livingston: {
    cor_s: 13.5,
    cor_g: 125,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 2.1,
    grc: 2.4,
    gti: 2.4,
    gbb: 1.9,
    gbs: 1.5,
    gbd: 2.4,
    gbk: 0.6,
    gbm: 1.6,
    gdt: 0.6,
    gfc: 1.6,
    gfm: 1.6,
    gtf: 2.3,
    gft: 2.5,
    gge: 2,
    ghf: 1.9,
    gin: 1.1,
    gpm: 0.4,
    gri: 1.9,
    grp: 1.9,
    gsw: 2,
    gtm: 1.8,
    chy: 1.6,
    rtp: 0.6,
  },
  Lobdell: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Lockport: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Lorain: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 2.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1,
    lca: 0.5,
    lcl: 1,
    lcw: 1,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.5,
    lcs: 1,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Lordstown: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Lovewell: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Lowville: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Loxley: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Lucas: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Ludlow: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Lupton: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Lyman: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  'Lyman-Becket-Be': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  Lyme: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Lyons: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Machias: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Macomber: {
    cor_s: 13.5,
    cor_g: 125,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.1,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.1,
    gti: 2.9,
    gbb: 2.8,
    gbs: 3.1,
    gbd: 2.8,
    gbk: 0.8,
    gbm: 2.7,
    gdt: 0.6,
    gfc: 1.1,
    gfm: 2.6,
    gtf: 2.8,
    gft: 3.8,
    gge: 3,
    ghf: 2.6,
    gin: 2.1,
    gpm: 1.1,
    gri: 2.4,
    grp: 2.6,
    gsw: 3.1,
    gtm: 2.8,
    chy: 2.3,
    rtp: 0.6,
  },
  'Macomber-Taconi': {
    cor_s: 13.5,
    cor_g: 125,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.1,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.1,
    gti: 2.9,
    gbb: 2.8,
    gbs: 3.1,
    gbd: 2.8,
    gbk: 0.8,
    gbm: 2.7,
    gdt: 0.6,
    gfc: 1.1,
    gfm: 2.6,
    gtf: 2.8,
    gft: 3.8,
    gge: 3,
    ghf: 2.6,
    gin: 2.1,
    gpm: 1.1,
    gri: 2.4,
    grp: 2.6,
    gsw: 3.1,
    gtm: 2.8,
    chy: 2.3,
    rtp: 0.6,
  },
  Madalin: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },

  Madawaska: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Madrid: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Malone: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Manahawkin: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Mandy: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Manheim: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Manhoning: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Manlius: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Mansfield: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Maplecrest: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Marcy: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Mardin: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Marilla: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Markey: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Marlow: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Martisco: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 2.5,
    lag: 4,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.4,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.2,
    gbs: 2.8,
    gbd: 2.4,
    gbk: 1.9,
    gbm: 2.9,
    gdt: 1.9,
    gfc: 2.9,
    gfm: 2.9,
    gtf: 3.6,
    gft: 3.8,
    gge: 3.3,
    ghf: 3.2,
    gin: 2.4,
    gpm: 1.7,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.3,
    gtm: 3.1,
    chy: 2.9,
    rtp: 1.9,
  },
  Massena: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Matoon: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1.5,
    lca: 1.5,
    lcl: 1,
    lcw: 0.5,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.3,
    lcs: 1,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Matunuck: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Medihemists: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Medina: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Medomak: {
    cor_s: 13.0,
    cor_g: 120,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 1.9,
    grc: 2.2,
    gti: 2.2,
    gbb: 1.7,
    gbs: 1.3,
    gbd: 2.2,
    gbk: 0.4,
    gbm: 1.4,
    gdt: 0.4,
    gfc: 1.4,
    gfm: 1.4,
    gtf: 2.1,
    gft: 2.3,
    gge: 1.8,
    ghf: 1.7,
    gin: 0.9,
    gpm: 0.2,
    gri: 1.7,
    grp: 1.7,
    gsw: 1.8,
    gtm: 1.6,
    chy: 1.4,
    rtp: 0.4,
  },
  Melrose: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Menlo: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.2,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Mentor: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Merrimac: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.5,
    lck: 2.8,
    lsf: 3,
    lmc: 2.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 3.7,
    grc: 4.4,
    gti: 3.6,
    gbb: 3.8,
    gbs: 4.2,
    gbd: 3.8,
    gbk: 1.4,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 1.9,
    gfm: 2.9,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.6,
    gin: 3.1,
    gpm: 2.2,
    gri: 3.4,
    grp: 3.6,
    gsw: 4.2,
    gtm: 3.6,
    chy: 3.5,
    rtp: 1.7,
  },
  Middlebrook: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  'Middlebrook-Mon': {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Middlebury: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Millis: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Millsite: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Mineola: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Miner: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Mino: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Minoa: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Mohawk: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Moira: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Monadnock: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.5,
    grc: 3.5,
    gti: 3.3,
    gbb: 3.2,
    gbs: 3.5,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1,
    gfc: 1.5,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.5,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1,
  },
  Monarda: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },

  Mongaup: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Montauk: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Mooers: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.5,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Morocco: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.8,
    grc: 4.4,
    gti: 4.2,
    gbb: 3.5,
    gbs: 3.7,
    gbd: 3.5,
    gbk: 2.2,
    gbm: 3.8,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4,
    gge: 3.7,
    ghf: 3.5,
    gin: 3.1,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.5,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.2,
    rtp: 2.1,
  },
  Morris: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Mosherville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Muck: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  'Muck-Peat': {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Mundal: {
    cor_s: 10.5,
    cor_g: 95,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.1,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 2.4,
    grc: 2.4,
    gti: 2.2,
    gbb: 2.1,
    gbs: 2.4,
    gbd: 2.1,
    gbk: 0.1,
    gbm: 2,
    gdt: 0.2,
    gfc: 0.4,
    gfm: 1.9,
    gtf: 2.1,
    gft: 3.1,
    gge: 2.3,
    ghf: 1.9,
    gin: 1.4,
    gpm: 0.4,
    gri: 1.7,
    grp: 1.9,
    gsw: 2.4,
    gtm: 2.1,
    chy: 1.6,
    rtp: 0.1,
  },
  Mundalite: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  'Mundalite-Rawso': {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Munson: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Munuscong: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.2,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Muskego: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Muskellunge: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Napoleon: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Napoli: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 2,
    lca: 2,
    lcl: 1.5,
    lcw: 1,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.3,
    gti: 3.3,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 1.3,
    gfc: 1.8,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.5,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 1,
  },
  Nassau: {
    cor_s: 13.5,
    cor_g: 125,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.9,
    grc: 3.6,
    gti: 2.8,
    gbb: 3,
    gbs: 3.4,
    gbd: 3,
    gbk: 0.6,
    gbm: 2.7,
    gdt: 1.1,
    gfc: 1.1,
    gfm: 2.1,
    gtf: 2.6,
    gft: 3.6,
    gge: 2.8,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.4,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.4,
    gtm: 2.6,
    chy: 2.7,
    rtp: 0.9,
  },
  Naumburg: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Nehasne: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Nellis: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Neversink: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },
  Newfane: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Newstead: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Newton: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 2.5,
    lag: 4,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 1.3,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Niagara: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Nicholville: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Ninigret: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 3,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Norchip: {
    cor_s: 13.0,
    cor_g: 120,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 2.5,
    grc: 3.1,
    gti: 2.9,
    gbb: 2.2,
    gbs: 2.4,
    gbd: 2.2,
    gbk: 0.9,
    gbm: 2.5,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.4,
    gtf: 2.6,
    gft: 2.7,
    gge: 2.4,
    ghf: 2.2,
    gin: 1.8,
    gpm: 0.9,
    gri: 2.2,
    grp: 2.2,
    gsw: 2.9,
    gtm: 2.6,
    chy: 1.9,
    rtp: 0.8,
  },
  Norwell: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Norwich: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Nunda: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Oakville: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Occum: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Odessa: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Ogdensburg: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Olean: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 6,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Ondawa: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Oneida: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },

  Onoville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Ontario: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Onteora: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Ontusia: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Oquaga: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Oramel: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Organic: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Orpark: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Orwell: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Ossipee: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Otego: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.5,
    grc: 5.5,
    gti: 5.3,
    gbb: 5.2,
    gbs: 5.5,
    gbd: 5.2,
    gbk: 3.2,
    gbm: 5.1,
    gdt: 3,
    gfc: 3.5,
    gfm: 5,
    gtf: 5.2,
    gft: 6.2,
    gge: 5.4,
    ghf: 5,
    gin: 4.5,
    gpm: 3.5,
    gri: 4.8,
    grp: 5,
    gsw: 5.5,
    gtm: 5.2,
    chy: 4.7,
    rtp: 3,
  },
  Otisville: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.3,
    grc: 4,
    gti: 3.2,
    gbb: 3.4,
    gbs: 3.8,
    gbd: 3.4,
    gbk: 1,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 1.5,
    gfm: 2.5,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.8,
    gri: 3,
    grp: 3.2,
    gsw: 3.8,
    gtm: 3,
    chy: 3.1,
    rtp: 1.3,
  },
  Ottawa: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Ovid: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Palatine: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.5,
    gbb: 3.4,
    gbs: 3.7,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.7,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.2,
  },
  Palms: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 2,
    lca: 2,
    lcl: 1.5,
    lcw: 1,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 0.5,
    lcs: 1.5,
    gor: 5.5,
    grc: 5.5,
    gti: 5.5,
    gbb: 5.2,
    gbs: 5.4,
    gbd: 5.2,
    gbk: 3.2,
    gbm: 5.1,
    gdt: 3.5,
    gfc: 4,
    gfm: 5,
    gtf: 5.2,
    gft: 6.2,
    gge: 5.4,
    ghf: 5,
    gin: 4.5,
    gpm: 3.7,
    gri: 4.8,
    grp: 5,
    gsw: 5.5,
    gtm: 5.2,
    chy: 4.7,
    rtp: 3.2,
  },
  Palmyra: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Panton: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1.5,
    lca: 1.5,
    lcl: 1,
    lcw: 0.5,
    lvc: 0.5,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1.5,
    lpf: 0.3,
    lcs: 1,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Papakating: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.2,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Parishville: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Parsippany: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Patchin: {
    cor_s: 13.5,
    cor_g: 125,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 2.7,
    grc: 3.3,
    gti: 3.1,
    gbb: 2.4,
    gbs: 2.6,
    gbd: 2.4,
    gbk: 1.1,
    gbm: 2.7,
    gdt: 1,
    gfc: 1.5,
    gfm: 2.6,
    gtf: 2.8,
    gft: 2.9,
    gge: 2.6,
    ghf: 2.4,
    gin: 2,
    gpm: 1.1,
    gri: 2.4,
    grp: 2.4,
    gsw: 2.8,
    gtm: 2.8,
    chy: 2.1,
    rtp: 1,
  },
  Pawcatuck: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Pawling: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Paxton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Peacham: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 2.4,
    grc: 3,
    gti: 2.8,
    gbb: 2.1,
    gbs: 2.3,
    gbd: 2.1,
    gbk: 0.8,
    gbm: 2.4,
    gdt: 0.7,
    gfc: 1.2,
    gfm: 2.3,
    gtf: 2.5,
    gft: 2.6,
    gge: 2.3,
    ghf: 2.1,
    gin: 1.7,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.8,
    gtm: 2.5,
    chy: 1.8,
    rtp: 0.7,
  },
  Peat: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  'Peat-Muck': {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Peru: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Petoskey: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Phelps: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Philo: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 3,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Pillsbury: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Pinckney: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Pipestone: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 2,
    lca: 2,
    lcl: 1.5,
    lcw: 1,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Pittsfield: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Pittstown: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Plainbo: {
    cor_s: 13.0,
    cor_g: 120,
    laa: 3,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.1,
    lvc: 1,
    lck: 1.3,
    lsf: 1.5,
    lmc: 1.3,
    lpf: 1,
    lcs: 2,
    gor: 2.7,
    grc: 3.4,
    gti: 2.6,
    gbb: 2.8,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 0.4,
    gbm: 2.5,
    gdt: 0.9,
    gfc: 0.9,
    gfm: 1.9,
    gtf: 2.4,
    gft: 3.4,
    gge: 2.6,
    ghf: 2.6,
    gin: 2.1,
    gpm: 1.2,
    gri: 2.4,
    grp: 2.6,
    gsw: 3.2,
    gtm: 2.4,
    chy: 2.5,
    rtp: 0.7,
  },
  Plainfield: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.1,
    grc: 3.8,
    gti: 3,
    gbb: 3.2,
    gbs: 3.6,
    gbd: 3.2,
    gbk: 0.8,
    gbm: 2.9,
    gdt: 1.3,
    gfc: 1.3,
    gfm: 2.3,
    gtf: 2.8,
    gft: 3.8,
    gge: 3,
    ghf: 3,
    gin: 2.5,
    gpm: 1.6,
    gri: 2.8,
    grp: 3,
    gsw: 3.6,
    gtm: 2.8,
    chy: 2.9,
    rtp: 1.1,
  },

  Plessis: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.5,
    grc: 3.5,
    gti: 3.5,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 2,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.7,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1.2,
  },
  Plymouth: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  Podunk: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 3,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Poland: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Pompton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Pootatuck: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Pope: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Potsdam: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Poygan: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3.3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },
  Punsit: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Pyrities: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Quetico: {
    cor_s: 10.5,
    cor_g: 95,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1,
    lca: 0.2,
    lcl: 0.1,
    lcw: 0.1,
    lvc: 0.7,
    lck: 1.3,
    lsf: 0.5,
    lmc: 1.3,
    lpf: 0.3,
    lcs: 1,
    gor: 2.4,
    grc: 2.4,
    gti: 2.2,
    gbb: 2.1,
    gbs: 2.4,
    gbd: 2.1,
    gbk: 0.1,
    gbm: 2,
    gdt: 0.2,
    gfc: 0.4,
    gfm: 1.9,
    gtf: 2.1,
    gft: 3.1,
    gge: 2.3,
    ghf: 1.9,
    gin: 1.4,
    gpm: 0.4,
    gri: 1.7,
    grp: 1.9,
    gsw: 2.4,
    gtm: 2.1,
    chy: 1.6,
    rtp: 0.1,
  },
  'Quetico-Rock Ou': {
    cor_s: 10.5,
    cor_g: 95,
    laa: 3,
    lag: 3,
    lbt: 0.5,
    lcr: 1,
    lca: 0.2,
    lcl: 0.1,
    lcw: 0.1,
    lvc: 0.7,
    lck: 1.3,
    lsf: 0.5,
    lmc: 1.3,
    lpf: 0.3,
    lcs: 1,
    gor: 2.4,
    grc: 2.4,
    gti: 2.2,
    gbb: 2.1,
    gbs: 2.4,
    gbd: 2.1,
    gbk: 0.1,
    gbm: 2,
    gdt: 0.2,
    gfc: 0.4,
    gfm: 1.9,
    gtf: 2.1,
    gft: 3.1,
    gge: 2.3,
    ghf: 1.9,
    gin: 1.4,
    gpm: 0.4,
    gri: 1.7,
    grp: 1.9,
    gsw: 2.4,
    gtm: 2.1,
    chy: 1.6,
    rtp: 0.1,
  },
  Raquette: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Rawsonville: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  'Rawsonville-Bes': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 2.8,
    grc: 2.8,
    gti: 2.6,
    gbb: 2.5,
    gbs: 2.8,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.3,
    gfc: 0.8,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 0.8,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.3,
  },
  Rayne: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Raynham: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 4.2,
    grc: 4.8,
    gti: 4.6,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 2.6,
    gbm: 4.2,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.1,
    gtf: 4.3,
    gft: 4.4,
    gge: 4.1,
    ghf: 3.9,
    gin: 3.5,
    gpm: 2.6,
    gri: 3.9,
    grp: 3.9,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.6,
    rtp: 2.5,
  },
  Raypol: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3.3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },
  'Red Hook': {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Redwater: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Remsen: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Retsof: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Rexford: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Rhinebeck: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Ricker: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  'Ricker-Lyman': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  Ridgebury: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.6,
    grc: 4.2,
    gti: 4,
    gbb: 3.3,
    gbs: 3.5,
    gbd: 3.3,
    gbk: 2,
    gbm: 3.6,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.5,
    gtf: 3.7,
    gft: 3.8,
    gge: 3.5,
    ghf: 3.3,
    gin: 2.9,
    gpm: 2,
    gri: 3.3,
    grp: 3.3,
    gsw: 4,
    gtm: 3.7,
    chy: 3,
    rtp: 1.9,
  },
  Rifle: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Riga: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Rippowam: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.9,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Riverhead: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Rockaway: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Romulus: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Ross: {
    cor_s: 20.5,
    cor_g: 180,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.7,
    grc: 5.7,
    gti: 5.5,
    gbb: 5.4,
    gbs: 5.7,
    gbd: 5.4,
    gbk: 3.4,
    gbm: 5.3,
    gdt: 3.2,
    gfc: 3.7,
    gfm: 5.2,
    gtf: 5.4,
    gft: 6.4,
    gge: 5.6,
    ghf: 5.2,
    gin: 4.7,
    gpm: 3.7,
    gri: 5,
    grp: 5.2,
    gsw: 5.7,
    gtm: 5.4,
    chy: 4.9,
    rtp: 3.2,
  },
  Roundabout: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Rumney: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Runeberg: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },
  Ruse: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 2.9,
    grc: 3.5,
    gti: 3.3,
    gbb: 2.6,
    gbs: 2.8,
    gbd: 2.6,
    gbk: 1.3,
    gbm: 2.9,
    gdt: 1.2,
    gfc: 1.7,
    gfm: 2.8,
    gtf: 3,
    gft: 3.1,
    gge: 2.8,
    ghf: 2.6,
    gin: 2.2,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.6,
    gsw: 3,
    gtm: 3,
    chy: 2.3,
    rtp: 1.2,
  },

  Rushford: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Saco: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 4,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.5,
    grc: 2.8,
    gti: 2.8,
    gbb: 2.3,
    gbs: 1.9,
    gbd: 2.8,
    gbk: 1,
    gbm: 2,
    gdt: 1,
    gfc: 2,
    gfm: 2,
    gtf: 2.7,
    gft: 2.9,
    gge: 2.4,
    ghf: 2.3,
    gin: 1.5,
    gpm: 0.8,
    gri: 2.3,
    grp: 2.3,
    gsw: 2.4,
    gtm: 2.2,
    chy: 2,
    rtp: 1,
  },
  Salamanca: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Salmon: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Saprists: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Saugatuck: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 2,
    lca: 2,
    lcl: 1.5,
    lcw: 1,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.5,
    grc: 3.5,
    gti: 3.5,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 2,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.7,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1.2,
  },
  Scantic: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Scarboro: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.9,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Schoharie: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Schroon: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Schuyler: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Scio: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Scituate: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Scriba: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Searsport: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.9,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Shaker: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Shoreham: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.5,
    grc: 2.8,
    gti: 2.8,
    gbb: 2.3,
    gbs: 1.9,
    gbd: 2.8,
    gbk: 1,
    gbm: 2,
    gdt: 1,
    gfc: 2,
    gfm: 2,
    gtf: 2.7,
    gft: 2.9,
    gge: 2.4,
    ghf: 2.3,
    gin: 1.5,
    gpm: 0.8,
    gri: 2.3,
    grp: 2.3,
    gsw: 2.4,
    gtm: 2.2,
    chy: 2,
    rtp: 1,
  },
  Sisk: {
    cor_s: 13.5,
    cor_g: 125,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.1,
    grc: 2.4,
    gti: 2.4,
    gbb: 1.9,
    gbs: 1.5,
    gbd: 2.4,
    gbk: 0.6,
    gbm: 1.6,
    gdt: 0.6,
    gfc: 1.6,
    gfm: 1.6,
    gtf: 2.3,
    gft: 2.5,
    gge: 2,
    ghf: 1.9,
    gin: 1.1,
    gpm: 0.4,
    gri: 1.9,
    grp: 1.9,
    gsw: 2,
    gtm: 1.8,
    chy: 1.6,
    rtp: 0.6,
  },
  Skerry: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Sloan: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Sodus: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Somerset: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  'St Johns': {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.9,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Staatsburg: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.3,
    grc: 3.3,
    gti: 3.1,
    gbb: 3,
    gbs: 3.3,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 0.8,
  },
  Stafford: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Steamburg: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Stetson: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Stissing: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Stockbridge: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Stockholm: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Stowe: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Sudbury: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Suffield: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Summerville: {
    cor_s: 13.0,
    cor_g: 120,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.7,
    grc: 3.4,
    gti: 2.6,
    gbb: 2.8,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 0.4,
    gbm: 2.5,
    gdt: 0.9,
    gfc: 0.9,
    gfm: 1.9,
    gtf: 2.4,
    gft: 3.4,
    gge: 2.6,
    ghf: 2.6,
    gin: 2.1,
    gpm: 1.2,
    gri: 2.4,
    grp: 2.6,
    gsw: 3.2,
    gtm: 2.4,
    chy: 2.5,
    rtp: 0.7,
  },
  Sun: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.7,
    grc: 3,
    gti: 3,
    gbb: 2.5,
    gbs: 2.1,
    gbd: 3,
    gbk: 1.2,
    gbm: 2.2,
    gdt: 1.2,
    gfc: 2.2,
    gfm: 2.2,
    gtf: 2.9,
    gft: 3.1,
    gge: 2.6,
    ghf: 2.5,
    gin: 1.7,
    gpm: 1,
    gri: 2.5,
    grp: 2.5,
    gsw: 2.6,
    gtm: 2.4,
    chy: 2.2,
    rtp: 1.2,
  },
  Sunapee: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Suncook: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.1,
    grc: 3.8,
    gti: 3,
    gbb: 3.2,
    gbs: 3.6,
    gbd: 3.2,
    gbk: 0.8,
    gbm: 2.9,
    gdt: 1.3,
    gfc: 1.3,
    gfm: 2.3,
    gtf: 2.8,
    gft: 3.8,
    gge: 3,
    ghf: 3,
    gin: 2.5,
    gpm: 1.6,
    gri: 2.8,
    grp: 3,
    gsw: 3.6,
    gtm: 2.8,
    chy: 2.9,
    rtp: 1.1,
  },
  Suny: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 3.5,
    lag: 4,
    lbt: 2,
    lcr: 2,
    lca: 1.5,
    lcl: 2,
    lcw: 2,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1.5,
    lcs: 2,
    gor: 3.6,
    grc: 4.2,
    gti: 4,
    gbb: 3.3,
    gbs: 3.5,
    gbd: 3.3,
    gbk: 2,
    gbm: 3.6,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.5,
    gtf: 3.7,
    gft: 3.8,
    gge: 3.5,
    ghf: 3.3,
    gin: 2.9,
    gpm: 2,
    gri: 3.3,
    grp: 3.3,
    gsw: 3.7,
    gtm: 3.7,
    chy: 3,
    rtp: 1.9,
  },
  Surplus: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },

  'Surplus-Sisk': {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Sutton: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Swanton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Swartswood: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Swormville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 0,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Taconic: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.3,
    grc: 3.3,
    gti: 3.1,
    gbb: 3,
    gbs: 3.3,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 0.8,
  },
  'Taconic-Macombe': {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 1.5,
    lca: 0.2,
    lcl: 0.5,
    lcw: 0.1,
    lvc: 1.2,
    lck: 1.8,
    lsf: 1,
    lmc: 1.8,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.3,
    gti: 3.1,
    gbb: 3,
    gbs: 3.3,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 0.8,
  },
  Tawas: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Teel: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Tioga: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Toledo: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 2.7,
    grc: 3,
    gti: 3,
    gbb: 2.5,
    gbs: 2.1,
    gbd: 3,
    gbk: 1.2,
    gbm: 2.2,
    gdt: 1.2,
    gfc: 2.2,
    gfm: 2.2,
    gtf: 2.9,
    gft: 3.1,
    gge: 2.6,
    ghf: 2.5,
    gin: 1.7,
    gpm: 1,
    gri: 2.5,
    grp: 2.5,
    gsw: 2.6,
    gtm: 2.4,
    chy: 2.2,
    rtp: 1.2,
  },
  Tonawanda: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Tor: {
    cor_s: 13.5,
    cor_g: 125,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 2,
    lca: 2,
    lcl: 1.5,
    lcw: 1,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 0.5,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.1,
    gti: 3.1,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 0.8,
    gbm: 2.7,
    gdt: 1.1,
    gfc: 1.6,
    gfm: 2.6,
    gtf: 2.8,
    gft: 3.8,
    gge: 3,
    ghf: 2.6,
    gin: 2.1,
    gpm: 1.3,
    gri: 2.4,
    grp: 2.6,
    gsw: 3.1,
    gtm: 2.8,
    chy: 2.3,
    rtp: 0.8,
  },
  Torull: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Towerville: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.2,
    grc: 4.2,
    gti: 4,
    gbb: 3.9,
    gbs: 4.2,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.2,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.7,
  },
  Trestle: {
    cor_s: 19.0,
    cor_g: 170,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5.3,
    grc: 5.3,
    gti: 5.1,
    gbb: 5,
    gbs: 5.3,
    gbd: 5,
    gbk: 3,
    gbm: 4.9,
    gdt: 2.8,
    gfc: 3.3,
    gfm: 4.8,
    gtf: 5,
    gft: 6,
    gge: 5.2,
    ghf: 4.8,
    gin: 4.3,
    gpm: 3.3,
    gri: 4.6,
    grp: 4.8,
    gsw: 5.3,
    gtm: 5,
    chy: 4.5,
    rtp: 2.8,
  },
  'Trout River': {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.3,
    grc: 4,
    gti: 3.2,
    gbb: 3.4,
    gbs: 3.8,
    gbd: 3.4,
    gbk: 1,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 1.5,
    gfm: 2.5,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.8,
    gri: 3,
    grp: 3.2,
    gsw: 3.8,
    gtm: 3,
    chy: 3.1,
    rtp: 1.3,
  },
  Troy: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Trumbull: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Tughill: {
    cor_s: 13.5,
    cor_g: 125,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.1,
    grc: 2.4,
    gti: 2.4,
    gbb: 1.9,
    gbs: 1.5,
    gbd: 2.4,
    gbk: 0.6,
    gbm: 1.6,
    gdt: 0.6,
    gfc: 1.6,
    gfm: 1.6,
    gtf: 2.3,
    gft: 2.5,
    gge: 2,
    ghf: 1.9,
    gin: 1.1,
    gpm: 0.4,
    gri: 1.9,
    grp: 1.9,
    gsw: 2,
    gtm: 1.8,
    chy: 1.6,
    rtp: 0.6,
  },
  Tuller: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.5,
    grc: 3.5,
    gti: 3.5,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.2,
    gbm: 3.1,
    gdt: 1.5,
    gfc: 2,
    gfm: 3,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3,
    gin: 2.5,
    gpm: 1.7,
    gri: 2.8,
    grp: 3,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.7,
    rtp: 1.2,
  },
  Tunbridge: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.3,
    grc: 3.3,
    gti: 3.1,
    gbb: 3,
    gbs: 3.3,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 0.8,
  },
  'Tunbridge-Adiro': {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.3,
    grc: 3.3,
    gti: 3.1,
    gbb: 3,
    gbs: 3.3,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 0.8,
  },
  Tunkhannock: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Turin: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Tuscarora: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Unadilla: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 6,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Valois: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Varick: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.3,
    grc: 3.9,
    gti: 3.7,
    gbb: 3,
    gbs: 3.2,
    gbd: 3,
    gbk: 1.7,
    gbm: 3.3,
    gdt: 1.6,
    gfc: 2.1,
    gfm: 3.2,
    gtf: 3.4,
    gft: 3.5,
    gge: 3.2,
    ghf: 3,
    gin: 2.6,
    gpm: 1.7,
    gri: 3,
    grp: 3,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.7,
    rtp: 1.6,
  },
  Varysburg: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Venango: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.4,
    gbb: 4.1,
    gbs: 4.3,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 2.4,
    gfc: 2.9,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.6,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 2.1,
  },
  Vergennes: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Vly: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.3,
    grc: 3.3,
    gti: 3.1,
    gbb: 3,
    gbs: 3.3,
    gbd: 3,
    gbk: 1,
    gbm: 2.9,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.8,
    gtf: 3,
    gft: 4,
    gge: 3.2,
    ghf: 2.8,
    gin: 2.3,
    gpm: 1.3,
    gri: 2.6,
    grp: 2.8,
    gsw: 3.3,
    gtm: 3,
    chy: 2.5,
    rtp: 0.8,
  },
  Volusia: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Waddington: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Wainola: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 4.6,
    grc: 4.6,
    gti: 4.6,
    gbb: 4.3,
    gbs: 4.5,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.8,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.3,
  },
  Wakeland: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Wakeville: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3.5,
    lca: 3.5,
    lcl: 3,
    lcw: 2.5,
    lvc: 2.5,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3.5,
    lpf: 2,
    lcs: 3,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Wallace: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.5,
    grc: 4.2,
    gti: 3.4,
    gbb: 3.6,
    gbs: 4,
    gbd: 3.6,
    gbk: 1.2,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 1.7,
    gfm: 2.7,
    gtf: 3.2,
    gft: 4.2,
    gge: 3.4,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2,
    gri: 3.2,
    grp: 3.4,
    gsw: 4,
    gtm: 3.2,
    chy: 3.3,
    rtp: 1.5,
  },

  Wallington: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Wallkill: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 2.7,
    lca: 2.8,
    lcl: 2.7,
    lcw: 2.7,
    lvc: 2.7,
    lck: 3.5,
    lsf: 2.5,
    lmc: 3,
    lpf: 3,
    lcs: 3,
    gor: 3.6,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.4,
    gbs: 3,
    gbd: 3.9,
    gbk: 2.1,
    gbm: 3.1,
    gdt: 2.1,
    gfc: 3.1,
    gfm: 3.1,
    gtf: 3.8,
    gft: 4,
    gge: 3.5,
    ghf: 3.4,
    gin: 2.6,
    gpm: 1.9,
    gri: 3.4,
    grp: 3.4,
    gsw: 3.5,
    gtm: 3.3,
    chy: 3.1,
    rtp: 2.1,
  },
  Walpole: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Walton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Wampsville: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Wappinger: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 6,
    lag: 6,
    lbt: 3.5,
    lcr: 4,
    lca: 2.7,
    lcl: 3,
    lcw: 2.5,
    lvc: 3.7,
    lck: 4.3,
    lsf: 3.5,
    lmc: 4.3,
    lpf: 3,
    lcs: 4,
    gor: 5.1,
    grc: 5.1,
    gti: 4.9,
    gbb: 4.8,
    gbs: 5.1,
    gbd: 4.8,
    gbk: 2.8,
    gbm: 4.7,
    gdt: 2.6,
    gfc: 3.1,
    gfm: 4.6,
    gtf: 4.8,
    gft: 5.8,
    gge: 5,
    ghf: 4.6,
    gin: 4.1,
    gpm: 3.1,
    gri: 4.4,
    grp: 4.6,
    gsw: 5.1,
    gtm: 4.8,
    chy: 4.3,
    rtp: 2.6,
  },
  Wareham: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2.5,
    lca: 2.5,
    lcl: 2,
    lcw: 1.5,
    lvc: 1.5,
    lck: 2.5,
    lsf: 1.5,
    lmc: 2.5,
    lpf: 1,
    lcs: 2,
    gor: 3.9,
    grc: 3.9,
    gti: 3.9,
    gbb: 3.6,
    gbs: 3.8,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 2.1,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.6,
  },
  Warners: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Wassaic: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Watchaug: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 1,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Waumbeck: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Wayland: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.2,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Weaver: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Wegatchie: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.6,
    grc: 4.2,
    gti: 4,
    gbb: 3.3,
    gbs: 3.5,
    gbd: 3.3,
    gbk: 2,
    gbm: 3.6,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.5,
    gtf: 3.7,
    gft: 3.8,
    gge: 3.5,
    ghf: 3.3,
    gin: 2.9,
    gpm: 2,
    gri: 3.3,
    grp: 3.3,
    gsw: 4,
    gtm: 3.7,
    chy: 3,
    rtp: 1.9,
  },
  Wellsboro: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Wenonah: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Westbury: {
    cor_s: 16.0,
    cor_g: 150,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 3.7,
    grc: 3.7,
    gti: 3.7,
    gbb: 3.4,
    gbs: 3.6,
    gbd: 3.4,
    gbk: 1.4,
    gbm: 3.3,
    gdt: 1.7,
    gfc: 2.2,
    gfm: 3.2,
    gtf: 3.4,
    gft: 4.4,
    gge: 3.6,
    ghf: 3.2,
    gin: 2.7,
    gpm: 1.9,
    gri: 3,
    grp: 3.2,
    gsw: 3.7,
    gtm: 3.4,
    chy: 2.9,
    rtp: 1.4,
  },
  Westland: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 3,
    lag: 3.5,
    lbt: 0.5,
    lcr: 0.7,
    lca: 0.8,
    lcl: 0.7,
    lcw: 0.7,
    lvc: 0.7,
    lck: 1.5,
    lsf: 0.5,
    lmc: 1,
    lpf: 1,
    lcs: 1,
    gor: 3,
    grc: 3.3,
    gti: 3.3,
    gbb: 2.8,
    gbs: 2.4,
    gbd: 3.3,
    gbk: 1.5,
    gbm: 2.5,
    gdt: 1.5,
    gfc: 2.5,
    gfm: 2.5,
    gtf: 3.2,
    gft: 3.4,
    gge: 2.9,
    ghf: 2.8,
    gin: 2,
    gpm: 1.3,
    gri: 2.8,
    grp: 2.8,
    gsw: 2.9,
    gtm: 2.7,
    chy: 2.5,
    rtp: 1.5,
  },
  Wethersfield: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Wharton: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Whately: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.9,
    grc: 3.2,
    gti: 3.2,
    gbb: 2.7,
    gbs: 2.3,
    gbd: 3.2,
    gbk: 1.4,
    gbm: 2.4,
    gdt: 1.4,
    gfc: 2.4,
    gfm: 2.4,
    gtf: 3.1,
    gft: 3.3,
    gge: 2.8,
    ghf: 2.7,
    gin: 1.9,
    gpm: 1.2,
    gri: 2.7,
    grp: 2.7,
    gsw: 2.8,
    gtm: 2.6,
    chy: 2.4,
    rtp: 1.4,
  },
  Whippany: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.2,
    grc: 4.2,
    gti: 4.2,
    gbb: 3.9,
    gbs: 4.1,
    gbd: 3.9,
    gbk: 1.9,
    gbm: 3.8,
    gdt: 2.2,
    gfc: 2.7,
    gfm: 3.7,
    gtf: 3.9,
    gft: 4.9,
    gge: 4.1,
    ghf: 3.7,
    gin: 3.2,
    gpm: 2.4,
    gri: 3.5,
    grp: 3.7,
    gsw: 4.2,
    gtm: 3.9,
    chy: 3.4,
    rtp: 1.9,
  },
  Whitelaw: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5.5,
    lag: 5.5,
    lbt: 3,
    lcr: 3.5,
    lca: 2.2,
    lcl: 2.5,
    lcw: 2,
    lvc: 3.2,
    lck: 3.8,
    lsf: 3,
    lmc: 3.8,
    lpf: 2.5,
    lcs: 3.5,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Whitman: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 3.5,
    lag: 4,
    lbt: 1,
    lcr: 1.2,
    lca: 1.3,
    lcl: 1.2,
    lcw: 1.2,
    lvc: 1.2,
    lck: 2,
    lsf: 1,
    lmc: 1.5,
    lpf: 1.5,
    lcs: 1.5,
    gor: 2.3,
    grc: 2.6,
    gti: 2.6,
    gbb: 2.1,
    gbs: 1.7,
    gbd: 2.6,
    gbk: 0.8,
    gbm: 1.8,
    gdt: 0.8,
    gfc: 1.8,
    gfm: 1.8,
    gtf: 2.5,
    gft: 2.7,
    gge: 2.2,
    ghf: 2.1,
    gin: 1.3,
    gpm: 0.6,
    gri: 2.1,
    grp: 2.1,
    gsw: 2.2,
    gtm: 2,
    chy: 1.8,
    rtp: 0.8,
  },
  Wilbraham: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 3,
    lca: 3,
    lcl: 2.5,
    lcw: 2,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 4,
    gbb: 3.7,
    gbs: 3.9,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 2,
    gfc: 2.5,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2.2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.7,
  },
  Willdin: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Willette: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 2.5,
    lag: 3.5,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 3.8,
    grc: 4.1,
    gti: 4.1,
    gbb: 3.6,
    gbs: 3.2,
    gbd: 2.8,
    gbk: 2.3,
    gbm: 3.3,
    gdt: 2.3,
    gfc: 3.3,
    gfm: 3.3,
    gtf: 4,
    gft: 4.2,
    gge: 3.7,
    ghf: 3.6,
    gin: 2.8,
    gpm: 2.1,
    gri: 3.6,
    grp: 3.6,
    gsw: 3.7,
    gtm: 3.5,
    chy: 3.3,
    rtp: 2.3,
  },
  Williamson: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Willowemoc: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Wilmington: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 2.5,
    lcr: 2.5,
    lca: 2,
    lcl: 2.5,
    lcw: 2.5,
    lvc: 2,
    lck: 3,
    lsf: 2,
    lmc: 3,
    lpf: 2,
    lcs: 2.5,
    gor: 3.6,
    grc: 4.2,
    gti: 4,
    gbb: 3.3,
    gbs: 3.5,
    gbd: 3.3,
    gbk: 2,
    gbm: 3.6,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.5,
    gtf: 3.7,
    gft: 3.8,
    gge: 3.5,
    ghf: 3.3,
    gin: 2.9,
    gpm: 2,
    gri: 3.3,
    grp: 3.3,
    gsw: 4,
    gtm: 3.7,
    chy: 3,
    rtp: 1.9,
  },
  Wilpoint: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
  Windsor: {
    cor_s: 14.5,
    cor_g: 135,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 3.1,
    grc: 3.8,
    gti: 3,
    gbb: 3.2,
    gbs: 3.6,
    gbd: 3.2,
    gbk: 0.8,
    gbm: 2.9,
    gdt: 1.3,
    gfc: 1.3,
    gfm: 2.3,
    gtf: 2.8,
    gft: 3.8,
    gge: 3,
    ghf: 3,
    gin: 2.5,
    gpm: 1.6,
    gri: 2.8,
    grp: 3,
    gsw: 3.6,
    gtm: 2.8,
    chy: 2.9,
    rtp: 1.1,
  },
  Winooski: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 5,
    grc: 5,
    gti: 4.8,
    gbb: 4.7,
    gbs: 5,
    gbd: 4.7,
    gbk: 2.7,
    gbm: 4.6,
    gdt: 2.5,
    gfc: 3,
    gfm: 4.5,
    gtf: 4.7,
    gft: 5.7,
    gge: 4.9,
    ghf: 4.5,
    gin: 4,
    gpm: 3,
    gri: 4.3,
    grp: 4.5,
    gsw: 5,
    gtm: 4.7,
    chy: 4.2,
    rtp: 2.5,
  },
  Wolcottsburg: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.5,
    grc: 4.1,
    gti: 3.9,
    gbb: 3.2,
    gbs: 3.4,
    gbd: 3.2,
    gbk: 1.9,
    gbm: 3.5,
    gdt: 1.8,
    gfc: 2.3,
    gfm: 3.4,
    gtf: 3.6,
    gft: 3.7,
    gge: 3.4,
    ghf: 3.2,
    gin: 2.8,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.2,
    gsw: 3.6,
    gtm: 3.6,
    chy: 2.9,
    rtp: 1.8,
  },
  Wonsqueak: {
    cor_s: 20.0,
    cor_g: 175,
    laa: 2.5,
    lag: 2,
    lbt: 0.2,
    lcr: 0.2,
    lca: 0.3,
    lcl: 0.2,
    lcw: 0.2,
    lvc: 0.2,
    lck: 1,
    lsf: 0.1,
    lmc: 0.5,
    lpf: 0.5,
    lcs: 0.5,
    gor: 4.5,
    grc: 4.8,
    gti: 4.8,
    gbb: 4.3,
    gbs: 3.9,
    gbd: 3.5,
    gbk: 3,
    gbm: 4,
    gdt: 3,
    gfc: 4,
    gfm: 4,
    gtf: 4.7,
    gft: 4.9,
    gge: 4.4,
    ghf: 4.3,
    gin: 3.5,
    gpm: 2.8,
    gri: 4.3,
    grp: 4.3,
    gsw: 4.4,
    gtm: 4.2,
    chy: 4,
    rtp: 3,
  },
  Woodbridge: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 2,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Woodlawn: {
    cor_s: 13.0,
    cor_g: 120,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.9,
    grc: 2.9,
    gti: 2.7,
    gbb: 2.6,
    gbs: 2.9,
    gbd: 2.6,
    gbk: 0.6,
    gbm: 2.5,
    gdt: 0.4,
    gfc: 0.9,
    gfm: 2.4,
    gtf: 2.6,
    gft: 3.6,
    gge: 2.8,
    ghf: 2.4,
    gin: 1.9,
    gpm: 0.9,
    gri: 2.2,
    grp: 2.4,
    gsw: 2.9,
    gtm: 2.6,
    chy: 2.1,
    rtp: 0.4,
  },
  Woodstock: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 4,
    lag: 4,
    lbt: 1,
    lcr: 1.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2,
    lck: 2.3,
    lsf: 2.5,
    lmc: 2.3,
    lpf: 2,
    lcs: 3,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },
  'Woodstock-Rock': {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3.5,
    lag: 3.5,
    lbt: 0.5,
    lcr: 1,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.5,
    lck: 1.8,
    lsf: 2,
    lmc: 1.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 2.6,
    grc: 3.3,
    gti: 2.5,
    gbb: 2.7,
    gbs: 3.1,
    gbd: 2.7,
    gbk: 0.3,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 0.8,
    gfm: 1.8,
    gtf: 2.3,
    gft: 3.3,
    gge: 2.5,
    ghf: 2.5,
    gin: 2,
    gpm: 1.1,
    gri: 2.3,
    grp: 2.5,
    gsw: 3.1,
    gtm: 2.3,
    chy: 2.4,
    rtp: 0.6,
  },

  Wooster: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.6,
    grc: 4.6,
    gti: 4.4,
    gbb: 4.3,
    gbs: 4.6,
    gbd: 4.3,
    gbk: 2.3,
    gbm: 4.2,
    gdt: 2.1,
    gfc: 2.6,
    gfm: 4.1,
    gtf: 4.3,
    gft: 5.3,
    gge: 4.5,
    ghf: 4.1,
    gin: 3.6,
    gpm: 2.6,
    gri: 3.9,
    grp: 4.1,
    gsw: 4.6,
    gtm: 4.3,
    chy: 3.8,
    rtp: 2.1,
  },
  Woostern: {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  'Woostern-Bath-V': {
    cor_s: 18.5,
    cor_g: 165,
    laa: 5,
    lag: 5.5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 4.8,
    grc: 4.8,
    gti: 4.6,
    gbb: 4.5,
    gbs: 4.8,
    gbd: 4.5,
    gbk: 2.5,
    gbm: 4.4,
    gdt: 2.3,
    gfc: 2.8,
    gfm: 4.3,
    gtf: 4.5,
    gft: 5.5,
    gge: 4.7,
    ghf: 4.3,
    gin: 3.8,
    gpm: 2.8,
    gri: 4.1,
    grp: 4.3,
    gsw: 4.8,
    gtm: 4.5,
    chy: 4,
    rtp: 2.3,
  },
  Worden: {
    cor_s: 12.0,
    cor_g: 110,
    laa: 3.5,
    lag: 3.5,
    lbt: 1,
    lcr: 2,
    lca: 2,
    lcl: 1.5,
    lcw: 1,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 0.5,
    lcs: 1.5,
    gor: 2.8,
    grc: 2.8,
    gti: 2.8,
    gbb: 2.5,
    gbs: 2.7,
    gbd: 2.5,
    gbk: 0.5,
    gbm: 2.4,
    gdt: 0.8,
    gfc: 1.3,
    gfm: 2.3,
    gtf: 2.5,
    gft: 3.5,
    gge: 2.7,
    ghf: 2.3,
    gin: 1.8,
    gpm: 1,
    gri: 2.1,
    grp: 2.3,
    gsw: 2.8,
    gtm: 2.5,
    chy: 2,
    rtp: 0.5,
  },
  Worth: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 4,
    lag: 4.5,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 0.5,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Wurtsboro: {
    cor_s: 18.0,
    cor_g: 165,
    laa: 4.5,
    lag: 4.5,
    lbt: 2,
    lcr: 2.5,
    lca: 1.2,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 2.2,
    lck: 2.8,
    lsf: 2,
    lmc: 2.8,
    lpf: 1.5,
    lcs: 2.5,
    gor: 4.4,
    grc: 4.4,
    gti: 4.2,
    gbb: 4.1,
    gbs: 4.4,
    gbd: 4.1,
    gbk: 2.1,
    gbm: 4,
    gdt: 1.9,
    gfc: 2.4,
    gfm: 3.9,
    gtf: 4.1,
    gft: 5.1,
    gge: 4.3,
    ghf: 3.9,
    gin: 3.4,
    gpm: 2.4,
    gri: 3.7,
    grp: 3.9,
    gsw: 4.4,
    gtm: 4.1,
    chy: 3.6,
    rtp: 1.9,
  },
  Wyalusing: {
    cor_s: 15.0,
    cor_g: 140,
    laa: 3,
    lag: 3.5,
    lbt: 1.5,
    lcr: 1.5,
    lca: 1,
    lcl: 1.5,
    lcw: 1.5,
    lvc: 1,
    lck: 2,
    lsf: 1,
    lmc: 2,
    lpf: 1,
    lcs: 1.5,
    gor: 3.1,
    grc: 3.7,
    gti: 3.5,
    gbb: 2.8,
    gbs: 3,
    gbd: 2.8,
    gbk: 1.5,
    gbm: 3.1,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3,
    gtf: 3.2,
    gft: 3.3,
    gge: 3,
    ghf: 2.8,
    gin: 2.4,
    gpm: 1.5,
    gri: 2.8,
    grp: 2.8,
    gsw: 3.5,
    gtm: 3.2,
    chy: 2.5,
    rtp: 1.4,
  },
  Yalesville: {
    cor_s: 16.5,
    cor_g: 155,
    laa: 5,
    lag: 5,
    lbt: 2.5,
    lcr: 3,
    lca: 1.7,
    lcl: 2,
    lcw: 1.5,
    lvc: 2.7,
    lck: 3.3,
    lsf: 2.5,
    lmc: 3.3,
    lpf: 2,
    lcs: 3,
    gor: 3.9,
    grc: 3.9,
    gti: 3.7,
    gbb: 3.6,
    gbs: 3.9,
    gbd: 3.6,
    gbk: 1.6,
    gbm: 3.5,
    gdt: 1.4,
    gfc: 1.9,
    gfm: 3.4,
    gtf: 3.6,
    gft: 4.6,
    gge: 3.8,
    ghf: 3.4,
    gin: 2.9,
    gpm: 1.9,
    gri: 3.2,
    grp: 3.4,
    gsw: 3.9,
    gtm: 3.6,
    chy: 3.1,
    rtp: 1.4,
  },
  Yorkshire: {
    cor_s: 17.5,
    cor_g: 165,
    laa: 4,
    lag: 4,
    lbt: 1.5,
    lcr: 2,
    lca: 0.7,
    lcl: 1,
    lcw: 1,
    lvc: 1.7,
    lck: 2.3,
    lsf: 1.5,
    lmc: 2.3,
    lpf: 1,
    lcs: 2,
    gor: 4,
    grc: 4,
    gti: 3.8,
    gbb: 3.7,
    gbs: 4,
    gbd: 3.7,
    gbk: 1.7,
    gbm: 3.6,
    gdt: 1.5,
    gfc: 2,
    gfm: 3.5,
    gtf: 3.7,
    gft: 4.7,
    gge: 3.9,
    ghf: 3.5,
    gin: 3,
    gpm: 2,
    gri: 3.3,
    grp: 3.5,
    gsw: 4,
    gtm: 3.7,
    chy: 3.2,
    rtp: 1.5,
  },
};

export default y_drained;
