import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles (() => ({
  headerCont: {
    backgroundColor: 'rgb(179,27,27)',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    alignItems: 'center',
    borderBottom: '2px solid rgb(80,80,80)'
  },
  left: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'fit-content'
  },
  cuLogo: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/Resources/cu_logo.gif)`,
    height: 37,
    width: 37,
    backgroundPositionY: -4
  },
  orgInfo: {
    marginLeft: '6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',
    fontFamily: '"Times New Roman","serif"',
  },
  orgName: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  toolName: {
    fontSize: '16px',
    fontStyle: 'italic'
  },
  link: {
    backgroundColor: 'rgb(159,7,7)',
    color: 'white',
    border: 'none',
    fontSize: '13px',
    padding: '6px 12px',
    borderRadius: '5px',
    height: 'fit-content',
    marginRight: '16px',
    '&:hover': {
      backgroundColor: 'rgb(139,7,7)',
      cursor: 'pointer'
    }
  }
}));

export default function Header() {
  let classes = useStyles();

  const navigate = () => {
    window.open('https://tools.forages.org/', '_blank')?.focus();
  };
  
  return (
    <header className={classes.headerCont}>
      <div className={classes.left}>
        <a href='https://www.cornell.edu/' target='_blank' rel='noreferrer'>
          <div className={classes.cuLogo}></div>
        </a>
        <div className={classes.orgInfo}>
          <div className={classes.orgName}>Cornell University</div>
          <div className={classes.toolName}>Forage Species Selector Tool</div>
        </div>
      </div>

      <button className={classes.link} onClick={navigate}>Grass Management Tools</button>
    </header>
  );
}