import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cover: {
    backgroundColor: 'rgba(0,0,0,0.75)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  box: {
    height: 150,
    width: 500,
    padding: 12,
    boxSizing: 'border-box',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '1px solid black',
    textAlign: 'center',
    color: 'rgb(200,50,50)'
  },
  note: {
    color: 'black',
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14
  },
  btn: {
    marginLeft: 30,
    marginRight: 30,
    color: 'white',
  },
  no: {
    backgroundColor: 'rgb(200,50,50)',
    '&:hover': {
      backgroundColor: 'rgb(150,0,0)'
    }
  },
  yes: {
    backgroundColor: 'rgb(50,200,50)',
    '&:hover': {
      backgroundColor: 'rgb(0,150,0)'
    }
  }
}));
    
export default function AsbChecker({ setAsbTreated, setAsbCheck }) {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <div className={classes.box}>
        <div>The location that you have selected is within a county that has known Alfalfa Snout Beetle infestations. Has the selected area been treated with ASB Nematodes?</div>
        <div className={classes.note}>(NOTE: If you are unsure select &apos;No&apos; in order to get more conservative recommendations.)</div>
        <Button className={`${classes.btn} ${classes.no}`} onClick={() => {setAsbTreated(false); setAsbCheck(false);}}>No</Button>
        <Button className={`${classes.btn} ${classes.yes}`} onClick={() => {setAsbTreated(true); setAsbCheck(false);}}>Yes</Button>
      </div>
    </div>
  );
}

AsbChecker.propTypes = {
  setAsbTreated: PropTypes.func,
  setAsbCheck: PropTypes.func
};