const nysfips = {
  'Albany': '001',
  'Allegany': '003',
  'Bronx': '005',
  'Broome': '007',
  'Cattaraugus': '009',
  'Cayuga': '011',
  'Chautauqua': '013',
  'Chemung': '015',
  'Chenango': '017',
  'Clinton': '019',
  'Columbia': '021',
  'Cortland': '023',
  'Delaware': '025',
  'Dutchess': '027',
  'Erie': '029',
  'Essex': '031',
  'Franklin': '033',
  'Fulton': '035',
  'Genesee': '037',
  'Greene': '039',
  'Hamilton': '041',
  'Herkimer': '043',
  'Jefferson': '045',
  'Kings': '047',
  'Lewis': '049',
  'Livingston': '051',
  'Madison': '053',
  'Monroe': '055',
  'Montgomery': '057',
  'Nassau': '059',
  'New York': '061',
  'Niagara': '063',
  'Oneida': '065',
  'Onondaga': '067',
  'Ontario': '069',
  'Orange': '071',
  'Orleans': '073',
  'Oswego': '075',
  'Otsego': '077',
  'Putnam': '079',
  'Queens': '081',
  'Rensselaer': '083',
  'Richmond': '085',
  'Rockland': '087',
  'St. Lawrence': '089',
  'Saratoga': '091',
  'Schenectady': '093',
  'Schoharie': '095',
  'Schuyler': '097',
  'Seneca': '099',
  'Steuben': '101',
  'Suffolk': '103',
  'Sullivan': '105',
  'Tioga': '107',
  'Tompkins': '109',
  'Ulster': '111',
  'Warren': '113',
  'Washington': '115',
  'Wayne': '117',
  'Westchester': '119',
  'Wyoming': '121',
  'Yates': '123'
};

export default nysfips;