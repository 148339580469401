import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  TextField,
  Button
} from '@material-ui/core';

import roundXDigits from '../Functions/rounding';
import { getCoordinates } from '../Functions/getData';
import nysfips from '../StaticData/nysFIPS';


const useStyles = makeStyles (() => ({
  locationCont: {
    textAlign: 'center',
    fontFamily: '"Times New Roman","serif"',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '6px'
  },
  label: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  infoItem: {
    fontSize: '13px',
    marginTop: '2px',
    minHeight: '13px'
  },
  submit: {
    backgroundColor: 'rgb(0,140,0)',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '11px',
    height: '25px',
    width: '150px',
    margin: '4px auto 0 auto',
    '&:hover': {
      backgroundColor: 'rgb(0,100,0)'
    }
  },
  textField: {
    '& .MuiInput-root': {
      padding: '0px 0px 4px 0px !important'
    }
  }
}));

const INPUT_PROPS = {
  spellCheck: false,
  style: {
    textAlign: 'center',
    fontSize: '12px',
  }
};



export default function LocationInfo({ options, handleChangeOptions, token }) {
  const [address, setAddress] = useState('');
  const [county, setCounty] = useState('');
  const [coordinates, setCoordinates] = useState('');
  
  const classes = useStyles();


  useEffect(() => {
    if (options === null || options.address === '') {
      setAddress('');
      setCounty('');
      setCoordinates('');
    } else {
      setAddress(formatAddress(options.address));
      setCounty(options.county);
      setCoordinates(`${roundXDigits(options.lat, 3)}\xB0N, ${roundXDigits(options.lon, 3)}\xB0W`);
    }
  }, [options.address, options.lat, options.lon, options.county]);


  const formatAddress = (address) => {
    address = address.split(', ');

    if (address[address.length - 1] === 'United States') {
      return address.slice(0,address.length - 1).join(', ');
    } else {
      return address.join(', ');
    }
  };

  const parseCoordinates = (coords) => {
    const regex = /[0-9]/g;
    let counter = 0;
    let newCoords = coords.split('').reduce((acc, letter, i) => {
      if (letter === '-' || letter === '.' || letter.match(regex)) {
        acc[counter] += letter;
      } else if (letter === ' ') {
        counter++;
        acc.push('');
      }

      if (i === coords.length - 1) {
        let filtered = acc.filter(item => item !== '');
  
        if (filtered.length === 2) {
          return [parseFloat(filtered[0]), parseFloat(filtered[1])].sort((a,b)=>a-b);
        } else {
          return false;
        }
      } else {
        return acc;
      }
    }, ['']);

    return newCoords;
  };

  const handleClick = async () => {
    let newCoordinates;
    if (coordinates !== '' && coordinates !== `${roundXDigits(options.lat, 3)}\xB0N, ${roundXDigits(options.lon, 3)}\xB0W`) {
      newCoordinates = parseCoordinates(coordinates);
    } else if (address !== '' && address + ', United States' !== options.address) {
      newCoordinates = await getCoordinates(address, token);
    } else if (county !== '' && county !== options.county) {
      let formatted = county.replaceAll(' ', '').toLowerCase();
      formatted = formatted[0].toUpperCase() + formatted.slice(1);

      if (Object.keys(nysfips).includes(formatted)) {
        newCoordinates = await getCoordinates(formatted, token);
      }
    }

    if (newCoordinates && newCoordinates.length === 2) {
      handleChangeOptions({coordinates: newCoordinates});
    }
  };


  return (
    <div className={classes.locationCont}>
      <TextField
        className={classes.textField}
        label='Address'
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        inputProps={INPUT_PROPS}
        InputLabelProps={{style: {fontSize: '14px'}}}
        autoComplete='chrome-off'
        error={address !== '' && address + ', United States' !== options.address}
        multiline
        fullWidth
        size='small'
      />

      <TextField
        className={classes.textField}
        label='County'
        value={county}
        onChange={(e) => setCounty(e.target.value)}
        inputProps={INPUT_PROPS}
        InputLabelProps={{style: {fontSize: '14px'}}}
        autoComplete='chrome-off'
        error={county !== '' && county !== options.county}
        multiline
        fullWidth
        size='small'
      />

      <TextField
        className={classes.textField}
        label='Coordinates'
        value={coordinates}
        onChange={(e) => setCoordinates(e.target.value)}
        inputProps={INPUT_PROPS}
        InputLabelProps={{style: {fontSize: '14px'}}}
        autoComplete='chrome-off'
        error={options.lat !== null && coordinates !== `${roundXDigits(options.lat, 3)}\xB0N, ${roundXDigits(options.lon, 3)}\xB0W`}
        multiline
        fullWidth
        size='small'
      />

      <Button className={classes.submit} onClick={handleClick}>Address Search</Button>
    </div>
  );
}



LocationInfo.propTypes = {
  options: PropTypes.object,
  handleChangeOptions: PropTypes.func,
  token: PropTypes.string
};