import React, { useState, useEffect, useRef } from 'react';

import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import Header from './Components/Header';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import Map from './Components/Map';
import OptionsPanel from './Components/OptionsPanel';
import FSSResults from './Components/FSSResults';
import SpeciesList from './Components/SpeciesList';
import Alert from './Components/Alert';
import AsbChecker from './Components/AsbChecker';
import Resources from './Components/Resources';

import { getSoilData, getPlaceData } from './Functions/getData';
import getSpeciesAndYield from './Functions/fssScripts';
import downloadPDF from './Functions/pdfConstructor';

import nysfips from './StaticData/nysFIPS';
import snout from './StaticData/snout';

// const MAPBOX_API_TOKEN = 'pk.eyJ1IjoicHJlY2lwYWRtaW4iLCJhIjoiY2xkdDVtcHI4MWxxeDNwbnZ1Y3Zva3cyMyJ9.BfelO13u0lXW7IWNaEA9DQ';
const MAPBOX_API_TOKEN = 'pk.eyJ1IjoicHJlY2lwYWRtaW4iLCJhIjoiY2txYjNjMHYxMGF4NTJ1cWhibHNub3BrdiJ9.1T_U5frbnHaHonvFpHenxQ';

const useStyles = makeStyles(() => ({
  appCont: {
    backgroundColor: 'rgb(211,211,211)',
    minWidth: '550px',
  },
  main: {
    boxSizing: 'border-box',
    minHeight: 'calc(100vh - 104px)',
    width: '100vw',
    minWidth: '550px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: '12px',
    overflowY: 'auto',
    padding: '20px 18px',
    height: '100%',
  },
  input: {
    display: 'flex',
    width: 'calc(100vw - 150px)',
    minWidth: '513px',
    maxWidth: '1500px',
    // minHeight: 'calc(100vh - 154px)',
    margin: '0 auto',
  },
  show: {
    display: 'inline',
  },
  hide: {
    display: 'none',
  },
}));

export default function App() {
  // const [asbCheck, setAsbCheck] = useState(false);
  // const [asbTreated, setAsbTreated] = useState(true);
  const [isResults, setIsResults] = useState(false);
  const [firstResults, setFirstResults] = useState(true);
  const [resultsAlert, setResultsAlert] = useState(null);
  const [speciesResults, setSpeciesResults] = useState({
    recommendedArr: [],
    acceptableArr: [],
    cornGrainYield: -1,
    cornSilageYield: -1,
    warning: false,
  });
  const [soilsAtPlace, setSoilsAtPlace] = useState({});
  const [params, setParams] = useSearchParams();
  const [options, setOptions] = useState({
    landUse: '',
    soil: '',
    drainage: 'none',
    foodUse: '',
    consUse: '',
    ph: '0',
    address: '',
    zipcode: null,
    county: null,
    fips: null,
    lat: null,
    lon: null,
    asbCheck: false,
    asbTreated: true
  });

  const classes = useStyles();
  const resultContainer = useRef();
  const mapRef = useRef();
  const page = useLocation();

  const checkForKey = (key, obj, ret) => {
    if (key === 'asbTreated' || key === 'asbCheck') {
      if (obj.has(key)) {
        const boolStr = obj.get(key);
        return boolStr === 'true';
      } else {
        return ret;
      }
    } else if (key === 'lat' || key === 'lon') {
      return obj.has(key) ? parseFloat(obj.get(key)) : ret;
    } else {
      return obj.has(key) ? obj.get(key) : ret;
    }
  };

  useEffect(() => {
    const newOptions = Object.keys(options).reduce((acc, k) => {
      let ret = '';
      if (k === 'drainage') ret = 'none';
      if (k === 'ph') ret = '0';
      if (k === 'asbCheck') ret = false;
      if (k === 'asbTreated') ret = true;
      if (['zipcode', 'county', 'fips', 'lat', 'lon'].includes(k)) ret = null;
      
      acc[k] = checkForKey(k, params, ret);
      return acc;
    }, {});
    
    if (newOptions.lat !== null && newOptions.lon !== null) {
      getSoilData(newOptions.lat, newOptions.lon).then((results) => {
        setSoilsAtPlace(results);
        
        if (results.items.length) {
          const soilOpts = results.items.map(item => item.value);
          
          // Fires if params soil is not in new list
          if (!(newOptions.soil && soilOpts.includes(newOptions.soil))) {
            newOptions.soil = soilOpts[0];
          }
        } else {
          newOptions.soil = '';
        }
        
        setOptions(newOptions);
        setParams(newOptions);
      });
    }
  }, []);

  useEffect(() => {
    if (page.pathname === '/' && !params.has('lat') && options.lat) {
      setParams(options);
    }
  }, [page]);

  useEffect(() => {
    if (
      options.landUse !== '' &&
      ((options.landUse === 'conservation' && options.consUse !== '') ||
        (options.landUse !== 'conservation' && options.foodUse !== '')) &&
      options.drainage !== ''
    ) {
      let results;
      try {
        if (options.ph === '0') {
          let soilName = options.soil;
          let soilObj = soilsAtPlace.items.find(
            (soil) => soil.value === soilName
          );
          results = getSpeciesAndYield({ ...options, ph: soilObj.ph });
        } else {
          results = getSpeciesAndYield(options);
        }
      } catch {
        results = {
          recommendedArr: [],
          acceptableArr: [],
          cornGrainYield: -1,
          cornSilageYield: -1,
          warning: false,
        };
      }
      setSpeciesResults(results);
    } else {
      setSpeciesResults({
        recommendedArr: [],
        acceptableArr: [],
        cornGrainYield: -1,
        cornSilageYield: -1,
        warning: false,
      });
    }
  }, [options]);

  useEffect(() => {
    if (!isResults) {
      if (
        speciesResults.recommendedArr.length > 0 ||
        speciesResults.acceptableArr.length > 0
      ) {
        setIsResults(true);
      }
    }
  }, [speciesResults]);

  useEffect(() => {
    if (
      speciesResults.recommendedArr.length > 0 ||
      speciesResults.acceptableArr.length > 0
    ) {
      if (firstResults) {
        resultContainer.current.scrollIntoView({ behavior: 'smooth' });
        setFirstResults(false);
      }
    }

    if (!firstResults && !options.asbCheck) {
      setResultsAlert(true);

      const timeId = setTimeout(() => {
        setResultsAlert(false);
      }, 4000);

      () => {
        clearTimeout(timeId);
      };
    }
  }, [speciesResults, options.asbCheck]);

  const handleCoordinateChange = async (coords) => {
    let results = await getPlaceData(coords[1], coords[0], MAPBOX_API_TOKEN);

    try {
      let res = results.features[0];

      if (res.place_name.includes('New York')) {
        let newPlace = {
          address: res.place_name,
          zipcode: null,
          county: null,
          fips: null,
          lat: coords[1],
          lon: coords[0],
        };

        res.context.forEach((context) => {
          if (context.id.includes('postcode')) {
            newPlace['zipcode'] = context.text;
          } else if (context.id.includes('district')) {
            newPlace['county'] = context.text.split(' ')[0];
            newPlace['fips'] = nysfips[newPlace['county']];
          }
        });

        if (newPlace['county'] === 'St.') {
          newPlace['county'] = 'St Lawrence';
        }

        return newPlace;
      } else {
        alert('Please select an area within New York.');
      }
    } catch {
      alert(
        'ERROR: An error occurred while attempting to retrieve data for the selected location. Please try again later.'
      );
    }

    return {
      address: '',
      zipcode: null,
      county: null,
      fips: null,
      lat: null,
      lon: null,
    };
  };

  const handleChangeOptions = async (newObj) => {
    let newOptions;

    if (Object.keys(newObj).includes('coordinates')) {
      if (!Object.keys(newObj).includes('place')) {
        newObj.place = await handleCoordinateChange(newObj.coordinates);
      }

      if (snout.includes(newObj.place.county)) {
        newObj.asbCheck = true;
      } else if (!options.asbTreated) {
        newObj.asbTreated = true;
      }

      let newSoils = await getSoilData(
        newObj.coordinates[1],
        newObj.coordinates[0]
      );
      setSoilsAtPlace(newSoils);

      const newPlace = {...newObj.place};
      delete newObj.place;
      delete newObj.coordinates;

      try {
        newObj = { ...newObj, ...newPlace, soil: newSoils.items[0].value };
      } catch {
        newObj = { ...newObj, ...newPlace, soil: '' };
      }
    }

    newOptions = {
      ...options,
      ...newObj,
    };

    // Use if persisting options between sessions is necessary
    // localStorage.setItem('options', JSON.stringify(newOptions));
    setOptions(newOptions);
    setParams(newOptions);
  };

  const handleExport = () => {
    mapRef.current.recenterViewport();
    setTimeout(() => {
      downloadPDF(options, speciesResults);
    }, 1001);
  };

  const getPHUsed = () => {
    let soil = soilsAtPlace.items.find((soil) => soil.value === options.soil);
    
    if (soil && soil?.ph) {
      return ` (${soil.ph})`;
    } else {
      return '';
    }
  };

  return (
    <div className={classes.appCont}>
      {resultsAlert && (
        <Alert variant='resultsUpdated'>
          Your forage species recommendations have been updated.
        </Alert>
      )}

      <Header />

      <Nav />

      <main className={classes.main}>
        <div className={classes.input}>
          <OptionsPanel
            options={options}
            soilsAtPlace={soilsAtPlace}
            handleChangeOptions={handleChangeOptions}
            token={MAPBOX_API_TOKEN}
            className={
              page.pathname === '/Resources/' ||
              page.pathname === '/Resources' ||
              page.pathname === '/Species-Descriptions'
                ? classes.hide
                : classes.show
            }
            path={page.pathname}
          />

          <Map
            token={MAPBOX_API_TOKEN}
            options={options}
            handleChangeOptions={handleChangeOptions}
            className={page.pathname === '/' ? classes.show : classes.hide}
            ref={mapRef}
            handleCoordinateChange={handleCoordinateChange}
          />

          <Routes>
            <Route path='/' element={''} />

            <Route path='/Species-Descriptions' element={<SpeciesList />} />

            <Route path='/Resources' element={<Resources />} />
          </Routes>
        </div>

        {page.pathname === '/' && (
          <FSSResults
            options={options}
            getPHUsed={getPHUsed}
            handleExport={handleExport}
            isResults={isResults}
            speciesResults={speciesResults}
            resultContainer={resultContainer}
          />
        )}
      </main>

      <Footer />

      {options.asbCheck && (
        <AsbChecker setAsbTreated={(val) => handleChangeOptions({asbTreated: val})} setAsbCheck={(val) => handleChangeOptions({asbCheck: val})} />
      )}
    </div>
  );
}