import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import roundXDigits from './rounding';

import { CONS_USE, FOOD_USE, LAND_USE } from '../StaticData/optionsInfo';

const constructSpeciesList = (y, doc, spList, title, landUse) => {
  doc.setLineDash([]);
  doc.line(5, y, 205, y);
  y += 8;

  doc.setFontSize(13);
  doc.setFont('times', 'bold');
  doc.text(title, 5, y);
  y += 5;
  
  doc.setFontSize(11);

  let x;
  if (landUse === 'conservation') {
    x = [105];
    
    doc.text('Species Name', x[0], y, null, null, 'center');
    y += 0.5;
    doc.line(x[0] - 12, y, x[0] + 12, y);
  } else if (landUse === 'feed') {
    x = [70, 140];

    doc.text('Species Name', x[0], y, null, null, 'center');
    doc.line(x[0] - 12, y + 0.5, x[0] + 12, y + 0.5);

    doc.text('Tons per acre', x[1], y, null, null, 'center');
    y += 0.5;
    doc.line(x[1] - 12, y, x[1] + 12, y);
  } else if (landUse === 'pasture') {
    let lbsX = 149;
    x = [39, 83, 101, 117, 133, 149, 165, 181, 197];
    y += 10;

    doc.text('Species Name', x[0], y, {align: 'center', maxWidth: 40});
    doc.line(x[0] - 12, y + 0.5, x[0] + 12, y + 0.5);

    doc.text('Tons per acre', x[1], y, null, null, 'center');
    doc.line(x[1] - 12, y + 0.5, x[1] + 12, y + 0.5);

    doc.text('Pounds dry matter/acre/month', lbsX, y - 5, null, null, 'center');

    ['May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov'].forEach((month, i) => {
      doc.text(month, x[i + 2], y, null, null, 'center');
      doc.line(x[i + 2] - 4, y + 0.5, x[i + 2] + 4, y + 0.5);
    });
  }
  y += 5;
  
  doc.setFont('times', 'normal');

  spList.forEach(row => {
    let split = 0;
    row.forEach((val, i) => {
      if (i === 0 && landUse === 'pasture') {
        let splitTitle = doc.splitTextToSize(val, 80);
        doc.text(splitTitle, x[i], y, null, null, 'center');

        if (splitTitle.length > 1) {
          split = 2.5 * (splitTitle.length - 1);
          y += split;
        }
      } else {
        doc.text(val, x[i], y, null, null, 'center');
      }
    });
    y += 5 + split;
  });

  return y;
};

const mmTopx = (mm) => {
  let standard = document.getElementById('my_mm');
  return Math.floor(mm * (standard.clientHeight/100));
};

function resizeForPDF(element) {
  let cropper = document.createElement('canvas').getContext('2d');
  let midY = element.clientHeight / 2;
  let midX = element.clientWidth / 2;
  let offset = mmTopx(50);
  let finalSize = mmTopx(100);
  let sY = midY - offset;
  let sX = midX - offset;

  return html2canvas(element).then(c => {
    cropper.canvas.width = finalSize;
    cropper.canvas.height = finalSize;
    cropper.drawImage(c, sX, sY, finalSize, finalSize, 0, 0, finalSize, finalSize);
    return { resizedImgURL: cropper.canvas.toDataURL(), w: 100, h: 100 };
  });
}   

const downloadPDF = async (fss, speciesResults) => {
  const doc = new jsPDF();
  let y = 10;

  doc.setFontSize(18);
  doc.setFont('times', 'bold');
  doc.text('Forages Tools: Forage Species Selector Tool', 105, y, null, null, 'center');
  y += 15;

  doc.setFontSize(13);
  doc.text('Selected Location and Options:', 5, y);
  y += 10;

  doc.setFontSize(11);
  [
    ['date', 'Date'],
    ['address', 'Address'],
    ['county', 'County'],
    ['', 'Coordinates'],
    ['line', 'line'],
    ['soil', 'Soil Type'],
    ['ph', 'Soil pH'],
    ['landUse', 'Forage Use'],
    ['drainage', 'Artificial Drainage Situation']
  ].forEach(arr => {
    if (arr[0] === 'line') {
      doc.setLineDash([2.5]);
      doc.line(16, y, 196, y);
      y += 6;
    } else {
      doc.setFont('times', 'bold');
      doc.text(arr[1] + ':', 15, y);

      doc.setFont('times', 'normal');

      if (arr[0] === '') {
        doc.text(`${roundXDigits(fss.lat, 3)}\xB0N, ${roundXDigits(fss.lon, 3)}\xB0W`, 195, y, null, null, 'right');
      } else if (arr[0] === 'address' || arr[0] === 'county') {
        doc.text(fss[arr[0]], 195, y, null, null, 'right');
      } else if (arr[0] === 'date') {
        let date = new Date().toDateString().split(' ');
        doc.text(`${date[1]} ${date[2]}, ${date[3]}`, 195, y, null, null, 'right');
      } else if (arr[0] === 'landUse') {
        doc.text(LAND_USE.items.find(obj => obj.value === fss[arr[0]]).text, 195, y, null, null, 'right');
        y += 5;
  
        let key = 'foodUse';
        let text = 'Intended Hay or Silage Use';
        let data = FOOD_USE.items;
  
        if (fss.landUse === 'pasture') {
          text = 'Intended Pasture Use';
        } else if (fss.landUse === 'conservation') {
          text = 'Intended Conservation Use';
          key = 'consUse';
          data = CONS_USE.items;
        }
          
        doc.setFont('times', 'bold');
        doc.text(text + ':', 15, y);
  
        doc.setFont('times', 'normal');
        doc.text(data.find(obj => obj.value === fss[key]).text, 195, y, null, null, 'right');
      } else if (arr[0] === 'drainage') {
        let cap = fss[arr[0]].split('')[0].toUpperCase();
        doc.text(cap + fss[arr[0]].slice(1), 195, y, null, null, 'right');
      } else {
        doc.text(fss[arr[0]], 195, y, null, null, 'right');
      }

      y += 5;
    }
  });

  y = constructSpeciesList(y, doc, speciesResults.recommendedArr, 'Recommended species for this application:', fss.landUse);
  y = constructSpeciesList(y, doc, speciesResults.acceptableArr, 'Acceptable but not recommended species for this application:', fss.landUse);
    
  if (fss.landUse === 'pasture' || fss.landUse === 'feed') {
    y += 7;
    doc.setFont('times', 'bold');
    doc.text('Note:', 20, y);
    doc.setFont('times', 'italic');

    let yieldDisc = 'Yields listed here are approximate and reflect forage trial results under ideal conditions.';
    let splitDisc = doc.splitTextToSize(yieldDisc, 150);
    doc.text(splitDisc, 32, y);
  }

  if (speciesResults.warning) {
    y += 7;
    doc.setFont('times', 'bold');
    doc.text('Note:', 20, y);
    doc.setFont('times', 'italic');

    let note;
    if (speciesResults.warning === 1) {
      note = 'Alfalfa is included in the acceptable list, but you should be aware that there is a possibility of Snout Beetle infestation in this area.';
    } else if (speciesResults.warning === 2) {
      note = 'Alfalfa would normally be included in the recommended list, but due to possible Snout Beetle infestation in this area, it has been moved to the acceptable but not recommended list. While the Alfalfa species are included in this list, be aware that they still may not be suitable given the presence of Snout Beetles.';
    }
      
    let splitNote = doc.splitTextToSize(note, 150);
    doc.text(splitNote, 32, y);
  }

  doc.addPage();
  
  let { resizedImgURL, w, h } = await resizeForPDF(document.querySelector('#map'));
  doc.addImage(resizedImgURL, 'canvas', 55, 55, w, h, 'map', 'NONE', 0);

  doc.save('output.pdf');
};

export default downloadPDF;