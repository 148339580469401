import species from '../StaticData/species';
import soilPH from '../StaticData/soilPH';
import soilInfo from '../StaticData/soilInfo';
import y_undrained from '../StaticData/y_undrained';
import y_drained from '../StaticData/y_drained';
import combo from '../StaticData/combo';

import roundXDigits from './rounding';

export default function getSpeciesAndYield(fssOptions) {
  if (fssOptions.soil === '') {
    return {
      recommendedArr: [],
      acceptableArr: [],
      cornGrainYield: false,
      cornSilageYield: false,
      warning: false,
    };
  } else if (!Object.keys(soilInfo).includes(fssOptions.soil)) {
    return {
      recommendedArr: ['not found'],
      acceptableArr: ['not found'],
      cornGrainYield: false,
      cornSilageYield: false,
      warning: false,
    };
  }

  try {
    let recommendedArr = [];
    let acceptableArr = [];
    let cornGrainYield = -1;
    let cornSilageYield = -1;
    let warning = false;

    let useStr = formatUseString(
      fssOptions.landUse,
      fssOptions.foodUse,
      fssOptions.consUse
    );

    let snout_flag = !fssOptions.asbTreated;

    species.forEach((s) => {
      let thisUse = s[useStr];
      if (thisUse > 1) {
        let yieldMod;
        if (s.combo === 'N') {
          let fullYield = get_yield(
            s.y_field,
            fssOptions.soil,
            fssOptions.drainage
          );
          let ph_mod = get_ph_yield_mod(s.id, fssOptions.ph);
          yieldMod = fullYield * ph_mod;
        } else if (s.combo === 'Y') {
          yieldMod = get_c_yield(
            s.id,
            s.name,
            fssOptions.soil,
            fssOptions.drainage,
            fssOptions.ph
          );
        }

        if (fssOptions.landUse === 'pasture') {
          yieldMod = yieldMod * 0.8;
        } else if (
          fssOptions.landUse === 'feed' &&
          fssOptions.foodUse === 'dry dairy'
        ) {
          yieldMod = yieldMod * 0.5;
        }

        if (yieldMod !== undefined) {
          let yCutString =
            'y_cut_' + soilInfo[fssOptions.soil].dr.split('')[0].toLowerCase();
          let yCut = s[yCutString];

          let resArr;
          if (fssOptions.landUse === 'pasture') {
            resArr = [s.name, roundXDigits(yieldMod, 1)];

            [
              s.y_may,
              s.y_jun,
              s.y_jul,
              s.y_aug,
              s.y_sep,
              s.y_oct,
              s.y_nov,
            ].forEach((monthMod) => {
              resArr.push(roundXDigits(monthMod * yieldMod * 1760, 0));
            });
          } else if (fssOptions.landUse === 'conservation') {
            resArr = [s.name];
          } else {
            resArr = [s.name, roundXDigits(yieldMod, 1)];
          }

          if (yieldMod > yCut) {
            const regex = /alfalfa/g;

            if (snout_flag && s.name.toLowerCase().match(regex)) {
              if (warning !== 2) {
                warning = thisUse === 2 ? 1 : 2;
              }

              thisUse = 2;
            }

            if (thisUse === 3) {
              recommendedArr.push(resArr);
            } else if (thisUse === 2) {
              acceptableArr.push(resArr);
            }
          }
        }
      }

      if (s.name === 'Corn') {
        if (fssOptions.landUse !== 'conservation') {
          cornGrainYield = get_yield(
            'cor_g',
            fssOptions.soil,
            fssOptions.drainage
          );
          cornSilageYield = get_yield(
            'cor_s',
            fssOptions.soil,
            fssOptions.drainage
          );
        }
      }
    });

    return {
      recommendedArr,
      acceptableArr,
      cornGrainYield,
      cornSilageYield,
      warning,
    };
  } catch {
    return {
      recommendedArr: ['error'],
      acceptableArr: ['error'],
      cornGrainYield: -1,
      cornSilageYield: -1,
      warning: false,
    };
  }
}

function formatUseString(landUse, foodUse, consUse) {
  let useStr = '';
  switch (foodUse) {
    case 'beef':
      useStr += 'b_s_';
      break;
    case 'dry dairy':
      useStr += 'd_dry_';
      break;
    case 'horses':
      useStr += 'h_';
      break;
    case 'wet dairy':
      useStr += 'd_lac_';
      break;
    case 'sheep':
      useStr += 'b_s_';
      break;
  }

  switch (landUse) {
    case 'feed':
      useStr += 'st';
      break;
    case 'pasture':
      useStr += 'pt';
      break;
    case 'conservation':
      switch (consUse) {
        case 'disturbed':
          useStr = 'c_dist';
          break;
        case 'erosion':
          useStr = 'c_ec';
          break;
        case 'plowdown':
          useStr = 'c_plow';
          break;
        case 'wildlife':
          useStr = 'c_wild';
          break;
      }
      break;
  }

  return useStr;
}

function get_yield(fieldName, soilName, drainage) {
  fieldName = fieldName.toLowerCase();
  let udYield = y_undrained[soilName][fieldName];
  let drYield = y_drained[soilName][fieldName];

  let currYield, midRange;
  switch (drainage) {
    case 'optimum':
      currYield = drYield;
      break;
    case 'moderate':
      midRange = (udYield + drYield) / 2;
      currYield = (drYield + midRange) / 2;
      break;
    case 'some':
      midRange = (udYield + drYield) / 2;
      currYield = (udYield + midRange) / 2;
      break;
    case 'none':
      currYield = udYield;
      break;
  }

  return currYield;
}

function get_ph_yield_mod(id, ph) {
  let phObj = soilPH[id];
  ph = parseFloat(ph);

  let phYieldMod, slope;
  if (ph === 0) {
    phYieldMod = 100;
  } else if (ph <= phObj.low_0) {
    phYieldMod = 0;
  } else if (ph <= phObj.low_min && ph > phObj.low_0) {
    phYieldMod = 8;
  } else if (ph > phObj.low_min && ph <= phObj.low_opt) {
    slope = 80 / (phObj.low_opt - phObj.low_min);
    phYieldMod = slope * ph + (80 - slope * phObj.low_opt);
  } else if (ph > phObj.low_opt && ph < phObj.hi_opt) {
    phYieldMod = 100;
  } else if (ph >= phObj.hi_opt && ph < phObj.hi_max) {
    slope = 80 / (phObj.hi_opt - phObj.hi_max);
    phYieldMod = slope * ph + (80 - slope * phObj.hi_opt);
  } else if (ph >= phObj.hi_max && ph < phObj.hi_0) {
    phYieldMod = 8;
  } else if (ph >= phObj.hi_0) {
    phYieldMod = 0;
  }

  if (phYieldMod > 100) {
    phYieldMod = 100;
  }
  phYieldMod = phYieldMod * 0.01;
  return phYieldMod;
}

function get_c_yield(id, fullName, soil, drainage, ph) {
  let yieldPercentageObj = combo[id];
  let speciesArr = fullName.split('-');

  return speciesArr.reduce((acc, name, i) => {
    let fragSpeciesObj = species.find((s) => s.name === name);

    let fragYield = get_yield(fragSpeciesObj.y_field, soil, drainage);
    let ph_mod = get_ph_yield_mod(fragSpeciesObj.id, ph);

    if (fragSpeciesObj.type === 'G') {
      fragYield = fragYield * 0.8;
    }

    let tempYield = fragYield * ph_mod;

    return (acc += yieldPercentageObj[`species${i + 1}`] * tempYield);
  }, 0);
}
