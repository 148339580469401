import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import RoomIcon from '@material-ui/icons/Room';
import {
  makeStyles,
  styled,
  Switch
} from '@material-ui/core';
import ReactMapGL, {
  NavigationControl,
  Marker,
  FlyToInterpolator,
  Source,
  Layer,
  AttributionControl
} from 'react-map-gl';


// Loading error fix
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import { getGeojsonData } from '../Functions/getData';

import snout from '../StaticData/snout';


const useStyles = makeStyles ((theme) => ({
  mapCont: {
    backgroundColor: 'white',
    color: 'white',
    position: 'relative',
    height: 775,
    // height: 'calc(100vh - 120px)',
    // minHeight: '325px',
    width: 'calc(100% - 200px)',
    margin: '0 auto',
    border: '1px solid black',
    boxSizing: 'border-box',
    borderRadius: '0px 6px 6px 0px'
  },
  map: {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: '0px 6px 6px 0px'
  },
  styleSelector: {
    position: 'absolute',
    top: '6px',
    left: '40px',
  },
  styleBtn: {
    border: 'none',
    width: '70px',
    height: '24.5px',
    boxSizing: 'border-box',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(228,228,228)'
    }
  },
  topLeft: {
    borderRight: '1px solid rgb(180,180,180)'
  },
  bottomLeft: {
    borderRight: '1px solid rgb(180,180,180)'
  },
  styleBtnRowTop: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '139px',
    border: '1px solid black',
    borderBottom: '1px solid rgb(180,180,180)'
  },
  styleBtnRowBottom: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '139px',
    border: '1px solid black',
    borderTop: 'none'
  },
  selectedStyle: {
    backgroundColor: 'rgb(220,220,220)',
    boxShadow: 'inset 1px 1px 1px 1px rgba(0,0,0,0.15)',
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'rgb(220,220,220)'
    }
  },
  marker: {
    color: 'red',
    fontSize: '30px !important'
  },
  mapNav: {
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  switchesCont: {
    position: 'absolute',
    top: '6px',
    left: '185px',
    backgroundColor: 'rgb(248,248,248)',
    border: '1px solid black',
    color: 'black',
    padding: '4px 12px',
    [theme.breakpoints.down('xs')]: {
      right: '6px'
    }
  },
  switchTitle: {
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '1px',
    marginBottom: '5px'
  },
  switchWrapper: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: 'bold',
    alignItems: 'center'
  },
  switchLabels: {
    height: 'fit-content',
  },
  disabled: {
    color: 'rgb(180,180,180)'
  },
  legend: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 6,
    right: 6,
    padding: 7,
    width: 160,
    textAlign: 'center',
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      right: '50%',
      top: 'auto',
      bottom: 10,
      transform: 'translateX(50%)'
    }
  },
  legendText: {
    color: 'red',
    fontSize: 12,
    lineHeight: 1.1
  }
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  margin: '3px',
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'rgb(0,175,0)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgb(255,0,0)',
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: 'rgb(180,180,180) !important'
  }
}));



const Map = forwardRef(({ token, options, handleChangeOptions, className, handleCoordinateChange }, ref) => {
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/satellite-streets-v11');
  const [selectedStyle, setSelectedStyle] = useState('Hybrid');
  const [location, setLocation] = useState(() => {
    if (options.lat && options.lon) {
      return [options.lat, options.lon];
    } else {
      return false;
    }
  });
  const [viewport, setViewport] = useState({
    latitude: 43.21,
    longitude: -75.37,
    zoom: 5.7,
    minZoom: 5.7,
  });
  const [geojson, setGeojson] = useState({ points: false, lines: false});
  const [showSoilsOverlay, setShowSoilsOverlay] = useState(true);
  const [showSnoutOverlay, setShowSnoutOverlay] = useState(true);
  
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    recenterViewport() {
      setViewport({
        ...viewport,
        zoom: 13.0,
        latitude: location[0],
        longitude: location[1],
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 500
      });
    }
  }));


  useEffect(() => {
    if (options.lat && options.lon) {
      getGeojsonData(options.lat, options.lon).then(results => setGeojson(results));
    }
  }, []);


  useEffect(() => {
    if (options.lat !== location[0] || options.lon !== location[1]) {
      if (options.lat && options.lon) {
        setLocation([options.lat, options.lon]);
        
      } else {
        setLocation(false);
      }
    }

    if (options.lat && options.lon && showSoilsOverlay) {
      getGeojsonData(options.lat, options.lon).then(res => setGeojson(res));
    } else {
      setGeojson({ points: false, lines: false});
    }
  }, [options.lat, options.lon]);

  useEffect(() => {
    if (location) {
      let zoom = viewport.zoom;
      if (zoom < 14.5) {
        zoom = 14.5;
      }
      
      setViewport({
        ...viewport,
        zoom: zoom,
        latitude: location[0],
        longitude: location[1],
        transitionInterpolator: new FlyToInterpolator(),
        transitionDuration: 1000
      });
    }
  }, [location]);

  const handlePanning = (view) => {
    let nextView = view;

    if (nextView.latitude > 49 || nextView.latitude < 40) {
      nextView.latitude = viewport.latitude;
    }

    if (nextView.longitude > -66 || nextView.longitude < -81) {
      nextView.longitude = viewport.longitude;
    }

    setViewport(nextView);
  };

  const handleStyleSelect = (name, style) => {
    setSelectedStyle(name);
    setMapStyle(style);
  };

  const handleSwitch = (event, overlayName) => {
    if (overlayName === 'soils') {
      if (event.target.checked && !geojson) {
        getGeojsonData(...location).then(res => setGeojson(res));
      }
  
      setShowSoilsOverlay(event.target.checked);
    } else {
      setShowSnoutOverlay(event.target.checked);
    }
  };

  const checkBounds = (lt, ln) => {
    return (lt >= 40.526327 && lt <= 45.050240 && ln >= -79.765468 && ln <= -71.853540 );
  };

  return (
    <div className={`${classes.mapCont} ${className}`}>
      <div id='map' className={classes.map}>
        <ReactMapGL
          {...viewport}
          width= "100%"
          height= "100%"
          onViewportChange={nextViewport => handlePanning(nextViewport)}
          mapboxApiAccessToken={token}
          mapStyle={mapStyle}
          dragRotate={false}
          doubleClickZoom={false}
          attributionControl={false}
          preserveDrawingBuffer={true}
          onClick={(e) => {
            let coords = e.lngLat;
            if (checkBounds(coords[1], coords[0])) {
              handleCoordinateChange(coords)
                .then(res => {
                  if (res.lat !== null && res.lon !== null) {
                    setLocation([coords[1], coords[0]]);
                    handleChangeOptions({coordinates: coords, place: res});
                  }
                });
            }
          }}
        >

          <Source type = "vector" url = "mapbox://precipadmin.c7r67bb2" >
            <Layer
              id= 'counties'
              type= 'fill'
              source-layer= 'NYS_Civil_Boundaries-7g2ah4'
              paint={{
                'fill-color': 'rgba(255,131,131,0.15)',
                'fill-outline-color': 'rgba(255,131,131,0.3)'
              }}
              filter={['in', ['get', 'NAME'], ['literal', snout]]}
              layout={{
                visibility: showSnoutOverlay ? 'visible' : 'none'
              }}
            />
          </Source>
          
          <Source id='soil-map-lines' type='geojson' data={geojson.lines} />
          <Layer
            source='soil-map-lines'
            type='line'
            layout={{
              visibility: showSoilsOverlay ? 'visible' : 'none',
              'line-cap': 'square'
            }}
            paint={{
              'line-color': 'rgb(244,175,100)',
              'line-width': 1
            }}
          />

          <Source id='soil-map-points' type='geojson' data={geojson.points} />
          <Layer
            source='soil-map-points'
            type='symbol'
            layout={{
              visibility: showSoilsOverlay ? 'visible' : 'none',
              'text-field': ['get', 'title'],
              'text-size': 13
            }}
            paint={{
              'text-color': 'rgb(244,175,100)'
            }}
          />
          

          { location && 
            <Marker
              latitude={location[0]}
              longitude={location[1]}
              offsetLeft={-15}
              offsetTop={-29}
            >
              <RoomIcon className={classes.marker} />
            </Marker>
          }

          <NavigationControl
            className={classes.mapNav}
            showCompass={false}
          />

          <AttributionControl compact={true} style={{bottom: -4, right: -4}} />
        </ReactMapGL>
      </div>

      <div className={classes.styleSelector}>
        <div className={classes.styleBtnRowTop}>
          <button className={`${classes.styleBtn} ${classes.topLeft} ${selectedStyle === 'Hybrid' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Hybrid', 'mapbox://styles/mapbox/satellite-streets-v11')}>Hybrid</button>
          <button className={`${classes.styleBtn} ${selectedStyle === 'Map' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Map', 'mapbox://styles/mapbox/streets-v11')}>Map</button>
        </div>
        <div className={classes.styleBtnRowBottom}>
          <button className={`${classes.styleBtn} ${classes.bottomLeft} ${selectedStyle === 'Satellite' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Satellite', 'mapbox://styles/mapbox/satellite-v9')}>Satellite</button>
          <button className={`${classes.styleBtn} ${selectedStyle === 'Terrain' && classes.selectedStyle}`} onClick={() => handleStyleSelect('Terrain', 'mapbox://styles/mapbox/outdoors-v11')}>Terrain</button>
        </div>
      </div>

      <div className={classes.switchesCont}>
        <div className={classes.switchTitle}>Soils Overlay</div>

        <div className={classes.switchWrapper}>
          <div className={`${classes.switchLabels} ${Object.keys(geojson).length === 0 && classes.disabled}`}>Hide</div>
          <AntSwitch
            className={classes.switch}
            checked={showSoilsOverlay}
            onChange={(e) => handleSwitch(e, 'soils')}
            disabled={!location}
          />
          <div className={`${classes.switchLabels} ${Object.keys(geojson).length === 0 && classes.disabled}`}>Show</div>
        </div>

        <div className={classes.switchTitle} style={{marginTop: 6}}>Snout Beetle Overlay</div>

        <div className={classes.switchWrapper}>
          <div className={classes.switchLabels}>Hide</div>
          <AntSwitch
            className={classes.switch}
            checked={showSnoutOverlay}
            onChange={(e) => handleSwitch(e, 'snout')}
          />
          <div className={classes.switchLabels}>Show</div>
        </div>
      </div>

      <div className={classes.legend}>
        <div className={classes.legendText}>Counties highlighted with red indicate the presence of Alfalfa Snout Beetle</div>
      </div>
    </div>
  );
});



Map.propTypes = {
  token: PropTypes.string,
  options: PropTypes.object,
  className: PropTypes.string,
  handleChangeOptions: PropTypes.func,
  handleCoordinateChange: PropTypes.func
};

export default Map;