import React from 'react';
import { NavLink } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  navCont: {
    width: '100%',
    height: 50,
    backgroundColor: 'rgb(0,150,0)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5%',
    zIndex: 1
  },
  tabLink: {
    textDecoration: 'none',
    fontSize: 17,
    fontWeight: 700,
    height: 35,
    color: 'white',
    padding: '6px 14px',
    boxSizing: 'border-box',
    margin: '0px 4px',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: 'rgb(0,130,0)'
    }
  },
  selected: {
    border: '1px solid #f6f95a'
  }
}));

const items = [
  { pathName: '/', label: 'Forage Species Selector'},
  { pathName: '/Species-Descriptions', label: 'Species Descriptions'},
  { pathName: '/Resources', label: 'Resources'}
];



export default function Nav() {
  const classes = useStyles();

  return (
    <nav className={classes.navCont}>
      {items.map((item, i) => {
        return (
          <NavLink
            key={i}
            to={item.pathName}
            className={({isActive}) => isActive ? `${classes.tabLink} ${classes.selected}` : classes.tabLink}
          >
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>{item.label}</Typography>
          </NavLink>
        );
      })}
    </nav>
  );
}