const species = [{'id':67,'name':'Reed Canarygrass','d_lac_pt':3,'d_lac_st':3,'d_dry_pt':2,'d_dry_st':3,'b_s_pt':3,'b_s_st':3,'h_pt':3,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':3,'y_cut_e':2,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':1.5,'y_field':'GRC','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':66,'name':'Redtop','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':3,'c_wild':1,'c_plow':1,'c_ec':2,'y_cut_e':0.2,'y_cut_w':0.2,'y_cut_m':0.2,'y_cut_s':0.2,'y_cut_p':0.2,'y_cut_v':0.2,'y_field':'RTP','y_may':0.32,'y_jun':0.38,'y_jul':0.07,'y_aug':0.06,'y_sep':0.12,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':65,'name':'Red Clover-Timothy','d_lac_pt':1,'d_lac_st':3,'d_dry_pt':1,'d_dry_st':3,'b_s_pt':1,'b_s_st':3,'h_pt':3,'h_st':3,'c_dist':1,'c_wild':1,'c_plow':2,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C037','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':64,'name':'Red Clover-Tall Fescue','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':1,'b_s_st':2,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':2,'c_ec':1,'y_cut_e':4,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C038','y_may':0.28,'y_jun':0.33,'y_jul':0.11,'y_aug':0.1,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':62,'name':'Red Clover','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':3,'c_ec':2,'y_cut_e':0.8,'y_cut_w':0.8,'y_cut_m':0.8,'y_cut_s':0.8,'y_cut_p':0.8,'y_cut_v':0.8,'y_field':'LCR','y_may':0.3,'y_jun':0.4,'y_jul':0.1,'y_aug':0.1,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':63,'name':'Red Clover-Alsike Clover-Timothy','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':2,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':2,'c_ec':1,'y_cut_e':7,'y_cut_w':7,'y_cut_m':7,'y_cut_s':2,'y_cut_p':1.8,'y_cut_v':2,'y_field':'C039','y_may':0.32,'y_jun':0.38,'y_jul':0.07,'y_aug':0.06,'y_sep':0.12,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':61,'name':'Perennial Ryegrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':2,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3.5,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'GRP','y_may':0.31,'y_jun':0.36,'y_jul':0.08,'y_aug':0.07,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':60,'name':'Orchardgrass','d_lac_pt':3,'d_lac_st':3,'d_dry_pt':2,'d_dry_st':2,'b_s_pt':3,'b_s_st':3,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2.5,'y_cut_p':3,'y_cut_v':3,'y_field':'GOR','y_may':0.3,'y_jun':0.3,'y_jul':0.1,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':59,'name':'Meadow Foxtail','d_lac_pt':0,'d_lac_st':1,'d_dry_pt':0,'d_dry_st':1,'b_s_pt':0,'b_s_st':1,'h_pt':0,'h_st':1,'c_dist':0,'c_wild':0,'c_plow':1,'c_ec':1,'y_cut_e':3.5,'y_cut_w':3,'y_cut_m':3,'y_cut_s':1.8,'y_cut_p':2,'y_cut_v':0.5,'y_field':'GTM','y_may':0.27,'y_jun':0.35,'y_jul':0.1,'y_aug':0.08,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':58,'name':'Meadow Fescue','d_lac_pt':3,'d_lac_st':3,'d_dry_pt':3,'d_dry_st':3,'b_s_pt':3,'b_s_st':3,'h_pt':2,'h_st':1,'c_dist':3,'c_wild':1,'c_plow':2,'c_ec':2,'y_cut_e':3,'y_cut_w':2.5,'y_cut_m':2.5,'y_cut_s':2,'y_cut_p':1.5,'y_cut_v':1.5,'y_field':'GFM','y_may':0.32,'y_jun':0.38,'y_jul':0.07,'y_aug':0.06,'y_sep':0.12,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':57,'name':'Meadow Bromegrass','d_lac_pt':0,'d_lac_st':0,'d_dry_pt':0,'d_dry_st':0,'b_s_pt':0,'b_s_st':0,'h_pt':0,'h_st':0,'c_dist':1,'c_wild':0,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2.5,'y_cut_p':3,'y_cut_v':3,'y_field':'GBM','y_may':0.27,'y_jun':0.35,'y_jul':0.1,'y_aug':0.08,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':56,'name':'Matua Prairiegrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':1.5,'y_cut_w':1.5,'y_cut_m':1.5,'y_cut_s':1.5,'y_cut_p':1.8,'y_cut_v':2,'y_field':'GPM','y_may':0.24,'y_jun':0.28,'y_jul':0.1,'y_aug':0.1,'y_sep':0.18,'y_oct':0.1,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':55,'name':'Ladino White Clover-Timothy','d_lac_pt':3,'d_lac_st':2,'d_dry_pt':3,'d_dry_st':2,'b_s_pt':3,'b_s_st':3,'h_pt':2,'h_st':2,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.5,'y_cut_w':1.8,'y_cut_m':1.8,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C025','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':54,'name':'Ladino White Clover-Tall Fescue-Timothy','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':3,'b_s_st':3,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3.5,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C036','y_may':0.28,'y_jun':0.33,'y_jul':0.11,'y_aug':0.1,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':53,'name':'Ladino White Clover-Smooth Bromegrass-Timothy','d_lac_pt':3,'d_lac_st':1,'d_dry_pt':3,'d_dry_st':1,'b_s_pt':3,'b_s_st':3,'h_pt':2,'h_st':2,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C035','y_may':0.28,'y_jun':0.32,'y_jul':0.15,'y_aug':0.1,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':52,'name':'Ladino White Clover-Red Clover-Timothy-Perennial Ryegrass','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':3,'y_cut_p':2,'y_cut_v':2,'y_field':'C032','y_may':0.32,'y_jun':0.38,'y_jul':0.07,'y_aug':0.06,'y_sep':0.12,'y_oct':0.5,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':51,'name':'Ladino White Clover-Red Clover-Tall Fescue','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':1.8,'y_cut_p':1,'y_cut_v':1,'y_field':'C026','y_may':0.28,'y_jun':0.33,'y_jul':0.11,'y_aug':0.1,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':50,'name':'Ladino White Clover-Red Clover-Reed Canarygrass-Smooth Bromegrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C031','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':49,'name':'Ladino White Clover-Red Clover-Kentucky Bluegrass-Timothy','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C027','y_may':0.32,'y_jun':0.38,'y_jul':0.07,'y_aug':0.06,'y_sep':0.12,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':48,'name':'Ladino White Clover-Red Clover-Kentucky Bluegrass-Perennial Ryegrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':3,'y_cut_p':2,'y_cut_v':2,'y_field':'C033','y_may':0.31,'y_jun':0.36,'y_jul':0.08,'y_aug':0.07,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':47,'name':'Ladino White Clover-Red Clover-Alsike Clover-Timothy','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.5,'y_cut_w':1.8,'y_cut_m':1.8,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C029','y_may':0.32,'y_jun':0.38,'y_jul':0.07,'y_aug':0.06,'y_sep':0.12,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':46,'name':'Ladino White Clover-Red Clover-Alsike Clover-Orchardgrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2.5,'y_cut_p':3,'y_cut_v':3,'y_field':'C030','y_may':0.27,'y_jun':0.31,'y_jul':0.17,'y_aug':0.1,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':45,'name':'Ladino White Clover-Orchardgrass','d_lac_pt':3,'d_lac_st':2,'d_dry_pt':3,'d_dry_st':2,'b_s_pt':3,'b_s_st':3,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':1.8,'y_cut_w':1.8,'y_cut_m':1.8,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C024','y_may':0.27,'y_jun':0.31,'y_jul':0.17,'y_aug':0.1,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':44,'name':'Ladino White Clover-Kentucky Bluegrass-Timothy','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':3,'b_s_st':1,'h_pt':3,'h_st':3,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C034','y_may':0.3,'y_jun':0.32,'y_jul':0.1,'y_aug':0.08,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':43,'name':'Ladino White Clover-Alsike Clover-Timothy-Reed Canarygrass','d_lac_pt':3,'d_lac_st':1,'d_dry_pt':3,'d_dry_st':1,'b_s_pt':3,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':7,'y_cut_w':7,'y_cut_m':7,'y_cut_s':1.8,'y_cut_p':1.8,'y_cut_v':2.5,'y_field':'C028','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':42,'name':'Ladino White Clover','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.1,'y_cut_w':2.1,'y_cut_m':2.1,'y_cut_s':2.1,'y_cut_p':2.1,'y_cut_v':2.1,'y_field':'LCL','y_may':0.25,'y_jun':0.3,'y_jul':0.2,'y_aug':0.15,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':41,'name':'Kura Clover','d_lac_pt':0,'d_lac_st':0,'d_dry_pt':0,'d_dry_st':0,'b_s_pt':0,'b_s_st':0,'h_pt':0,'h_st':0,'c_dist':0,'c_wild':0,'c_plow':0,'c_ec':0,'y_cut_e':2.1,'y_cut_w':2.1,'y_cut_m':2.1,'y_cut_s':2.1,'y_cut_p':2.1,'y_cut_v':2.1,'y_field':'LCK','y_may':0.25,'y_jun':0.27,'y_jul':0.23,'y_aug':0.15,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':40,'name':'Kentucky Bluegrass-Smooth Bromegrass-Timothy','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':3,'h_st':3,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C043','y_may':0.32,'y_jun':0.38,'y_jul':0.07,'y_aug':0.06,'y_sep':0.12,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':39,'name':'Kentucky Bluegrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2.5,'y_cut_v':2.5,'y_field':'GBK','y_may':0.37,'y_jun':0.31,'y_jul':0.1,'y_aug':0.07,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':38,'name':'Italian Ryegrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3.5,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2.5,'y_cut_p':3,'y_cut_v':3.5,'y_field':'GRI','y_may':0.31,'y_jun':0.36,'y_jul':0.08,'y_aug':0.07,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':37,'name':'Indiangrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2,'y_cut_w':1.5,'y_cut_m':2,'y_cut_s':1.8,'y_cut_p':2.5,'y_cut_v':3,'y_field':'GIN','y_may':0,'y_jun':0.1,'y_jul':0.4,'y_aug':0.35,'y_sep':0.15,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':36,'name':'Flatpea','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':3,'c_wild':1,'c_plow':1,'c_ec':3,'y_cut_e':0.1,'y_cut_w':0.1,'y_cut_m':0.1,'y_cut_s':0.1,'y_cut_p':0.1,'y_cut_v':0.1,'y_field':'LPF','y_may':0.24,'y_jun':0.26,'y_jul':0.23,'y_aug':0.17,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':35,'name':'Festulolium hybrids','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':2,'c_wild':1,'c_plow':2,'c_ec':2,'y_cut_e':3.5,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'GHF','y_may':0.31,'y_jun':0.36,'y_jul':0.08,'y_aug':0.07,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':34,'name':'Eastern Gamagrass','d_lac_pt':0,'d_lac_st':0,'d_dry_pt':0,'d_dry_st':0,'b_s_pt':0,'b_s_st':0,'h_pt':1,'h_st':1,'c_dist':0,'c_wild':3,'c_plow':1,'c_ec':0,'y_cut_e':2,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':1.8,'y_cut_v':2,'y_field':'GGE','y_may':0.05,'y_jun':0.15,'y_jul':0.3,'y_aug':0.35,'y_sep':0.15,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':33,'name':'Deer Tongue','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':3,'c_wild':2,'c_plow':1,'c_ec':2,'y_cut_e':0.1,'y_cut_w':0.1,'y_cut_m':0.1,'y_cut_s':0.1,'y_cut_p':1.5,'y_cut_v':1.5,'y_field':'GDT','y_may':0.05,'y_jun':0.15,'y_jul':0.3,'y_aug':0.35,'y_sep':0.15,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':32,'name':'Deborah Brome','d_lac_pt':0,'d_lac_st':0,'d_dry_pt':0,'d_dry_st':0,'b_s_pt':0,'b_s_st':0,'h_pt':0,'h_st':0,'c_dist':0,'c_wild':0,'c_plow':1,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2.5,'y_cut_p':2.5,'y_cut_v':2.5,'y_field':'GBD','y_may':0.3,'y_jun':0.34,'y_jul':0.15,'y_aug':0.07,'y_sep':0.09,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':31,'name':'Crownvetch-Timothy','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':2,'c_wild':2,'c_plow':1,'c_ec':3,'y_cut_e':1,'y_cut_w':1,'y_cut_m':1,'y_cut_s':1,'y_cut_p':1,'y_cut_v':1,'y_field':'C023','y_may':0.24,'y_jun':0.3,'y_jul':0.17,'y_aug':0.11,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':30,'name':'Crownvetch-Tall Fescue','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':2,'c_wild':2,'c_plow':1,'c_ec':3,'y_cut_e':1,'y_cut_w':1,'y_cut_m':1,'y_cut_s':1,'y_cut_p':1,'y_cut_v':1,'y_field':'C022','y_may':0.26,'y_jun':0.26,'y_jul':0.19,'y_aug':0.13,'y_sep':0.13,'y_oct':0.03,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':29,'name':'Crownvetch-Orchardgrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':2,'c_wild':2,'c_plow':1,'c_ec':3,'y_cut_e':1,'y_cut_w':1,'y_cut_m':1,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C021','y_may':0.28,'y_jun':0.28,'y_jul':0.15,'y_aug':0.13,'y_sep':0.13,'y_oct':0.03,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':28,'name':'Crownvetch','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':3,'c_wild':1,'c_plow':1,'c_ec':3,'y_cut_e':0.4,'y_cut_w':0.4,'y_cut_m':0.4,'y_cut_s':0.4,'y_cut_p':0.4,'y_cut_v':0.4,'y_field':'LVC','y_may':0.25,'y_jun':0.25,'y_jul':0.23,'y_aug':0.17,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':27,'name':'Creeping Red Fescue','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':0,'h_st':1,'c_dist':3,'c_wild':1,'c_plow':1,'c_ec':3,'y_cut_e':0.2,'y_cut_w':0.2,'y_cut_m':0.2,'y_cut_s':0.2,'y_cut_p':0.2,'y_cut_v':0.2,'y_field':'GFC','y_may':0.3,'y_jun':0.3,'y_jul':0.1,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':25,'name':'Cicer Milkvetch','d_lac_pt':0,'d_lac_st':0,'d_dry_pt':0,'d_dry_st':0,'b_s_pt':0,'b_s_st':0,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':0,'c_plow':0,'c_ec':0,'y_cut_e':2.1,'y_cut_w':2.1,'y_cut_m':2.1,'y_cut_s':2.1,'y_cut_p':2.1,'y_cut_v':2.1,'y_field':'LMC','y_may':0.25,'y_jun':0.26,'y_jul':0.23,'y_aug':0.17,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':26,'name':'Creeping Foxtail','d_lac_pt':0,'d_lac_st':1,'d_dry_pt':0,'d_dry_st':1,'b_s_pt':0,'b_s_st':1,'h_pt':0,'h_st':1,'c_dist':3,'c_wild':0,'c_plow':1,'c_ec':0,'y_cut_e':3,'y_cut_w':3,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':0.2,'y_field':'GTF','y_may':0.37,'y_jun':0.31,'y_jul':0.1,'y_aug':0.07,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':24,'name':'Birdsfoot Trefoil-Timothy-Kentucky Bluegrass','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':2,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C019','y_may':0.23,'y_jun':0.29,'y_jul':0.2,'y_aug':0.13,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':23,'name':'Birdsfoot Trefoil-Timothy','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':1,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':7,'y_cut_w':7,'y_cut_m':7,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C014','y_may':0.2,'y_jun':0.3,'y_jul':0.25,'y_aug':0.1,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':22,'name':'Birdsfoot Trefoil-Tall Fescue','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':1,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':2,'c_plow':1,'c_ec':2,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C015','y_may':0.27,'y_jun':0.27,'y_jul':0.2,'y_aug':0.11,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':21,'name':'Birdsfoot Trefoil-Smooth Bromegrass','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':1,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C017','y_may':0.25,'y_jun':0.32,'y_jul':0.17,'y_aug':0.11,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':20,'name':'Birdsfoot Trefoil-Reed Canarygrass','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':1,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':2,'c_plow':1,'c_ec':2,'y_cut_e':3,'y_cut_w':7,'y_cut_m':7,'y_cut_s':2,'y_cut_p':2,'y_cut_v':1.9,'y_field':'C013','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':19,'name':'Birdsfoot Trefoil-Orchardgrass-Kentucky Bluegrass','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':2,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C020','y_may':0.3,'y_jun':0.3,'y_jul':0.1,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':18,'name':'Birdsfoot Trefoil-Orchardgrass','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':1,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C016','y_may':0.3,'y_jun':0.3,'y_jul':0.1,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':17,'name':'Birdsfoot Trefoil-Meadow Fescue','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':0,'c_wild':0,'c_plow':1,'c_ec':0,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':1.4,'y_cut_v':2,'y_field':'C018','y_may':0.3,'y_jun':0.32,'y_jul':0.13,'y_aug':0.1,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':16,'name':'Birdsfoot Trefoil-Kentucky Bluegrass','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':2,'b_s_st':1,'h_pt':2,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C012','y_may':0.27,'y_jun':0.27,'y_jul':0.2,'y_aug':0.11,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':15,'name':'Birdsfoot Trefoil','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':3,'c_wild':1,'c_plow':1,'c_ec':2,'y_cut_e':0.4,'y_cut_w':0.4,'y_cut_m':0.4,'y_cut_s':0.4,'y_cut_p':0.4,'y_cut_v':0.4,'y_field':'LBT','y_may':0.18,'y_jun':0.31,'y_jul':0.22,'y_aug':0.14,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':14,'name':'Big Bluestem','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':3,'c_wild':3,'c_plow':1,'c_ec':1,'y_cut_e':2,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2.5,'y_cut_v':2.5,'y_field':'GBB','y_may':0,'y_jun':0.1,'y_jul':0.4,'y_aug':0.35,'y_sep':0.15,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':13,'name':'Alsike Clover','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.1,'y_cut_w':2.1,'y_cut_m':2.1,'y_cut_s':2.1,'y_cut_p':2.1,'y_cut_v':2.1,'y_field':'LCA','y_may':0.4,'y_jun':0.4,'y_jul':0.07,'y_aug':0.06,'y_sep':0.07,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':12,'name':'Alfalfa-Meadow Fescue','d_lac_pt':2,'d_lac_st':3,'d_dry_pt':2,'d_dry_st':3,'b_s_pt':2,'b_s_st':3,'h_pt':1,'h_st':2,'c_dist':1,'c_wild':1,'c_plow':2,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2.5,'y_cut_m':2.5,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C004','y_may':0.21,'y_jun':0.23,'y_jul':0.21,'y_aug':0.16,'y_sep':0.14,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':12,'name':'Alfalfa-Timothy-Smooth Bromegrass-Ladino White Clover','d_lac_pt':3,'d_lac_st':1,'d_dry_pt':3,'d_dry_st':2,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2.5,'y_cut_m':2.5,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C010','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.15,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':11,'name':'Alfalfa-Timothy','d_lac_pt':2,'d_lac_st':2,'d_dry_pt':2,'d_dry_st':3,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':3,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3.5,'y_cut_w':3,'y_cut_m':3,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C001','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.15,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':10,'name':'Alfalfa-Tall Fescue','d_lac_pt':2,'d_lac_st':3,'d_dry_pt':2,'d_dry_st':3,'b_s_pt':2,'b_s_st':3,'h_pt':1,'h_st':2,'c_dist':1,'c_wild':1,'c_plow':2,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2.5,'y_cut_m':2.5,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C004','y_may':0.21,'y_jun':0.23,'y_jul':0.21,'y_aug':0.16,'y_sep':0.14,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':9,'name':'Alfalfa-Smooth Bromegrass','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':3,'d_dry_st':3,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':3,'c_dist':1,'c_wild':1,'c_plow':2,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2.5,'y_cut_m':2.5,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C005','y_may':0.24,'y_jun':0.26,'y_jul':0.23,'y_aug':0.15,'y_sep':0.1,'y_oct':0.02,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':8,'name':'Alfalfa-Reed Canarygrass','d_lac_pt':2,'d_lac_st':3,'d_dry_pt':2,'d_dry_st':3,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2.5,'y_cut_m':2.5,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C003','y_may':0.21,'y_jun':0.23,'y_jul':0.21,'y_aug':0.16,'y_sep':0.14,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':7,'name':'Alfalfa-Perennial Ryegrass','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':2,'c_dist':1,'c_wild':1,'c_plow':2,'c_ec':1,'y_cut_e':3.5,'y_cut_w':3,'y_cut_m':3,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C006','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.15,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':6,'name':'Alfalfa-Orchardgrass-Smooth Bromegrass-Ladino White Clover','d_lac_pt':2,'d_lac_st':1,'d_dry_pt':2,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2.5,'y_cut_m':2.5,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C011','y_may':0.23,'y_jun':0.23,'y_jul':0.2,'y_aug':0.16,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':5,'name':'Alfalfa-Orchardgrass-Ladino White Clover','d_lac_pt':3,'d_lac_st':1,'d_dry_pt':3,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2.5,'y_cut_m':2.5,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C009','y_may':0.23,'y_jun':0.23,'y_jul':0.2,'y_aug':0.16,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':4,'name':'Alfalfa-Orchardgrass','d_lac_pt':3,'d_lac_st':3,'d_dry_pt':3,'d_dry_st':2,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':2,'c_ec':1,'y_cut_e':2.5,'y_cut_w':2.5,'y_cut_m':2.5,'y_cut_s':3.5,'y_cut_p':3.7,'y_cut_v':3.7,'y_field':'C002','y_may':0.23,'y_jun':0.23,'y_jul':0.2,'y_aug':0.16,'y_sep':0.13,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':3,'name':'Alfalfa-Birdsfoot Trefoil-Timothy','d_lac_pt':1,'d_lac_st':3,'d_dry_pt':1,'d_dry_st':3,'b_s_pt':1,'b_s_st':3,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':7,'y_cut_w':7,'y_cut_m':2.5,'y_cut_s':1.5,'y_cut_p':3,'y_cut_v':3,'y_field':'C008','y_may':0.26,'y_jun':0.29,'y_jul':0.15,'y_aug':0.15,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':2,'name':'Alfalfa-Birdsfoot Trefoil-Reed Canarygrass','d_lac_pt':1,'d_lac_st':3,'d_dry_pt':1,'d_dry_st':3,'b_s_pt':1,'b_s_st':3,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':7,'y_cut_w':7,'y_cut_m':2.5,'y_cut_s':1.5,'y_cut_p':3,'y_cut_v':3,'y_field':'C007','y_may':0.2,'y_jun':0.24,'y_jul':0.21,'y_aug':0.16,'y_sep':0.14,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':1,'name':'Alfalfa','d_lac_pt':2,'d_lac_st':2,'d_dry_pt':2,'d_dry_st':2,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':2,'c_dist':1,'c_wild':1,'c_plow':3,'c_ec':1,'y_cut_e':2.9,'y_cut_w':2.9,'y_cut_m':3.2,'y_cut_s':3.2,'y_cut_p':3.2,'y_cut_v':3.2,'y_field':'LAA','y_may':0.24,'y_jun':0.26,'y_jul':0.23,'y_aug':0.17,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':68,'name':'Sainfoin','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.1,'y_cut_w':2.1,'y_cut_m':2.1,'y_cut_s':2.1,'y_cut_p':2.1,'y_cut_v':2.1,'y_field':'LSF','y_may':0.24,'y_jun':0.26,'y_jul':0.23,'y_aug':0.17,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':69,'name':'Smooth Bromegrass','d_lac_pt':2,'d_lac_st':2,'d_dry_pt':2,'d_dry_st':2,'b_s_pt':2,'b_s_st':3,'h_pt':2,'h_st':3,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':3,'y_cut_p':3.2,'y_cut_v':3,'y_field':'GBS','y_may':0.3,'y_jun':0.34,'y_jul':0.15,'y_aug':0.07,'y_sep':0.09,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':70,'name':'Sweetclover','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':3,'c_ec':2,'y_cut_e':0.4,'y_cut_w':0.4,'y_cut_m':0.4,'y_cut_s':0.4,'y_cut_p':0.4,'y_cut_v':0.4,'y_field':'LCS','y_may':0.24,'y_jun':0.26,'y_jul':0.23,'y_aug':0.17,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':71,'name':'Switchgrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':3,'c_wild':3,'c_plow':1,'c_ec':3,'y_cut_e':2,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2.5,'y_cut_v':2.5,'y_field':'GSW','y_may':0,'y_jun':0.15,'y_jul':0.35,'y_aug':0.35,'y_sep':0.15,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':72,'name':'Tall Fescue','d_lac_pt':3,'d_lac_st':3,'d_dry_pt':3,'d_dry_st':3,'b_s_pt':3,'b_s_st':3,'h_pt':2,'h_st':1,'c_dist':3,'c_wild':1,'c_plow':2,'c_ec':2,'y_cut_e':2.5,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':1.5,'y_field':'GFT','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':73,'name':'Timothy','d_lac_pt':1,'d_lac_st':3,'d_dry_pt':1,'d_dry_st':3,'b_s_pt':1,'b_s_st':1,'h_pt':2,'h_st':3,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3.5,'y_cut_w':2,'y_cut_m':2,'y_cut_s':1.8,'y_cut_p':1.8,'y_cut_v':2,'y_field':'GTI','y_may':0.32,'y_jun':0.38,'y_jul':0.07,'y_aug':0.06,'y_sep':0.12,'y_oct':0.05,'y_nov':0,'combo':'N','pasture':'Y','type':'G'},
  {'id':74,'name':'White Clover','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':2.1,'y_cut_w':2.1,'y_cut_m':2.1,'y_cut_s':2.1,'y_cut_p':2.1,'y_cut_v':2.1,'y_field':'LCW','y_may':0.25,'y_jun':0.3,'y_jul':0.2,'y_aug':0.15,'y_sep':0.1,'y_oct':0,'y_nov':0,'combo':'N','pasture':'Y','type':'L'},
  {'id':75,'name':'White Clover-Kentucky Bluegrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':1.8,'y_cut_w':1.8,'y_cut_m':1.8,'y_cut_s':1.8,'y_cut_p':1.8,'y_cut_v':1.8,'y_field':'C040','y_may':0.3,'y_jun':0.31,'y_jul':0.12,'y_aug':0.09,'y_sep':0.15,'y_oct':0.03,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':76,'name':'White Clover-Orchardgrass','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':4,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C041','y_may':0.27,'y_jun':0.31,'y_jul':0.17,'y_aug':0.1,'y_sep':0.1,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':77,'name':'White Clover-Timothy','d_lac_pt':1,'d_lac_st':1,'d_dry_pt':1,'d_dry_st':1,'b_s_pt':1,'b_s_st':1,'h_pt':1,'h_st':1,'c_dist':1,'c_wild':1,'c_plow':1,'c_ec':1,'y_cut_e':3,'y_cut_w':2,'y_cut_m':2,'y_cut_s':2,'y_cut_p':2,'y_cut_v':2,'y_field':'C042','y_may':0.25,'y_jun':0.3,'y_jul':0.15,'y_aug':0.1,'y_sep':0.15,'y_oct':0.05,'y_nov':0,'combo':'Y','pasture':'Y','type':'C'},
  {'id':78,'name':'Forage Chicory','d_lac_pt':'','d_lac_st':'','d_dry_pt':'','d_dry_st':'','b_s_pt':'','b_s_st':'','h_pt':'','h_st':'','c_dist':'','c_wild':'','c_plow':'','c_ec':'','y_cut_e':'','y_cut_w':'','y_cut_m':'','y_cut_s':'','y_cut_p':'','y_cut_v':'','y_field':'GCF','y_may':'','y_jun':'','y_jul':'','y_aug':'','y_sep':'','y_oct':'','y_nov':'','combo':'','pasture':'','type':''},
  {'id':79,'name':'Corn','d_lac_pt':'','d_lac_st':'','d_dry_pt':'','d_dry_st':'','b_s_pt':'','b_s_st':'','h_pt':'','h_st':'','c_dist':'','c_wild':'','c_plow':'','c_ec':'','y_cut_e':'','y_cut_w':'','y_cut_m':'','y_cut_s':'','y_cut_p':'','y_cut_v':'','y_field':'','y_may':'','y_jun':'','y_jul':'','y_aug':'','y_sep':'','y_oct':'','y_nov':'','combo':'','pasture':'','type':''},
  {'id':80,'name':'Soybean','d_lac_pt':'','d_lac_st':'','d_dry_pt':'','d_dry_st':'','b_s_pt':'','b_s_st':'','h_pt':'','h_st':'','c_dist':'','c_wild':'','c_plow':'','c_ec':'','y_cut_e':'','y_cut_w':'','y_cut_m':'','y_cut_s':'','y_cut_p':'','y_cut_v':'','y_field':'','y_may':'','y_jun':'','y_jul':'','y_aug':'','y_sep':'','y_oct':'','y_nov':'','combo':'','pasture':'','type':''}];

export default species;