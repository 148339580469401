import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 704,
      md: 900,
      lg: 1098,
      xl: 1536,
    },
  },
  'palette': {
    'common': {
      'black': '#000',
      'white': '#fff'
    },
    'background': {
      'paper': '#fff',
      'default': 'rgba(242, 242, 242, 1)'
    },
    'primary': {
      'light': '#80e27e',
      'main': '#4caf50',
      'dark': '#087f23',
      'contrastText': '#fff'
    },
    'secondary': {
      'light': '#4c8c4a',
      'main': '#1b5e20',
      'dark': '#003300',
      'contrastText': '#fff'
    },
    'error': {
      'light': 'rgba(244, 147, 54, 1)',
      'main': 'rgba(244, 206, 54, 1)',
      'dark': 'rgba(238, 195, 19, 1)',
      'contrastText': 'rgba(0, 0, 0, 1)'
    },
    'text': {
      'primary': 'rgba(0, 0, 0, 0.87)',
      'secondary': 'rgba(0, 0, 0, 0.54)',
      'disabled': 'rgba(0, 0, 0, 0.38)',
      'hint': 'rgba(0, 0, 0, 0.38)'
    }
  },
  typography: {
    h6: {
      fontWeight: 'bold'
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
