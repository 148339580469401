const soilPH = {
  '1': { 'low_0':4.6, 'low_min':5.6, 'low_opt':6.5, 'hi_opt':7.4, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':3, 'nrate':0},
  '13': { 'low_0':4.2, 'low_min':5.2, 'low_opt':5.8, 'hi_opt':6.5, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':2, 'nrate':0},
  '14': { 'low_0':3.7, 'low_min':4.7, 'low_opt':5.6, 'hi_opt':6.6, 'hi_max':7.6, 'hi_0':8.6, 'opt_cuts':2, 'nrate':75},
  '15': { 'low_0':4.4, 'low_min':5.4, 'low_opt':6, 'hi_opt':6.7, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':3, 'nrate':0},
  '78': { 'low_0':3.5, 'low_min':4.5, 'low_opt':5.5, 'hi_opt':6.5, 'hi_max':8.3, 'hi_0':9.3, 'opt_cuts':2, 'nrate':150},
  '25': { 'low_0':4.8, 'low_min':5.8, 'low_opt':6.4, 'hi_opt':7.2, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':2, 'nrate':0},
  '26': { 'low_0':4.5, 'low_min':5.5, 'low_opt':6, 'hi_opt':6.7, 'hi_max':7.8, 'hi_0':8.8, 'opt_cuts':3, 'nrate':200},
  '27': { 'low_0':3.8, 'low_min':4.8, 'low_opt':5.6, 'hi_opt':6.5, 'hi_max':8, 'hi_0':9, 'opt_cuts':3, 'nrate':200},
  '28': { 'low_0':4.7, 'low_min':5.7, 'low_opt':6.3, 'hi_opt':6.8, 'hi_max':8, 'hi_0':9, 'opt_cuts':2, 'nrate':0},
  '32': { 'low_0':4.2, 'low_min':5.2, 'low_opt':5.8, 'hi_opt':7.1, 'hi_max':7.9, 'hi_0':8.9, 'opt_cuts':3, 'nrate':200},
  '33': { 'low_0':3.5, 'low_min':4.5, 'low_opt':5, 'hi_opt':6.5, 'hi_max':7.5, 'hi_0':8.5, 'opt_cuts':0, 'nrate':0},
  '34': { 'low_0':3.7, 'low_min':4.7, 'low_opt':5.4, 'hi_opt':6.6, 'hi_max':7.5, 'hi_0':8.5, 'opt_cuts':2, 'nrate':150},
  '35': { 'low_0':4.1, 'low_min':5.1, 'low_opt':5.9, 'hi_opt':6.6, 'hi_max':8, 'hi_0':9, 'opt_cuts':3, 'nrate':200},
  '36': { 'low_0':4.8, 'low_min':5.8, 'low_opt':6.4, 'hi_opt':7, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':1, 'nrate':0},
  '37': { 'low_0':4.6, 'low_min':5.6, 'low_opt':6, 'hi_opt':6.5, 'hi_max':7.1, 'hi_0':8.1, 'opt_cuts':2, 'nrate':75},
  '38': { 'low_0':4.2, 'low_min':5.2, 'low_opt':5.8, 'hi_opt':6.5, 'hi_max':8, 'hi_0':9, 'opt_cuts':3, 'nrate':200},
  '39': { 'low_0':4.1, 'low_min':5.1, 'low_opt':5.9, 'hi_opt':6.7, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':3, 'nrate':200},
  '41': { 'low_0':4.4, 'low_min':5.4, 'low_opt':5.9, 'hi_opt':6.5, 'hi_max':7.8, 'hi_0':8.8, 'opt_cuts':3, 'nrate':0},
  '42': { 'low_0':4.5, 'low_min':5.5, 'low_opt':6, 'hi_opt':6.5, 'hi_max':7.8, 'hi_0':8.8, 'opt_cuts':2, 'nrate':0},
  '56': { 'low_0':4, 'low_min':5, 'low_opt':6, 'hi_opt':6.8, 'hi_max':8, 'hi_0':9, 'opt_cuts':3, 'nrate':200},
  '57': { 'low_0':4.2, 'low_min':5.2, 'low_opt':5.8, 'hi_opt':6.8, 'hi_max':8, 'hi_0':9, 'opt_cuts':3, 'nrate':200},
  '58': { 'low_0':3.5, 'low_min':4.5, 'low_opt':5.6, 'hi_opt':6.7, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':3, 'nrate':200},
  '59': { 'low_0':4, 'low_min':5, 'low_opt':5.6, 'hi_opt':6.6, 'hi_max':7.8, 'hi_0':8.8, 'opt_cuts':3, 'nrate':200},
  '60': { 'low_0':3.9, 'low_min':4.9, 'low_opt':5.7, 'hi_opt':6.6, 'hi_max':8, 'hi_0':9, 'opt_cuts':3, 'nrate':200},
  '61': { 'low_0':4.1, 'low_min':5.1, 'low_opt':5.9, 'hi_opt':6.6, 'hi_max':8.3, 'hi_0':9.4, 'opt_cuts':3, 'nrate':200},
  '62': { 'low_0':4.5, 'low_min':5.5, 'low_opt':6.1, 'hi_opt':6.8, 'hi_max':8, 'hi_0':9, 'opt_cuts':2, 'nrate':0},
  '66': { 'low_0':3.5, 'low_min':4.5, 'low_opt':5.3, 'hi_opt':6.5, 'hi_max':8.3, 'hi_0':9.3, 'opt_cuts':0, 'nrate':0},
  '67': { 'low_0':3.8, 'low_min':4.8, 'low_opt':5.6, 'hi_opt':6.7, 'hi_max':8, 'hi_0':9, 'opt_cuts':3, 'nrate':200},
  '68': { 'low_0':4.9, 'low_min':5.9, 'low_opt':6.4, 'hi_opt':7.2, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':2, 'nrate':0},
  '69': { 'low_0':4.2, 'low_min':5.2, 'low_opt':5.9, 'hi_opt':6.9, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':3, 'nrate':200},
  '70': { 'low_0':4.9, 'low_min':5.9, 'low_opt':6.4, 'hi_opt':7.2, 'hi_max':8.2, 'hi_0':9.2, 'opt_cuts':1, 'nrate':0},
  '71': { 'low_0':3.7, 'low_min':4.7, 'low_opt':5.6, 'hi_opt':6.6, 'hi_max':7.6, 'hi_0':8.6, 'opt_cuts':2, 'nrate':75},
  '72': { 'low_0':3.7, 'low_min':4.7, 'low_opt':5.6, 'hi_opt':6.7, 'hi_max':8.3, 'hi_0':9.3, 'opt_cuts':3, 'nrate':200},
  '73': { 'low_0':3.9, 'low_min':4.9, 'low_opt':5.6, 'hi_opt':6.6, 'hi_max':7.8, 'hi_0':8.8, 'opt_cuts':3, 'nrate':200},
  '74': { 'low_0':4.5, 'low_min':5.5, 'low_opt':6, 'hi_opt':6.5, 'hi_max':7.8, 'hi_0':8.8, 'opt_cuts':2, 'nrate':0}
};

export default soilPH;