import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import {
  Typography,
  makeStyles,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';

const useStyles = makeStyles({
  smallRadioButton: {
    '& svg': {
      width: '15px',
      height: '15px'
    },
  },
  radioPadding: {
    padding: '2px 5px 2px 25px'
  },
  label: {
    fontSize: '12px'
  },
  formLabel: {
    fontSize: '13px'
  }
});



export default function CustomRadio({ infoObj, selected, setSelected }) {
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const classes = useStyles();

  return (
    <FormControl fullWidth>
      <FormLabel className={classes.formLabel}>{infoObj.label}</FormLabel>
      <RadioGroup
        name={infoObj.label.replaceAll(' ', '-')}
        value={selected}
        onChange={handleChange}
      >
        {
          infoObj.items.map(item => <FormControlLabel className={classes.smallRadioButton} key={uuidv4()} value={item.value} control={<Radio className={classes.radioPadding}/>} label={<Typography className={classes.label}>{item.text}</Typography>} />)
        }
      </RadioGroup>
    </FormControl>
  );
}


CustomRadio.propTypes = {
  infoObj: PropTypes.object,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};