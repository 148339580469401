import React from 'react';

const speciesHTML = {
  'Alfalfa': <>
    <h1>Alfalfa (<em>Medicago sativa</em> L.) (Lucerne)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season (<strong>pure alfalfa, no grass</strong>)</li>

      <li>Seeds/pound: 200,000</li>

      <li>Seeding rate: 12-15 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, or late summer before August 15</li>

      <li>Optimum harvest management for 40% NDF (hay) or 45% NDF (haylage) is generally 3 harvests per year in NYS, and is influenced by variety</li>

      <li>If grazing, select variety specifically developed for grazing</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>excellent quality possible</li>

      <li>higher yielding than grass, on good alfalfa soils</li>

      <li>more drought tolerant than grass</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>not adapted to acid or variably drained soils</li>

      <li>not suitable for alfalfa snout beetle-infested regions</li>

      <li>can cause bloat</li>

      <li>needs fall rest period</li>

      <li>potential for heaving on many soil types in NYS</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://forages.oregonstate.edu/php/fact_sheet_print_legume.php?SpecID=1&amp;use=Forage" target="_blank" rel="noreferrer">Forage Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_mesa.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://www.plantmanagementnetwork.org/pub/fg/management/2004/alfalfa/" target="_blank" rel="noreferrer">Alfalfa Establishment Guide</a></li>

      <li><a href="http://www.ag.ndsu.edu/pubs/plantsci/hay/r648w.htm" target="_blank" rel="noreferrer">Alfalfa Growth and Development</a></li>

      <li><a href="http://forages.oregonstate.edu/fi/publications?PageID=345&amp;PubID=410" target="_blank" rel="noreferrer">Nitrogen Fertilization of Alfalfa</a></li>

      <li><a href="http://ohioline.osu.edu/ac-fact/0043.html" target="_blank" rel="noreferrer">Verticillium Wilt of Alfalfa</a></li>

    </ul></>,
  'Alfalfa-Birdsfoot Trefoil-Reed Canarygrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 60:10:30</li>

      <li>Seeding rate (lbs/acre): 6/4/7</li>

      <li>Variably drained soils, with spots too wet for alfalfa</li>

      <li>Stored feed or intensive pasture</li>

      <li>Birdsfoot trefoil may not persist</li>

    </ul></>,
  'Alfalfa-Birdsfoot Trefoil-Timothy': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 60:10:30</li>

      <li>Seeding rate (lbs/acre): 6/4/6</li>

      <li>Variably drained soils, with spots too wet for alfalfa</li>

      <li>Stored feed or intensive pasture</li>

      <li>Birdsfoot trefoil may not persist</li>

    </ul></>,
  'Alfalfa-Orchardgrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 50:50</li>

      <li>Seeding rate (lbs/acre): 10/5</li>

      <li>Well-drained soils, for stored feed or intensive pasture</li>

      <li>Use late maturing orchardgrass variety in mixture</li>

      <li>Provides more midsummer grass than other mixtures</li>

    </ul></>,
  'Alfalfa-Orchardgrass-Ladino White Clover': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 40:50:10</li>

      <li>Seeding rate (lbs/acre): 8/2/2</li>

      <li>Well-drained soils, stored feed or intensive pasture</li>

    </ul></>,
  'Alfalfa-Orchardgrass-Smooth Bromegrass-Ladino White Clover': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 40:30:20:10</li>

      <li>Seeding rate (lbs/acre): 8/3/8/2</li>

      <li>For well-drained soils, stored feed or intensive pasture</li>

      <li>Early grazing, with higher percentage of grass in regrowth</li>

    </ul></>,
  'Alfalfa-Perennial Ryegrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 60:40</li>

      <li>Seeding rate (lbs/acre): 10/6</li>

      <li>Deep to moderately droughty, well-drained soils, intensive pasture</li>

      <li>Perennial ryegrass may not be competitive with alfalfa, and may not overwinter</li>

    </ul></>,
  'Alfalfa-Reed Canarygrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 70:30</li>

      <li>Seeding rate (lbs/acre): 10/8</li>

      <li>For deep to moderately droughty, well-drained soils</li>

      <li>Stored feed or intensive pasture</li>

      <li>Reed canarygrass will be slow to establish</li>

      <li>Provides a mixture with less grass, compared to orchardgrass mix</li>

    </ul></>,
  'Alfalfa-Smooth Bromegrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 60:40</li>

      <li>Seeding rate (lbs/acre): 10/8</li>

      <li>Deep, well-drained soils, intensive pasture</li>

      <li>Smooth bromegrass will not persist with a 3-4 harvest management</li>

    </ul></>,
  'Alfalfa-Tall Fescue': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 60:40</li>

      <li>Seeding rate (lbs/acre): 10/8</li>

      <li>Deep, well-drained soils, stored feed or intensive pasture</li>

      <li>Very little management information is available on this mixture</li>

    </ul></>,
  'Alfalfa-Timothy': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 70:30</li>

      <li>Seeding rate (lbs/acre): 10/4</li>

      <li>Well-drained soils, for stored feed or intensive pasture</li>

      <li>Use early maturing timothy variety in mixture</li>

    </ul></>,
  'Alfalfa-Timothy-Smooth Bromegrass-Ladino White Clover': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 50:20:20:10</li>

      <li>Seeding rate (lbs/acre): 8/2/8/2</li>

      <li>Well-drained soils, stored feed or intensive pasture</li>

    </ul></>,
  'Alsike Clover': <>
    <h1>Alsike Clover (<em>Trifolium hybridum</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 700,000</li>

      <li>Seeding rate: 6-10 lbs pure live seed/acre</li>

      <li>Similar to red clover in growth habit</li>

      <li>One to two harvests per season possible</li>

      <li>Best suited to pastures with marginal soils</li>

      <li>Do not use for horse pasture</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>very well adapted to acid or variably drained soils</li>

      <li>easy to establish</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>generally does not persist after 2 growing seasons</li>

      <li>much lower yielding than alfalfa</li>

      <li>can cause bloat and photosensitization in animals</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://forages.oregonstate.edu/php/fact_sheet_print_legume.php?SpecID=39&amp;use=Forage" target="_blank" rel="noreferrer">Forage Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_trhy.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_trhy.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

    </ul></>,
  'Big Bluestem': <>
    <h1>Big Bluestem (<em>Andropogon gerardii</em> Vitman)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 165,000</li>

      <li>Seeding rate: 10-12 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer before August 15</li>

      <li>Warm-season grass with most of growth in mid-summer</li>

      <li>Unacceptable forage quality for ruminants in NYS</li>

      <li>Erosion control for sand and gravel pits, mine spoil, and roadsides, cover for wildlife</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good yield potential</li>

      <li>tolerates low fertility, acid soils</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>unacceptably low forage quality</li>

      <li>fluffy seed difficult to sow</li>

      <li>loses forage quality rapidly</li>

      <li>slow to establish</li>

      <li>must be grown in separate pastures</li>

      <li>intolerant of poorly-drained soils</li>

      <li>poor shade tolerance</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_ange.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_ange.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://www.forages.psu.edu/topics/species_variety_trials/species/wsgrasses/establishment.html" target="_blank" rel="noreferrer">Warm-Season Grass Establishment</a></li>

      <li><a href="http://www.ernstseed.com/biomass/featured-biomass-species/big-bluestem/" target="_blank" rel="noreferrer">Big Bluestem (Ernst)</a></li>

    </ul></>,
  'Birdsfoot Trefoil': <>
    <h1>Birdsfoot Trefoil (<em>Lotus corniculatus</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 375,000</li>

      <li>Seeding rate: 4-6 lbs pure live seed/acre (10-12 lbs/acre for conservation purposes)</li>

      <li>Seeding date: early spring, or late summer before August 1</li>

      <li>Late maturing, better for pasture than hay crop</li>

      <li>Can graze frequently, but not closely, persists in NYS by reseeding</li>

      <li>Also used for erosion control, soil improvement and forage for deer</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>excellent quality possible</li>

      <li>well adapted to acid or variably drained soils</li>

      <li>no bloat hazard</li>

      <li>best legume to stockpile for fall grazing</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>not persistent in NYS due to Fusarium disease complex</li>

      <li>slow to establish, slow spring growth and regrowth</li>

      <li>lower yielding than alfalfa</li>

      <li>needs fall rest period</li>

      <li>poor shade tolerance</li>

      <li>fair drought tolerance</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://forages.oregonstate.edu/php/fact_sheet_print_legume.php?SpecID=12&amp;use=Forage" target="_blank" rel="noreferrer">Forage Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_loco6.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://cropsoil.psu.edu/extension/facts/agronomy-facts-20" target="_blank" rel="noreferrer">Penn State Fact Sheet</a></li>

    </ul></>,
  'Birdsfoot Trefoil-Kentucky Bluegrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 50:50</li>

      <li>Seeding rate (lbs/acre): 8/4</li>

      <li>Not recommended, low yielding, Birdsfoot trefoil may not persist</li>

    </ul></>,
  'Birdsfoot Trefoil-Meadow Fescue': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 40:60</li>

      <li>Seeding rate (lbs/acre): 8/9</li>

      <li>Moderately well-drained to poorly-drained soils, intensive pasture</li>

      <li>Very early grass production</li>

      <li>Birdsfoot Trefoil may not persist</li>

    </ul></>,
  'Birdsfoot Trefoil-Orchardgrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 30:70</li>

      <li>Seeding rate (lbs/acre): 7/3</li>

      <li>Deep, well-drained soils, stored feed or pasture</li>

      <li>Birdsfoot Trefoil may not persist</li>

    </ul></>,
  'Birdsfoot Trefoil-Orchardgrass-Kentucky Bluegrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 30:60:10</li>

      <li>Seeding rate (lbs/acre): 8/2/4</li>

      <li>Deep, well-drained soils, horse pasture</li>

      <li>Birdsfoot Trefoil may not persist</li>

    </ul></>,
  'Birdsfoot Trefoil-Reed Canarygrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 30:70</li>

      <li>Seeding rate (lbs/acre): 6/6</li>

      <li>Moderately droughty, well-drained to poorly-drained soils</li>

      <li>Stored feed or pasture, tolerates lower pH</li>

      <li>Birdsfoot trefoil may not persist, both species slow to establish</li>

    </ul></>,
  'Birdsfoot Trefoil-Smooth Bromegrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 30:70</li>

      <li>Seeding rate (lbs/acre): 7/5</li>

      <li>Deep, well-drained soils, stored feed or pasture</li>

      <li>Birdsfoot Trefoil may not persist</li>

    </ul></>,
  'Birdsfoot Trefoil-Tall Fescue': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 30:70</li>

      <li>Seeding rate (lbs/acre): 7/9</li>

      <li>Moderately well-drained to poorly-drained soils</li>

      <li>Stored feed or pasture, tolerates lower pH</li>

      <li>Birdsfoot Trefoil may not persist</li>

    </ul></>,
  'Birdsfoot Trefoil-Timothy': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 40:60</li>

      <li>Seeding rate (lbs/acre): 7/2</li>

      <li>Moderately well-drained to poorly-drained soils</li>

      <li>Stored feed or pasture, tolerates lower pH</li>

      <li>Birdsfoot trefoil may not persist</li>

    </ul></>,
  'Birdsfoot Trefoil-Timothy-Kentucky Bluegrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 30:50:20</li>

      <li>Seeding rate (lbs/acre): 8/2/4</li>

      <li>Deep, well-drained soils, horse pasture</li>

      <li>Birdsfoot Trefoil may not persist</li>

    </ul></>,
  'Cicer Milkvetch': <>
    <h1>Cicer Milkvetch (<em>Astragalus cicer</em> L.)</h1>
    <p>New/Untested species, not yet recommended for some or all applications</p>

    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 130,000</li>

      <li>Seeding rate: 12 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, or late summer before August 15</li>

      <li>Two to three harvests per season</li>

      <li>Seed inoculation is essential</li>

      <li>Potential as a hay or pasture crop</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good forage quality possible</li>

      <li>adapted to a wide range in soil moisture conditions</li>

      <li>no bloat hazard</li>

      <li>very persistent, once established</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>slow to establish, slow regrowth</li>

      <li>not adapted to acid or alkaline soils</li>

      <li>may cause photosensitization in ruminants</li>

      <li>lower yielding than alfalfa</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_asci4.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://www1.agric.gov.ab.ca/$department/deptdocs.nsf/all/faq11270/$file/cicer_milkvetch.pdf?OpenElement" target="_blank" rel="noreferrer">Ag-Canada Fact Sheet</a></li>

    </ul></>,
  'Creeping Foxtail': <>
    <h1>Creeping Foxtail (<em>Alopecurus arundinaceus</em> Poir.)</h1>
    <p>New/Untested species, not yet recommended for some or all applications</p>

    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeding rate: 10 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer</li>

      <li>Only about 20% of spring shoots are reproductive</li>

      <li>Used mainly in conservation seedings</li>

      <li>Potential pasture crop, not for stored feed</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>very early spring growth</li>

      <li>quick recovery after spring harvest</li>

      <li>good tolerance to acid and poorly-drained soils</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>relatively low yield potential</li>

      <li>low palatability</li>

      <li>fluffy seed difficult to sow</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://forages.oregonstate.edu/php/fact_sheet_print_grass.php?SpecID=4&amp;use=" target="_blank" rel="noreferrer">Forage Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_alar.pdf">USDA Plant Guide</a></li>

    </ul></>,
  'Creeping Red Fescue': <>
    <h1>Creeping Red Fescue (<em>Festuca rubra</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 227,000</li>

      <li>Seeding rate: 8-12 lbs pure live seed/acre (25-30 lbs/acre for conservation purposes)</li>

      <li>Seeding date: early spring, late summer</li>

      <li>Forms a dense sod</li>

      <li>Streambank or grass waterway protection, soil improvement, areas with heavy animal traffic</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>easy to establish</li>

      <li>good tolerance to acid and poorly-drained soils</li>

      <li>good tolerance to close grazing</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>low yield potential</li>

      <li>low palatability</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_ferua3.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

    </ul></>,
  'Crownvetch': <>
    <h1>Crownvetch (<em>Coronilla varia</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 110,000</li>

      <li>Seeding rate: 10-15 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer or fall</li>

      <li>Ground cover for steep roadbanks, surface mine spoil, and industrial sites where low maintenance is important</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>long-lived perennial if not harvested</li>

      <li>well adapted to shallow, low-fertility, acid soils</li>

      <li>excellent drought tolerance</li>

      <li>no bloat hazard</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>low seedling vigor, slow to establish</li>

      <li>poor shade tolerance</li>

      <li>not adapted to wet soils</li>

      <li>very poor persistence when harvested</li>

      <li>low palatability due to nitro-compounds</li>

      <li>lower yielding than alfalfa</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://forages.oregonstate.edu/php/fact_sheet_print_legume.php?SpecID=41&amp;use=Forage" target="_blank" rel="noreferrer">Forage Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_cova2.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

    </ul></>,
  'Crownvetch-Orchardgrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 30:70</li>

      <li>Seeding rate (lbs/acre): 10/3</li>

      <li>Deep to shallow, well-drained soils, intensive pasture</li>

    </ul></>,
  'Crownvetch-Tall Fescue': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 30:70</li>

      <li>Seeding rate (lbs/acre): 10/6</li>

      <li>Deep to shallow, well-drained soils, intensive pasture</li>

    </ul></>,
  'Crownvetch-Timothy': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 40:60</li>

      <li>Seeding rate (lbs/acre): 10/4</li>

      <li>Deep to shallow, well-drained soils, intensive pasture</li>

    </ul></>,
  'Deborah Brome': <>
    <h1>Deborah Brome (<em>Bromus carinatus</em> Hook. and Arn.) (California Brome)</h1>
    <p>New/Untested species, not yet recommended for some or all applications</p>

    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 45,000</li>

      <li>Seeding rate: 35-40 lbs pure live seed/acre for pastures (15-25 lbs/acre for conservation seedlings)</li>

      <li>Seeding date: early spring, late summer before August 15</li>

      <li>Large seed size</li>

      <li>Primarily used for conservation seedings, reseeding after fires, wildlife cover</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>rapid establishment</li>

      <li>good tolerance to droughty soils</li>

      <li>good midsummer growth</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>lower yielding than other tall grasses</li>

      <li>not adapted to acid or poorly-drained soils</li>

      <li>may not persist in NYS</li>

    </ul></>,
  'Deer Tongue': <>
    <h1>Deer Tongue (<em>Panicum clandestimun</em> L. = <em>P. latifolium</em> L. var. <em>clandestimum</em> (l.) Pursh)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 1 harvest/season</li>

      <li>Seeding date: early spring, late summer</li>

      <li>Warm season bunch grass</li>

      <li>Revegetating acid mine spoil, ground cover for erodible sandy sites, food for wildlife</li>

      <li>Produces complete cover in 2 years</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good tolerance to acid, low fertility soils</li>

      <li>excellent drought tolerance</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>very low palatability, not a source of ruminant forage</li>

      <li>poor shade tolerance</li>

      <li>not tolerant of poorly-drained soils</li>

    </ul>

    <h2>More Information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_dicl.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/java/profile?symbol=DICL" target="_blank" rel="noreferrer">USDA Plant Profile</a></li>

    </ul></>,
  'Eastern Gamagrass': <>
    <h1>Eastern Gamagrass (<em>Tripsacum dactyloides</em> (L.) L.)</h1>
    <p>New/Untested species, not yet recommended for some or all applications</p>

    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeding date: early spring</li>

      <li>Tall, warm-season perennial prairie grass related to corn</li>

      <li>Primarily used as a hay crop</li>

      <li>Thick rhizomes, with a clumpy growth habit</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>very high forage quality</li>

      <li>high yield potential</li>

      <li>good tolerance of acid, droughty, and poorly-drained soils</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>management practices for NE USA not yet clear</li>

      <li>difficult to establish</li>

      <li>not adapted to continuous grazing</li>

    </ul>

    <h2>More Information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_trda3.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/java/profile?symbol=TRDA3" target="_blank" rel="noreferrer">USDA Plant Profile</a></li>

    </ul></>,
  'Festulolium hybrids': <>
    <h1>Festulolium hybrids (<em>xFestulolium braunii</em> (K. Richter) A. Camus) (hybrids between Italian ryegrass and meadow fescue)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 227,000</li>

      <li>Seeding rate: 15-20 lbs pure live seed/acre</li>

      <li>Seeding date: early spring</li>

      <li>Slightly more winter hardy than Italian ryegrass</li>

      <li>Must be used in combination with legumes</li>

      <li>For short-term rotations only</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>very good quality possible</li>

      <li>easy to establish, rapid growth rate</li>

      <li>good growth through the season</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>most likely not a perennial crop in NYS, will not persist</li>

      <li>not well suited to droughty or poorly-drained soils</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://www.uwex.edu/ces/forage/pubs/ryegrass.htm" target="_blank" rel="noreferrer">Ryegrass Hybrids</a></li>

    </ul></>,
  'Flatpea': <>
    <h1>Flatpea (<em>Lathyrus sylvestris</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 1 harvest/season</li>

      <li>Seeding rate: 30-35 lbs pure live seed/acre</li>

      <li>Seeding date: spring, late summer, or early fall</li>

      <li>Seed inoculation is essential</li>

      <li>For erosion control on roadbanks, logging roads, dams, gravel pits, surface mine spoil, and industrial waste areas</li>

      <li>Should be sown with fast-growing grass such as tall fescue</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>adapted to a wide range of shallow, droughty acid soils</li>

      <li>good shade tolerance</li>

      <li>deep-rooted and persistent, once established</li>

      <li>restricts the growth of woody plants</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>slow to germinate and grow the first year</li>

      <li>not adapted to wet soils</li>

    </ul>

    <h2>More Information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_lasy.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

    </ul></>,
  'Indiangrass': <>
    <h1>Indiangrass (<em>Sorghastrum nutans</em> (L.) Nash)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 175,000</li>

      <li>Seeding rate: 8-10 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer before August 15</li>

      <li>Warm-season grass with most of growth in mid-summer</li>

      <li>Unacceptable forage quality for ruminants in NYS</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good yields possible</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>unacceptably low forage quality</li>

      <li>loses forage quality rapidly</li>

      <li>fluffy seed difficult to sow</li>

      <li>difficult to establish</li>

      <li>slow to establish</li>

      <li>intolerant of poorly-drained soils</li>

      <li>intolerant of continuous grazing</li>

      <li>must be grown in separate pastures</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_sonu2.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://www.ernstseed.com/biomass/featured-biomass-species/indiangrass/" target="_blank" rel="noreferrer">Indiangrass (Ernst)</a></li>

    </ul></>,
  'Italian Ryegrass': <>
    <h1>Italian Ryegrass (<em>Lolium multiflorum</em> Lam.) (annual ryegrass)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 227,000</li>

      <li>Seeding rate: 15-20 lbs pure live seed/acre</li>

      <li>Seeding date: early spring</li>

      <li>May survive winter in mixture with alfalfa if consistent snow cover</li>

      <li>Green manure or short-term cover crop</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>very good quality possible</li>

      <li>easy to establish, rapid growth rate</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>not a perennial crop in NYS, will not persist</li>

      <li>poor drought and heat tolerance</li>

      <li>not well suited to poorly-drained soils</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/java/profile?symbol=LOPEM2" target="_blank" rel="noreferrer">USDA Plant Profile</a></li>

      <li><a href="http://cropsoil.psu.edu/extension/facts/agfact19.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

      <li><a href="http://forages.oregonstate.edu/php/fact_sheet_print_grass.php?SpecID=2&amp;use=Forage" target="_blank" rel="noreferrer">Forage Fact Sheet (OR)</a></li>

      <li><a href="http://www.plant-identification.co.uk/skye/gramineae/lolium-multiflorum.htm" target="_blank" rel="noreferrer">Italian ryegrass seed head</a></li>

      <li><a href="http://www.noble.org/Ag/Forage/AnnualRyegrass/index.html" target="_blank" rel="noreferrer">Annual ryegrass (Noble)</a></li>

    </ul></>,
  'Kentucky Bluegrass': <>
    <h1>Kentucky Bluegrass (<em>Poa pratensis</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 2,177,000</li>

      <li>Seeding rate: 10-15 lbs pure live seed/acre</li>

      <li>Seeding date: frost seed, early spring, late summer before August 15</li>

      <li>Sod forming grass, shallow root system</li>

      <li>Survives in infertile, overgrazed pastures</li>

      <li>Good for grazing only, especially horse pastures</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>good tolerance to close grazing</li>

      <li>withstands animal traffic</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>very low yield potential</li>

      <li>poor drought and heat tolerance, dormant in summer</li>

      <li>not suited to poorly-drained soils</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_popr.pdf" target="_blank" rel="noreferrer">USDA Plant Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_popr.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://cropsoil.psu.edu/extension/facts/agfact50.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

    </ul></>,
  'Kentucky Bluegrass-Smooth Bromegrass-Timothy': <>
    <ul>
      <li>Yield based on 2 harvests/season and species ratio of 20:50:30</li>

      <li>Seeding rate (lbs/acre): 8/4/4</li>

      <li>Deep to shallow, well-drained soils to moderately well-drained soils, horse pasture</li>

    </ul></>,
  'Kura Clover': <>
    <h1>Kura Clover (<em>Trifolium ambiguum</em> Bieb.) (caucasian, pellett, or honey clover)</h1>
    <p>New/Untested species, not yet recommended for some or all applications</p>

    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 800,000</li>

      <li>Seeding rate: 4-6 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, or late summer before August 15</li>

      <li>Valuable honey-producing crop</li>

      <li>Seed inoculation is essential</li>

      <li>Good potential as a pasture legume</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>well adapted to acid, variably drained, low fertility soils</li>

      <li>very persistent, once established</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>seed not readily available</li>

      <li>poor seedling vigor, slow to establish</li>

      <li>lower yielding than alfalfa</li>

      <li>can cause bloat</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://www.hort.purdue.edu/newcrop/CropFactSheets/KuraClover.html" target="_blank" rel="noreferrer">Fact Sheet (Purdue)</a></li>

      <li><a href="http://www.uwex.edu/ces/forage/pubs/Kura_stands.htm" target="_blank" rel="noreferrer">Establishment (WI)</a></li>

      <li><a href="http://www.uwex.edu/ces/crops/uwforage/KuraAlbrecht.pdf" target="_blank" rel="noreferrer">Management (WI)</a></li>

      <li><a href="http://www.omafra.gov.on.ca/english/livestock/dairy/facts/98-049.htm" target="_blank" rel="noreferrer">Fact Sheet (Ontario)</a></li>

    </ul></>,
  'Ladino White Clover-Alsike Clover-Timothy-Reed Canarygrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 20:10:20:50</li>

      <li>Seeding rate (lbs/acre): 1/2/4/8</li>

      <li>Moderately well-drained to poorly-drained soils, intensive pasture</li>

      <li>Not for horse pasture</li>

    </ul></>,
  'Ladino White Clover-Kentucky Bluegrass-Timothy': <>
    <ul>
      <li>Yield based on 2 harvests/season and species ratio of 20:30:50</li>

      <li>Seeding rate (lbs/acre): 1/6/4</li>

      <li>Deep, well-drained to moderately well-drained soils, horse pasture</li>

    </ul></>,
  'Ladino White Clover-Orchardgrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 30:70</li>

      <li>Seeding rate (lbs/acre): 2/5</li>

      <li>Deep to moderately droughty, well-drained soils, intensive pasture</li>

    </ul></>,
  'Ladino White Clover-Red Clover-Alsike Clover-Orchardgrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 10:30:10:50</li>

      <li>Seeding rate (lbs/acre): 1/2/1/4</li>

      <li>Deep, well-drained soils, intensive pasture</li>

      <li>Not for horse pasture</li>

    </ul></>,
  'Ladino White Clover-Red Clover-Alsike Clover-Timothy': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 20:30:10:40</li>

      <li>Seeding rate (lbs/acre): 1/2/1/4</li>

      <li>Deep, well-drained soils, intensive pasture</li>

      <li>Not for horse pasture</li>

    </ul></>,
  'Ladino White Clover-Red Clover-Kentucky Bluegrass-Perennial Ryegrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 10:30:20:40</li>

      <li>Seeding rate (lbs/acre): 1/2/4/5</li>

      <li>Deep, well-drained to moderately well-drained soils, intensive pasture</li>

    </ul></>,
  'Ladino White Clover-Red Clover-Kentucky Bluegrass-Timothy': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 20:30:10:40</li>

      <li>Seeding rate (lbs/acre): 1/2/4/2</li>

      <li>Deep, well-drained to moderately well-drained soils, intensive pasture</li>

    </ul></>,
  'Ladino White Clover-Red Clover-Reed Canarygrass-Smooth Bromegrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 10:30:40:20</li>

      <li>Seeding rate (lbs/acre): 1/2/6/6</li>

      <li>Variably-drained soils, intensive pasture</li>

    </ul></>,
  'Ladino White Clover-Red Clover-Tall Fescue': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 20:30:50</li>

      <li>Seeding rate (lbs/acre): 1/2/6</li>

      <li>Deep, well-drained to poorly-drained soils, intensive pasture</li>

    </ul></>,
  'Ladino White Clover-Red Clover-Timothy-Perennial Ryegrass': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 10:30:20:40</li>

      <li>Seeding rate (lbs/acre): 1/2/2/5</li>

      <li>Deep, well-drained to moderately well-drained soils, intensive pasture</li>

    </ul></>,
  'Ladino White Clover-Smooth Bromegrass-Timothy': <>
    <ul>
      <li>Yield based on 2 harvests/season and species ratio of 20:50:30</li>

      <li>Seeding rate (lbs/acre): 1/8/4</li>

      <li>Deep, well-drained to moderately well-drained soils, intensive pasture</li>

    </ul></>,
  'Ladino White Clover-Tall Fescue-Timothy': <>
    <ul>
      <li>Yield based on 2 harvests/season and species ratio of 20:50:30</li>

      <li>Seeding rate (lbs/acre): 1/8/4</li>

      <li>Deep, well-drained to moderately well-drained soils, horse pasture</li>

    </ul></>,
  'Ladino White Clover-Timothy': <>
    <ul>
      <li>Yield based on 3 harvests/season and species ratio of 40:60</li>

      <li>Seeding rate (lbs/acre): 2/6</li>

      <li>Deep to shallow, well-drained soils, intensive pasture</li>
    </ul></>,
  'Prairie Grass': <>
    <h1>Prairiegrass (<em>Bromus willdenovii</em> Knuth = <em>B. cartharticus</em> Vahl) (Variety = Matua)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeding rate: 25 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer before August 15</li>

      <li>Type of bromegrass without rhizomes</li>

      <li>Best management practices not well defined for Matua</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good yield potential, very palatable forage</li>

      <li>tolerates heat, and droughty soils</li>

      <li>very good growth in early spring and autumn</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>does not persist in NYS</li>

      <li>intolerant of poorly-drained soils</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/java/profile?symbol=BRCA6" target="_blank" rel="noreferrer">USDA Plant Profile</a></li>

      <li><a href="http://www.ars-grin.gov/cgi-bin/npgs/html/taxon.pl?7785" target="_blank" rel="noreferrer">USDA Taxonomy (multiple common names)</a></li>

      <li><a href="http://www.forages.psu.edu/topics/species_variety_trials/species/prairie/char_adapt.html" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

    </ul></>,
  'Meadow Bromegrass': <>
    <h1>Meadow bromegrass (<em>Bromus riparius</em> Rehm.)</h1>
    <p>New/Untested species, not yet recommended for some or all applications</p>

    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvest/season</li>

      <li>Seeding rate: 10-15 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer before August 15</li>

      <li>Persistent cool-season bunch grass, primary variety is <i>Regar</i></li>

      <li>Primarily used for pasture in western USA</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good yield potential</li>

      <li>good regrowth</li>

      <li>tolerant of close grazing</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>management not well defined</li>

      <li>forage quality not known</li>

      <li>not tolerant of acid and poorly-drained soils</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/java/profile?symbol=BRRI7" target="_blank" rel="noreferrer">USDA Plant Profile</a></li>

      <li><a href="http://www.plant-materials.nrcs.usda.gov/pubs/idpmspg04832.pdf" target="_blank" rel="noreferrer">NRCS Plant Guide</a></li>

    </ul></>,
  'Meadow Fescue': <>
    <h1>Meadow Fescue (<em>Festuca pratensis</em> Huds.)</h1>
    <p>New/Untested species, not yet recommended for some or all applications</p>

    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 227,000</li>

      <li>Seeding rate: 8-12 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer before August 15</li>

      <li>Usually sown in mixtures for pasture</li>

      <li>Can be used as a hay crop</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>easy to establish</li>

      <li>good tolerance to acid and poorly-drained soils</li>

      <li>good tolerance to close grazing</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>lower yielding than tall fescue</li>

      <li>high susceptibility to disease</li>

      <li>low palatability</li>

      <li>contains an endophytic fungus</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://www.hort.purdue.edu/newcrop/duke_energy/Festuca_pratensis.html" target="_blank" rel="noreferrer">Fact Sheet (Purdue)</a></li>

    </ul></>,
  'Meadow Foxtail': <>
    <h1>Meadow Foxtail (<em>Alopecurus pratensis</em> L.)</h1>
    <p>New/Untested species, not yet recommended for some or all applications</p>

    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeding rate: 8-12 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer before August 15</li>

      <li>Usually sown in mixtures for pasture</li>

      <li>Can be used as a hay crop</li>

      <li>Very early spring growth</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>easy to establish</li>

      <li>good tolerance to poorly-drained soils</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>fluffy seed difficult to sow, should be coated</li>

      <li>lower yielding than other tall grasses</li>

      <li>low midsummer yield</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/java/profile?symbol=ALPR3" target="_blank" rel="noreferrer">USDA Plant Profile</a></li>

      <li><a href="http://www.plant-identification.co.uk/skye/gramineae/alopecurus-pratensis.htm" target="_blank" rel="noreferrer">Meadow foxtail seed head</a></li>

    </ul></>,
  'Orchardgrass': <>
    <h1>Orchardgrass (<em>Dactylis glomerata</em> L.) (cocksfoot)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 654,000</li>

      <li>Seeding rate: 8-10 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, or late summer before August 15</li>

      <li>Bunch-type grass, very competitive with legumes</li>

      <li>Good for stored feed or intensive pasture</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>easy to establish</li>

      <li>good regrowth</li>

      <li>adapted to droughty soils</li>

      <li>good tolerance to close grazing, with early spring growth</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>poor tolerance to variable drainage and icing</li>

      <li>older varieties may mature too early in spring to permit high quality harvest</li>

      <li>rapidly loses quality with maturity</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_dagl.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_dagl.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://pubs.cas.psu.edu/FreePubs/pdfs/uc088.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

      <li><a href="http://forages.oregonstate.edu/php/fact_sheet_print_grass.php?SpecID=5&amp;use=" target="_blank" rel="noreferrer">Forage Fact Sheet (OR)</a></li>

    </ul></>,
  'Perennial Ryegrass': <>
    <h1>Perennial Ryegrass (<em>Lolium perenne</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 227,000</li>

      <li>Seeding rate: 15-20 lbs pure live seed/acre</li>

      <li>Better suited to pasture</li>

      <li>Must be used in combination with other species</li>

      <li>Good for short-term stabilizing cover and for soil improvement</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>very good quality possible</li>

      <li>easy to establish, rapid growth rate</li>

      <li>good tolerance to close grazing</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>no varieties have consistently persisted in NYS to-date</li>

      <li>poor drought and heat tolerance</li>

      <li>not well suited to poorly-drained soils</li>

      <li>poor shade tolerance</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://cropsoil.psu.edu/extension/facts/agfact19.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_lopep.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://www.uwex.edu/ces/forage/pubs/ryegrass.htm" target="_blank" rel="noreferrer">Production Guide (WI)</a></li>

    </ul></>,
  'Red Clover': <>
    <h1>Red Clover (<em>Trifolium pratense</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 275,000</li>

      <li>Seeding rate: 6-8 lbs pure live seed/acre</li>

      <li>Seeding date: frost seed, early spring, or late summer before August 15</li>

      <li>Two to three harvests per season possible</li>

      <li>Use in pasture mixtures, with rotational grazing</li>

      <li>Persistent if allowed to reseed</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>well adapted to acid or variable drained soils</li>

      <li>easy and fast to establish</li>

      <li>good first year yields</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>generally does not persist after 2 growing seasons</li>

      <li>lower yielding than alfalfa</li>

      <li>can cause bloat</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://cropsoil.psu.edu/extension/facts/agfact21.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_trpr2.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_trpr2.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://www.covercrops.msu.edu/species/red.html" target="_blank" rel="noreferrer">Cover Crop in MI</a></li>

    </ul></>,
  'Red Clover-Alsike Clover-Timothy': <>
    <ul>
      <li>Yield based on 2 harvests/season and species ratio of 40:10:50</li>

      <li>Seeding rate (lbs/acre): 4/2/4</li>

      <li>Poorly-drained soils, stored feed, not for horse pasture</li>

    </ul></>,
  'Red Clover-Tall Fescue': <>
    <ul>
      <li>Yield based on 2 harvests/season and species ratio of 40:60</li>

      <li>Seeding rate (lbs/acre): 6/12</li>

      <li>Deep to moderately droughty, well-drained soils</li>

      <li>Stored feed or intensive pasture</li>

    </ul></>,
  'Red Clover-Timothy': <>
    <ul>
      <li>Yield based on 2 harvests/season and species ratio of 50:50</li>

      <li>Seeding rate (lbs/acre): 6/4</li>

      <li>Well-drained to variably-drained soils, tolerates lower pH</li>

      <li>Stored feed or intensive pasture, short-term stands</li>

    </ul></>,
  'Redtop': <>
    <h1>Redtop (<em>Agrostis gigantea</em> Roth. = <em>A. alba</em> L., auct. Am.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 4,990,000</li>

      <li>Seeding rate: 3-6 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer</li>

      <li>Naturalized grass used for pasture in the past</li>

      <li>Quick cover for grass waterways, diversions and roadbanks</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>easy to establish, fast germination</li>

      <li>good tolerance to acid and poorly-drained soils</li>

      <li>higher yields than most grasses that volunteer on poor soils</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>low yield potential</li>

      <li>low forage quality</li>

      <li>not adapted to heavy soils that are droughty in summer</li>

      <li>not competitive on fertile soils</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_aggi2.pdf" target="_blank" rel="noreferrer">USDA Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_aggi2.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

    </ul></>,
  'Reed Canarygrass': <>
    <h1>Reed Canarygrass (<em>Phalaris arundinacea</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 533,000</li>

      <li>Seeding rate: 8-12 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, or late summer before August 1</li>

      <li>Sod-forming grass with thick, short rhizomes</li>

      <li>Must use new low-alkaloid varieties (Palaton, Venture, Rival, Beilvue, etc.)</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>good regrowth</li>

      <li>adapted to wide range of soils, from droughty to very poorly-drained</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>slow to establish, very weak seedling</li>

      <li>needs 6 weeds of seedling growth in fall to overwinter</li>

      <li>rapidly loses quality with maturity</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://pubs.cas.psu.edu/FreePubs/pdfs/uc089.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_phar3.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_phar3.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://www.extension.umn.edu/distribution/livestocksystems/DI5533.html" target="_blank" rel="noreferrer">Forage Fact Sheet (MN)</a></li>

    </ul></>,
  'Sainfoin': <>
    <h1>Sainfoin (<em>Onobrychis viciifolia</em> Scop.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 30,000</li>

      <li>Seeding rate: 20 lbs pure live seed/acre</li>

      <li>One or two harvests per season</li>

      <li>Seed inoculation is essential</li>

      <li>Potential as a hay or pasture crop</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>good drought tolerance</li>

      <li>excellent seedling vigor</li>

      <li>no bloat hazard</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>intolerant of frequent defoliation</li>

      <li>intolerant of wet or acid soils</li>

      <li>low forage yield potential</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_onvi.pdf" target="_blank" rel="noreferrer">USDA Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_onvi.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://www.msuextension.org/park/Images/Ag/pdfs/sanfoin.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (MT)</a></li>

    </ul></>,
  'Smooth Bromegrass': <>
    <h1>Smooth Bromegrass (<em>Bromus inermis</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 136,000</li>

      <li>Seeding rate: 12-14 lbs pure live seed/acre</li>

      <li>Seeding date: frost seed, early spring, or late summer before August 15</li>

      <li>Forms dense sod</li>

      <li>Good for stored feed, can be used for intensive pasture</li>

      <li>Hay is preferred by horse owners</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>good spring and fall production</li>

      <li>adapted to droughty soils</li>

      <li>may retain quality with maturity better than other species</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>large, fluffy seed can make seeding difficult</li>

      <li>poor persistence under 3-4 cut system</li>

      <li>not suitable in mixture with alfalfa</li>

      <li>not adapted to poorly-drained soils</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://pubs.cas.psu.edu/FreePubs/pdfs/uc090.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

      <li><a href="http://extension.umd.edu/publications/PDFs/FS813.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (MD)</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_brin2.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://forages.oregonstate.edu/php/fact_sheet_print_grass.php?SpecID=23&amp;use=Soil" target="_blank" rel="noreferrer">Forage Fact Sheet (OR)</a></li>

    </ul></>,
  'Sweetclover': <>
    <h1>Sweetclover (<em>Melilotus officinalis</em> Lam. (yellow sweetclover), <em>Melilotus alba</em> Medik. (white sweetclover))</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 260,000</li>

      <li>Seeding rate: 8-10 lbs pure live seed/acre</li>

      <li>Seeding date: early spring</li>

      <li>Seed inoculation is essential</li>

      <li>Biennial, with only vegetative growth in the seeding year</li>

      <li>Primarily used as a soil-improving, plowdown crop</li>

      <li>Can be used for pasture, hay or silage</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>adapted to a wide range of soil and climatic conditions</li>

      <li>deep-rooted and winter-hardy (the winter following seeding)</li>

      <li>opens up subsoil</li>

      <li>excellent for bees</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>low palatability, contains coumarin</li>

      <li>not adapted to acid soils</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_meof.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://www.merckvetmanual.com/mvm/index.jsp?cfile=htm/bc/213800.htm" target="_blank" rel="noreferrer">Sweet clover poisoning (Merck)</a></li>

      <li><a href="http://www.il.nrcs.usda.gov/technical/grazing/bienlgm.html" target="_blank" rel="noreferrer">Grazing Sweet clover (IL)</a></li>

    </ul></>,
  'Switchgrass': <>
    <h1>Switchgrass (<em>Panicum virgatum</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 389,000</li>

      <li>Seeding rate: 5-8 lbs pure live seed/acre</li>

      <li>Seeding date: early spring, late summer before August 15</li>

      <li>Warm-season grass with most of growth in mid-summer</li>

      <li>Unacceptable forage quality for ruminants in NYS</li>

      <li>Good potential as biomass crop for energy or wood pulp substitute</li>

      <li>Useful for soil protection purposes and wildlife cover</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>better suited to poorly-drained soils than big bluestem</li>

      <li>tolerates low pH and fertility</li>

      <li>high yield potential</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>unacceptably low forage quality</li>

      <li>loses forage quality rapidly</li>

      <li>slow to establish</li>

      <li>must be grown in separate pastures</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_pavi2.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://www.ernstseed.com/biomass/featured-biomass-species/switchgrass/" target="_blank" rel="noreferrer">Switchgrass (Ernst)</a></li>

      <li><a href="http://bioenergy.msu.edu/feedstocks/switchgrass.shtml" target="_blank" rel="noreferrer">Switchgrass for bioenergy (MI)</a></li>

      <li><a href="http://extension.psu.edu/energy/field-crops/additional-info/switchgrass" target="_blank" rel="noreferrer">Switchgrass information (PA)</a></li>

    </ul></>,
  'Tall Fescue': <>
    <h1>Tall Fescue (<em>Lolium arundinaceum</em> (Schreb.) S.J. Darbyshire)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 227,000</li>

      <li>Seeding rate: 10-14 lbs pure live seed/acre (18-20 lbs/acre for conservation purposes)</li>

      <li>Seeding date: early spring, or late summer before August 15</li>

      <li>Deep rooted bunch grass, very winter hardy</li>

      <li>Good for stored feed and intensive pasture, need endophyte-free variety</li>

      <li>Used for stabilizing grass waterways, slopes and roadbanks, also food and cover for wildlife</li>

      <li>Good for areas of heavy livestock or machinery traffic</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>easy to establish</li>

      <li>high yield, with good summer production</li>

      <li>good stockpiled forage for late season grazing</li>

      <li>adapted to acid and somewhat poorly-drained soils</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>not adequately tested as lactating dairy cow forage</li>

      <li>not well suited to droughty soils</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://cropsoil.psu.edu/extension/facts/agronomy-facts-28" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_loar10.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_loar10.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

      <li><a href="http://www.ca.uky.edu/agc/pubs/agr/agr59/agr59.htm" target="_blank" rel="noreferrer">Forage Fact Sheet (KY)</a></li>

    </ul></>,
  'Timothy': <>
    <h1>Timothy (<em>Phleum pratense</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 3 harvests/season</li>

      <li>Seeds/pound: 1,230,000</li>

      <li>Seeding rate: 4-8 lbs pure live seed/acre</li>

      <li>Seeding date: frost seed, early spring, or late summer before August 15</li>

      <li>Non-competitive, bunch-type grass</li>

      <li>Better for stored feed than pasture</li>

      <li>Use in combination with other species for pasture</li>

      <li>Hay of choice for horse owners</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>easy to establish</li>

      <li>adapted to somewhat poorly-drained soils</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>poor regrowth, with poor summer production</li>

      <li>not suited to droughty soils</li>

      <li>rapidly losses quality with maturity</li>

      <li>poor persistence of late maturing varieties under 3-4 cut system</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://cropsoil.psu.edu/extension/facts/agronomy-facts-24" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_phpr3.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://www.hort.purdue.edu/newcrop/duke_energy/Phleum_pratense.html" target="_blank" rel="noreferrer">Forage Fact Sheet (Purdue)</a></li>

    </ul></>,
  'Ladino White Clover': <>
    <h1>Ladino White Clover (<em>Trifolium repens</em> L.) (Dutch White, Ladino)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 800,000 (White Dutch), 860,000 (Ladino)</li>

      <li>Seeding date: frost seed, early spring, or late summer before August 15</li>

      <li>Ladino is a large form of white clover</li>

      <li>Useful for pasture only, withstands continuous grazing</li>

      <li>Component of wildlife mixtures as feed for deer</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>excellent quality possible</li>

      <li>easy to establish</li>

      <li>good tolerance to wide pH range</li>

      <li>good tolerance to close grazing</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>generally does not persist after 2 growing seasons</li>

      <li>very low yielding</li>

      <li>low drought tolerance, winter hardiness</li>

      <li>can cause bloat</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://cropsoil.psu.edu/extension/facts/agfact22.pdf" target="_blank" rel="noreferrer">Forage Fact Sheet (PA)</a></li>

      <li><a href="http://plants.usda.gov/factsheet/pdf/fs_trre3.pdf" target="_blank" rel="noreferrer">USDA Plant Fact Sheet</a></li>

      <li><a href="http://plants.usda.gov/plantguide/pdf/pg_trre3.pdf" target="_blank" rel="noreferrer">USDA Plant Guide</a></li>

    </ul></>,
  'Ladino White Clover-Kentucky Bluegrass': <>
    <ul>
      <li>Yield based on 2 harvests/season and species ratio of 40:60</li>

      <li>Seeding rate (lbs/acre): 2/6</li>

      <li>Deep to moderately droughty, well-drained soils, intensive pasture</li>

    </ul></>,
  'Forage Chicory': <>
    <h1>Forage Chicory (<em>Cichorium intybus</em> L.)</h1>
    <h2>Characteristics</h2>

    <ul>

      <li>Yield based on 2 harvests/season</li>

      <li>Seeds/pound: 227,000</li>

      <li>Seeding rate: 3-4 lbs pure live seed/acre</li>

      <li>Seeding date: early spring</li>

      <li>Perennial herb, usually sown in mixture with legumes</li>

      <li>For grazing only, rapid stem growth must be prevented</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>good quality possible</li>

      <li>very good tolerance to droughty soils</li>

      <li>can be no-till seeded into existing pastures</li>

      <li>provides grazing during spring and summer periods</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>not well suited to poorly-drained soils</li>

      <li>very poor quality and growth if not managed intensively</li>

      <li>weeds may be difficult to control, no registered herbicides</li>

      <li>pure chicory stands will produce milk with metallic taste</li>

    </ul>

    <h2>More information</h2>

    <ul>

      <li><a href="http://pubs.cas.psu.edu/FreePubs/pdfs/uc116.pdf" target="_blank" rel="noreferrer">Forage Chicory (PA)</a></li>

      <li><a href="http://www.aces.edu/dept/forages/forchic.html" target="_blank" rel="noreferrer">Forage Chicory (AL)</a></li>

      <li><a href="http://www.ca.uky.edu/agc/pubs/agr/agr190/agr190.pdf" target="_blank" rel="noreferrer">Forage Chicory (KY)</a></li>

    </ul></>,
  'Coastal Panic Grass': <>
    <h1>Coastal Panic Grass (<em>Panicum amarum</em> Elliott)</h1>
    <p>New/untested species, not yet recommended for some or all applications.</p>

    <h2>Characteristics</h2>

    <ul>

      <li>Seeds/pound: 325,000</li>

      <li>Seeding rate: 10 lbs pure live seed/acre</li>

      <li>Seeding date: early spring</li>

      <li>Prefers soil pH of 5 to 7.6.</li>

      <li>Warm-season bunch grass with deep root system</li>

      <li>Relative of switchgrass</li>

    </ul>

    <h2>Advantages</h2>

    <ul>

      <li>Very high yield potential</li>

      <li>Good for soil conservation/wildlife uses</li>

      <li>Very high potential for biomass uses</li>

      <li>High drought tolerance</li>

    </ul>

    <h2>Concerns</h2>

    <ul>

      <li>unacceptably low forage quality</li>

      <li>prefers sandy or well-drained soils</li>

      <li>intolerant of poorly-drained soils</li>

      <li>slow to establish</li>

    </ul>

    <h2>More Information</h2>

    <ul>

      <li><a href="http://www.ernstseed.com/biomass/featured-biomass-species/coastal-panic-grass/" target="_blank" rel="noreferrer">Coastal Panic Grass (Ernst)</a></li>

    </ul></>
};

export default speciesHTML;