import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {v4 as uuidv4} from 'uuid';

import resources from '../StaticData/resourceList';


const useStyles = makeStyles(() => ({
  resources: {
    backgroundColor: 'white',
    position: 'relative',
    height: 'calc(100vh - 120px)',
    minHeight: '325px',
    width: '100%',
    marginLeft: '6px',
    border: '1px solid black',
    boxSizing: 'border-box',
    borderRadius: '6px',
    padding: '5px 12px'
  },
  link: {
    padding: '2px 0px',
    margin: '4px 0px 4px 15px',
    borderLeft: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  linkText: {
    fontSize: 14,
    color: 'rgb(150,0,0)',
    textDecoration: 'none'
  },
  lists: {
    overflow: 'auto',
    height: 'calc(100vh - 167px)',
    minHeight: '278px',
    boxSizing: 'border-box',
    gap: 6,
    border: '1px solid rgba(100,100,100,0.5)',
    padding: '8px 14px',
    margin: '0 auto',
    width: 'fit-content'
  },
  title: {
    width: 'fit-content',
    margin: '0 auto',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 6,
    paddingBottom: 2,
    borderBottom: '1px solid black'
  },
  groupName: {
    marginTop: 25,
  }
}));



export default function Resources() {
  const classes = useStyles();

  const renderList = () => {
    let list = Object.keys(resources).map(group => {
      return (
        <li className={classes.groupName} key={uuidv4()}>
          <Typography variant='h6'>{group}</Typography>
          
          {
            resources[group].map(linkArr => {
              let path = linkArr.length === 2 ? process.env.PUBLIC_URL + '/Resources/' + linkArr[1] : linkArr[1];

              return (
                <li className={classes.link} key={uuidv4()}>
                  <a
                    href={path}
                    className={classes.linkText}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <div>{linkArr[0]}</div>
                  </a>
                </li>
              );
            })
          }
        </li>
      );
    });
    return (
      <div className={classes.lists}>
        <ul>{list}</ul>
      </div>
    );
  };

  return (
    <div className={classes.resources}>
      <h1 className={classes.title}>Resources</h1>

      {renderList()}
    </div>
  );
}