const resources = {
  'Canada': [
    ['Annual Forage Production', 'https://www.saskatchewan.ca/business/agriculture-natural-resources-and-industry/agribusiness-farmers-and-ranchers/livestock/pastures-grazing-hay-silage/annual-crops-for-grazing-silage-and-greenfeed/annual-forages', true],
    ['Annual Ryegrass for Stored Feed or Pasture (PDF)', 'Annual-Ryegrass-for-Stored-Feed-and-Pasture-OMAFRA.pdf'],
    ['Annual Ryegrass for Stored Feed or Pasture (Webpage)', 'http://www.omafra.gov.on.ca/english/crops/facts/98-039.htm', true],
    ['Diseases of Grasses and Legumes', 'https://www.gov.mb.ca/agriculture/crops/crop-management/forages/disease-and-deficiencies-in-forages.html', true],
    ['Forage Pearl Millet', 'Cover-Crops_-Pearl-Millet-OMAFRA.pdf'],
    ['Forage Seed Mixture Calculator', 'https://www.agric.gov.ab.ca/app19/calc/forageseed/forageseedintro.jsp', true],
    ['Forage Sorghum-Sudan Grass', 'Forage-Sorghum-Sudan-Grass-OMAFRA.pdf'],
    ['Forages Ag Canada', 'https://ovc.uoguelph.ca/ruminant_health_management/sites/default/files/files/Forage%20-%20Agriculture%20and%20Agri-Food%20Canada%20(AAFC).pdf', true],
    ['Forages and Pastures', 'http://www.omafra.gov.on.ca/english/crops/field/forages.html', true],
    ['Frost Seeding - A Cheaper Alternative', 'Frost-Seeding-A-Cheaper-Alternative.pdf'],
    ['Grain, Forage and Straw Nutrient Use Calculator', 'https://www.agric.gov.ab.ca/app19/calc/crop/nutrientuse.jsp', true],
    ['Harvesting and Storing Large Bale Haylage', 'Harvesting-and-Storing-Large-Bale-Haylage-OMAFRA.pdf'],
    ['Large Bale Haylage', 'http://www.omafra.gov.on.ca/english/engineer/facts/01-073.htm', true],
    ['Maintaining Quality in Large Bale Silage (PDF)', 'Maintaining-Quality-in-Large-Bale-Silage-OMAFRA.pdf'],
    ['Maintaining Quality in Large Bale Silage (Webpage)', 'http://www.omafra.gov.on.ca/english/crops/facts/98-069.htm', true],
    ['Ontario Forage Crop Variety Performance Trials', 'http://www.omafra.gov.on.ca/english/crops/field/forages.html', true],
    ['Pasture Legume Identification', 'http://www.omafra.gov.on.ca/english/crops/facts/04-057.htm', true],
    ['Poisoning of Livestock by Plants', 'Poisoning-of-Livestock-by-Plants.pdf'],
    ['Spring Cereals and Cereal-Pea Mixtures', 'Spring-Cereals-and-Cereal-Pea-Mixtures-OMAFRA.pdf'],
  ],
  'Cornell University': [
    ['Brown Midrib Sorghum Sudangrass, Factsheet #14', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet14.pdf', true],
    ['Brown Midrib Sorghum Sudangrass Nitrogen management, Factsheet #26', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet26.pdf', true],
    ['Cornell Guide - Field Crops', 'https://cals.cornell.edu/field-crops', true],
    ['Dept. of Animal Science - Dairy Management', 'https://cals.cornell.edu/animal-science', true],
    ['Dept. of Plant Breeding & Genetics - Forage Testing', 'https://cals.cornell.edu/school-integrative-plant-science/school-sections/sips-plant-breeding-genetics-section', true],
    ['Lime Guidelines for Field Crops in New York', 'http://nmsp.cals.cornell.edu/publications/extension/LimeDoc2006.pdf', true],
    ['Manure and Groundwater Protection Guidelines', 'http://nmsp.cals.cornell.edu/publications/files/Groundwater.pdf', true],
    ['Manure Cost, Value and Time Management Calculator, Factsheet #53', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet53.pdf', true],
    ['Manure Use for Alfalfa-Grass Establishment, Factsheet #42', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet42.pdf', true],
    ['Manure Use for Alfalfa-Grass Production', 'http://nmsp.cals.cornell.edu/publications/files/Manureandalfalfa.pdf', true],
    ['Manure Spreading Calibrations', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet18.pdf', true],
    ['Nitrogen Credits from Manure', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet4.pdf', true],
    ['Nitrogen Guidelines for Field Crops in New York', 'http://nmsp.cals.cornell.edu/publications/extension/Ndoc2003.pdf', true],
    ['Nitrogen Management of Teff, Factsheet #46', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet46.pdf', true],
    ['Nutrient Management', 'http://nmsp.cals.cornell.edu/', true],
    ['Nutrient Management Curriculum', 'http://nmsp.cals.cornell.edu/projects/curriculum.html', true],
    ['NYS Forage Crop Guidelines', 'https://cals.cornell.edu/field-crops', true],
    ['Other regions of New York', 'https://cals.cornell.edu/cornell-cooperative-extension', true],
    ['Phosphorus Guidelines for Field Crops in New York', 'http://nmsp.cals.cornell.edu/publications/extension/Pdoc2003.pdf', true],
    ['Poisonous Plants', 'http://poisonousplants.ansci.cornell.edu/', true],
    ['Potassium Guidelines for Field Crops in New York', 'http://nmsp.cals.cornell.edu/publications/extension/Kdoc2003.pdf', true],
    ['Soil & Crop Sciences Section', 'https://cals.cornell.edu/school-integrative-plant-science/school-sections/sips-soil-crop-sciences-section', true],
    ['Soil Sampling for Field Crops', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet1.pdf', true],
    ['Supplemental Manure Spreading Guidelines to Reduce Water Contamination Risk', 'http://nmsp.cals.cornell.edu/publications/files/WinterSpreadingGuidelines.pdf', true],
    ['Teff as an Emergency Forage, Factsheet #24', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet24.pdf', true],
    ['The History and Practice of Dairy Farming in New York State', 'http://forages.org/files/Agronomy_of_Dairy_Farming.pdf', true],
    ['Winter Triticale Forage, Factsheet #56', 'http://nmsp.cals.cornell.edu/publications/factsheets/factsheet56.pdf', true],
  ],
  'Michigan State': [
    ['Ag. Practices that conserve grassland birds', 'Agricultural_Practices_that_Conserve_Grasslands_Birds_MSU.pdf'],
    ['Michigan State Forage Trials', 'https://forage.msu.edu/', true],
    ['Michigan State Grass Variety Trials', 'https://forage.msu.edu/publications/', true],
    ['Nitrate Toxicity in Drought Stressed Corn', 'Nitrate-accumulation-in-drought-stressed-corn-MSU-Extension.pdf'],
    ['No-till Establishment of Forages', 'E2880-StepsSuccessfulNotillEstablishmentForages-Leep-Undersander-etal-20031.pdf'],
    ['Prussic Acid Poisoning', 'Frosted-sorghum-and-sudangrass-MSU.pdf'],
    ['Summer Annual Forage Grasses', 'Emergency-forages-part-1-MSU.pdf'],
    ['Sulfur Fertility of Forage Crops in Michigan', 'Sulfur-Fertility-of-Forage-Crops-in-Michigan.pdf'],
    ['Timing Spring Alfalfa Harvest', 'Timing-spring-alfalfa-harvest-MSU.pdf']
  ],
  'Miscellaneous': [
    ['Illinois Forage Variety Trials', 'http://vt.cropsci.illinois.edu/forage.html', true],
    ['National Alfalfa and Forage Alliance', 'https://www.alfalfa.org/research.php', true],
    ['Optimal Length of an Alfalfa Stand Calculator', 'Optimal-length-alfalfa-stand.xls'],
  ],
  'North Dakota State University': [
    ['Feeding and Managing the Transition Dairy Cow', 'Feeding-transition-dairy-cows-NDSU.pdf'],
  ],
  'NYS Department of Environmental Conservation': [
    ['New York State Revegetation Procedures Manual', 'http://www.dec.ny.gov/lands/5401.html', true],
  ],
  'Ohio State': [
    ['Forage Crop Identification', 'https://forages.osu.edu/forage-management/forage-species-varieties/forage-id', true],
    ['Ohio Forages', 'https://forages.osu.edu/home', true],
    ['Ohio State Forage Trials', 'http://oardc.osu.edu/forage2010/', true],
    ['Purchasing Nutrients for Forage Crops', 'Purchasing-Nutrients-for-Hay-and-Forage-Crops-_-OSU.pdf'],
    ['Round Bale Silage', 'Baleage-OSU.pdf'],
  ],
  'Oregon State University': [
    ['Forage Information System', 'http://forages.oregonstate.edu/fi', true],
  ],
  'Penn State': [
    ['Forage Quality in Perspective', 'forage-quality-in-perspective.pdf'],
    ['Forage Quality Testing', 'https://extension.psu.edu/forage-quality-and-testing', true],
    ['Hay and Silage Troubleshooter', 'Troubleshooting-Silage-Problems.pdf'],
    ['Molds and Mycotoxins in Feeds', 'Mycotoxins-in-feed-PSU.pdf'],
    ['PA Forage Variety Trials', 'https://extension.psu.edu/forage-variety-trials-reports', true],
    ['Particle Size Separator Spreadsheet', 'Particle-size-separator-spreadsheet.xlsx'],
    ['Penn State Forages', 'https://extension.psu.edu/forage-and-food-crops/forages', true],
    ['Penn State Forage Trials', 'https://extension.psu.edu/forage-variety-trials-reports', true],
  ],
  'Purdue University': [
    ['Forage Identification', 'https://www.agry.purdue.edu/ext/forages/ForageID/forageid.htm', true],
    ['Forage Selection and Seeding Guide for Indiana', 'Purdue-AY-253-W.pdf'],
    ['Frost-Damaged Forages', 'FrostDamagedForages-Purdue.pdf'],
    ['Glossary of Forage Terminology', 'http://www.agry.purdue.edu/ext/forages/Forage%20Glossary-2010.pdf', true],
    ['Guide to Toxic Plants in Forages', 'http://www.extension.purdue.edu/extmedia/WS/WS_37_ToxicPlants08.pdf', true],
    ['Purdue Forage Information', 'http://www.agry.purdue.edu/ext/forages/', true],
  ],
  'Rutgers University': [
    ['Feeding Nitrate-Containing Forages (Rutgers Univ.)', 'Feeding-nitrate-containing-forages-Rutgers.pdf'],
  ],
  'Sustainable Agriculture Research and Education': [
    ['Manage Weeds on Your Farm', 'Manage-Weeds-on-Your-Farm.pdf'],
  ],
  'University of California': [
    ['Weed Identification', 'http://www.ipm.ucdavis.edu/PMG/weeds_intro.html', true],
  ],
  'University of New Hampshire': [
    ['Establishing Forage Seedings', 'http://extension.unh.edu/resources/files/Resource000482_Rep504.pdf', true],
  ],
  'University of Maryland': [
    ['Maryland Forages', 'https://www.facebook.com/MarylandForages/', true],
  ],
  'University of Minnesota': [
    ['Alfalfa Autotoxicity Risk Assessment Tool', 'Alfalfa-Autotoxicity-Calculator.xls'],
    ['Determining the Value of Rained-On Hay (Univ. of Minnesota)', 'https://extension.umn.edu/horse-nutrition/rained-hay', true],
    ['Grass Makes Milk Too (Feeding Trial) (Univ. of Minnesota)', 'https://extension.umn.edu/pasture-based-dairy/grass-fed-cows-produce-healthier-milk', true],
    ['Grass vs. Legume Forages for Dairy Cattle', 'Grass-vs-alfalfa-for-dairy-UM.pdf'],
    ['High Quality Forages Reduce Feeding Costs', 'High-quality-forage-UM.pdf'],
    ['Minnesota Forages', 'https://extension.umn.edu/forages/forage-variety-selection', true],
    ['Minnesota Variety Trial Results', 'https://extension.umn.edu/forages/forage-variety-selection', true],
  ],
  'University of Vermont': [
    ['Characteristics of Forage Grasses Grown in the Northeast', 'Forage_Grass_ID_Characteristics_ID_NE_2014.pdf'],
    ['Vermont Forages', 'https://www.uvm.edu/extension/agriculture/crop_soil_pasture', true],
  ],
  'University of Wisconsin': [
    ['A Quick Guide to Understanding Forage Test Results', 'QuickGuide-for-forage-tests-UW.pdf'],
    ['Alfalfa-Grass mixtures in dairy rations', 'Alfalfa-Grass-Mixtures-in-Dairy-Rations-UW.pdf'],
    ['Big Bale Storage Losses', 'Big-Bale-Storage-Losses.pdf'],
    ['Big Bale Storage Losses: How Different Options Stack Up', 'BigBaleStorage-losses-UW.pdf'],
    ['Bunker Silo Silage Density Calculator', 'BunkerDensityCalcDoc-4-19-08-1.pdf'],
    ['Cereal Forages for Spring Planting', 'Cereal-spring-forage-UW.pdf'],
    ['Dry Round Bale Storage Costs', 'Dry-round-bale-storage-costs-UW.pdf'],
    ['Fall Forage Rye for Dairy heifers and Dry Cows', 'Forage-rye-for-dry-cows-and-heifers-UW.pdf'],
    ['FAQs About Liming in Wisconsin', 'Facts-about-liming-in-Wis.pdf'],
    ['Feedout Losses from Forage Storage Systems', 'Feedout-losses-from-storage-systems-UW.pdf'],
    ['Focus on Forage Series', 'https://fyi.extension.wisc.edu/forage/fof/', true],
    ['Frost Seeding of Forages', 'Frost-Seeding-of-Forages-UW.pdf'],
    ['Hay Price Calculator', 'HayPriceCalculator-2014.xls'],
    ['Heat Damaged Forages: Effects of Forage Quality', 'HeatDamage-forages-quality-UW.pdf'],
    ['Heat Damaged Forages: Effects on Forage Energy Content', 'Heat-Damaged-forage-Energy--UW.pdf'],
    ['How to Price Standing Forage', 'Value-of-Standing-Forage-UW.pdf'],
    ['Identifying Pasture Grasses', 'http://learningstore.uwex.edu/assets/pdfs/A3637.pdf', true],
    ['Incorporating Grass into Silages for Dairy Cows', 'Incorporating-grass-into-rations-UW.pdf'],
    ['Making Quality Silage Bales', 'Making-quality-bag-silage-UW.pdf'],
    ['Managing Forage in Silo Bags', 'Managing-forage-in-silo-bags-UW.pdf'],
    ['Milk 2016 Spreadsheet for Alfalfa and Grass', 'Milk2016-ALFALFA-GRASS.xls'],
    ['Nitrate Poisoning in Cattle', 'NITRATE-poisoning-in-cattle-UW.pdf'],
    ['Packing Bunker and Pile Silos to Minimize Porosity', 'Bunker-silo-porosity-UW.pdf'],
    ['Pea and Small Grain Mixtures', 'Pea-small-grain-mixtures-UW.pdf'],
    ['Planting Corn for Silage after a First-Cut Alfalfa Harvest', 'Corn-after-alfalfa-UW.pdf'],
    ['Potassium in Forages', 'Potassium-in-Forages-UW.pdf'],
    ['Red Clover Harvest Management', 'Red-clover-management-UW.pdf'],
    ['Relative Forage Quality', 'Relative-forage-quality-UW.pdf'],
    ['Ryegrass Types for Pasture and Hay', 'Ryegrass-Types-for-Pasture-and-Hay.pdf'],
    ['Seeding into an Existing Alfalfa Stand', 'Seeding-into-an-existing-alfalfa-stand-UW.pdf'],
    ['Sorghums', 'Sorghums-UW.pdf'],
    ['Trends in Feed and Manure Phosphorus', 'Trends-in-Feed-and-Manure-Phosphorus-UW.pdf'],
    ['Using NDF Digestibility in Ration Formulation', 'Using-NDFD-in-dairy-rations-UW.pdf'],
    ['Will Alfalfa Respond to Sulfur in Wisconsin?', 'Will-Alfalfa-respond-to-sulfur-in-Wis.pdf'],
    ['Wisconsin Forage Resources', 'https://fyi.extension.wisc.edu/forage/', true],
    ['Wisconsin Forage Trials', 'https://fyi.extension.wisc.edu/forage/category/trial-results/', true],
  ],
  'U.S. Department of Agriculture': [
    ['Forage Fescues in the Northern USA', 'Forage-fescue-in-Northern-USA.pdf'],
    ['Perennial Forages Benefit Soils and Water Quality', 'Perennial-forage-benefits-USDA.pdf'],
    ['USDA-NRCS Soil Information', 'http://websoilsurvey.nrcs.usda.gov/app/', true],
  ],
  'West Virginia University': [
    ['Grass Tetany', 'Reducing-The-Risk-of-Grass-Tetany-WVU.pdf'],
  ],
};

export default resources;