import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Box
} from '@material-ui/core';

import speciesHTML from '../StaticData/speciesHTML';


const useStyles = makeStyles ((theme) => ({
  cont: {
    backgroundColor: 'white',
    width: '50%',
    boxSizing: 'border-box',
    border: '1px solid black',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      width: 530
    }
  },
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 12,
    '& h1': {
      fontSize: 16,
      marginBottom: 16,
      color: 'rgb(150,0,0)'
    },
    '& h2': {
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 8
    },
    '& ul': {
      listStyleType: 'disc',
      paddingLeft: 30,
    }
  },
  cell: {
    fontSize: 12,
    padding: '3px 0px',
    borderLeft: '1px solid rgb(200,200,200)',
    textAlign: 'center',
    minWidth: 50
  },
  link: {
    color: 'rgb(150,0,0)',
    borderLeft: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  head: {
    padding: '3px !important',
    textAlign: 'center',
    borderLeft: '1px solid rgb(200,200,200)',
    fontWeight: 'bold'
  },
  row: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  noLeftBorder: {
    borderLeft: 'none'
  },
  headerBorder: {
    borderBottom: '2px solid rgb(200,200,200)'
  },
  title: {
    marginBottom: 20,
    fontWeight: 'bold'
  }
}));



export default function TextResults({ speciesList, landUse, title }) {
  const [modalContent, setModalContent] = useState('');
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleClose = () => setOpen(false);

  const handleModal = (title) => {
    setModalContent(speciesHTML[title]);
    setOpen(true);
  };

  const renderTable = () => {
    if (speciesList.length === 0) {
      return <div>&nbsp;&nbsp;&nbsp;&nbsp;No species found for the options selected.</div>;
    } else if (speciesList[0] === 'error') {
      return <div>&nbsp;&nbsp;&nbsp;&nbsp;A species list could not be generated for the option combination that you have selected.</div>;
    } else if (speciesList[0] === 'not found') {
      return <div>&nbsp;&nbsp;&nbsp;&nbsp;The soil type that you have selected is not currently supported by this tool.</div>;
    } else {
      let headers;
      if (landUse === 'pasture') {
        headers = 
        <>
          <TableRow>
            <TableCell className={`${classes.head} ${classes.noLeftBorder}`} rowSpan={2}>Species Name</TableCell>
            <TableCell className={classes.head} rowSpan={2}>Tons per acre</TableCell>
            <TableCell className={classes.head} colSpan={7}>Pounds dry matter/acre/month</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.head}>May</TableCell>
            <TableCell className={classes.head}>June</TableCell>
            <TableCell className={classes.head}>July</TableCell>
            <TableCell className={classes.head}>Aug</TableCell>
            <TableCell className={classes.head}>Sept</TableCell>
            <TableCell className={classes.head}>Oct</TableCell>
            <TableCell className={classes.head}>Nov</TableCell>
          </TableRow>
        </>;
      } else if (landUse === 'conservation') {
        headers =
        <TableRow>
          <TableCell className={`${classes.head} ${classes.noLeftBorder}`}>Species Name</TableCell>
        </TableRow>;
      } else {
        headers = 
        <TableRow>
          <TableCell className={`${classes.head} ${classes.noLeftBorder}`}>Species Name</TableCell>
          <TableCell className={classes.head}>Tons per acre</TableCell>
        </TableRow>;
      }

      return (
        <Table size='small'>
          <TableHead className={classes.headerBorder}>
            {headers}
          </TableHead>
          <TableBody>
            {speciesList.map((row) => (
              <TableRow
                key={uuidv4()}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                className={classes.row}
              >
                {
                  row.map((cell, i) => {
                    if (i === 0) {
                      return <TableCell className={`${classes.cell} ${classes.link}`} key={uuidv4()} onClick={() => handleModal(cell)}>{cell}</TableCell>;
                    } else {
                      return <TableCell key={uuidv4()} className={classes.cell}>{cell}</TableCell>;
                    }
                  })
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
  };

  return (
    <div className={classes.cont}>
      <h2 className={classes.title}>{title}</h2>

      {renderTable()}

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.box}>
          {modalContent}
        </Box>
      </Modal>
    </div>
  );
}



TextResults.propTypes = {
  speciesList: PropTypes.array,
  landUse: PropTypes.string,
  title: PropTypes.string
};