import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

// import SoilTypeSelector from './SoilTypeSelector';
import CustomRadio from './CustomRadio';
import CustomSelector from './CustomSelector';
import LocationInfo from './LocationInfo';

import {
  LAND_USE,
  CONS_USE,
  DRAINAGE,
  FOOD_USE
} from '../StaticData/optionsInfo';



export default function ForageSpeciesSelectorOptions({
  section,
  options,
  handleChangeOptions,
  soilsAtPlace,
  token
}) {
  const renderDrainage = () => {
    try {
      let drainage = soilsAtPlace.items.find(obj => obj.value === options.soil).drainageClass;
  
      if (drainage === '' || !drainage) return '';

      return (
        <div style={{fontSize: '13px', marginTop: '6px', textAlign: 'center', border: '1px solid orange', borderRadius: '5px', padding: '4px'}}>
          &apos;<b>{options.soil}</b>&apos; soil is typically classified as &apos;<b>{drainage}</b>&apos;
        </div>
      );
    } catch {
      return '';
    }
  };

  const renderLandUseDependentOption = () => {
    let firstRadio;
    if (options.landUse === 'conservation') {
      firstRadio =  <CustomRadio 
        infoObj={CONS_USE}
        selected={options.consUse}
        setSelected={(e) => handleChangeOptions({consUse: e})}
      />;
    } else {
      let label = FOOD_USE.label;
      if (options.landUse === 'pasture') {
        label = 'Intended Pasture Use';
      }

      firstRadio =  <CustomRadio 
        infoObj={{ ...FOOD_USE, label }}
        selected={options.foodUse}
        setSelected={(e) => handleChangeOptions({foodUse: e})}
      />;
    }
    
    return <div style={{paddingTop: '8px'}}>{firstRadio}</div>;
  };

  return (
    <div>
      <div className={section} style={{paddingTop: 0}}>
        <LocationInfo options={options} handleChangeOptions={handleChangeOptions} token={token} />
      </div>

      <div className={section}>
        { Object.keys(soilsAtPlace).length > 0 && soilsAtPlace.items.length > 0 ?
          <CustomSelector
            infoObj={soilsAtPlace}
            selected={soilsAtPlace.items.map(soil => soil.value).includes(options.soil) ? options.soil : ''}
            setSelected={(e) => handleChangeOptions({soil: e})}
          />
          :
          <div style={{color: 'red', textAlign: 'center', fontSize: '14px'}}>{options.lat === null ? 'Please select a location by clicking on the map.' : 'No supported soil types found at this location. Please select a different location.'}</div>
        }
      </div>

      <div className={section}>
        <TextField
          label='Soil pH'
          type='number'
          value={parseFloat(options.ph)}
          onChange={(e) => handleChangeOptions({ph: String(e.target.value)})}
          inputProps={{
            min: '4',
            max: '8',
            step:'0.1',
            style: { textAlign: 'center', padding: '0px 0px 4px 0px', fontSize: '13px' }
          }}
          size='small'
          fullWidth
        />

        <div style={{fontSize: 12, textAlign: 'center', position: 'relative', top: '3px', color: 'rgb(200, 20, 20)'}}>If unsure leave at 0</div>
      </div>

      <div className={section}>
        <CustomRadio
          infoObj={DRAINAGE}
          selected={options.drainage}
          setSelected={(e) => handleChangeOptions({drainage: e})}
        />

        {renderDrainage()}
      </div>

      <div className={section}>
        <CustomRadio
          infoObj={LAND_USE}
          selected={options.landUse}
          setSelected={(e) => handleChangeOptions({landUse: e})}
        />
      </div>

      { renderLandUseDependentOption() }
    </div>
  );
}



ForageSpeciesSelectorOptions.propTypes = {
  section: PropTypes.string,
  options: PropTypes.object,
  handleChangeOptions: PropTypes.func,
  soilsAtPlace: PropTypes.object,
  token: PropTypes.string
};