export const LAND_USE = {
  label: 'Forage Use',
  items: [
    {
      value: 'feed',
      text: 'Hay/Silage'
    },
    {
      value: 'pasture',
      text: 'Pasture'
    },
    {
      value: 'conservation',
      text: 'Conservation Use'
    }
  ]
};

export const CONS_USE = {
  label: 'Intended Conservation Use',
  items: [
    {
      value: 'disturbed',
      text: 'Disturbed Areas'
    },
    {
      value: 'erosion',
      text: 'Erosion Control'
    },
    {
      value: 'plowdown',
      text: 'Plowdown Covercrop'
    },
    {
      value: 'wildlife',
      text: 'Wildlife Habitat'
    },
  ]  
};

export const DRAINAGE = {
  label: 'Artificial Drainage Situation',
  items: [
    {
      value: 'optimum',
      text: 'Optimum'
    },
    {
      value: 'moderate',
      text: 'Moderate'
    },
    {
      value: 'some',
      text: 'Some'
    },
    {
      value: 'none',
      text: 'None'
    },
  ]
};

export const FOOD_USE = {
  label: 'Intended Hay or Silage Use',
  items: [
    {
      value: 'beef',
      text: 'Beef Cows'
    },
    {
      value: 'dry dairy',
      text: 'Dry Dairy Cows'
    },
    {
      value: 'horses',
      text: 'Horses'
    },
    {
      value: 'wet dairy',
      text: 'Lactating Dairy Cows'
    },
    {
      value: 'sheep',
      text: 'Sheep'
    },
  ]
};