import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  alert: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 'fit-content',
    padding: 6,
    borderTop: '1px solid black',
    fontSize: '14px',
    textAlign: 'center',
    zIndex: 10
  },
  resultsUpdated: {
    backgroundColor: 'rgb(29, 233, 20)'
  }
}));
    
export default function Alert({ variant, children }) {
  const classes = useStyles();

  return (
    <div className={`${classes.alert} ${classes[variant]}`}>
      {children}
    </div>
  );
}

Alert.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node
};