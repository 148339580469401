const soilInfo = {
  'Acton': { 'great_grou':'Udorthent', 'li':'L', 'mgt':4, 'dr':'ME', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'36', 'pos':2.5, 'sym':'D'},
  'Adams': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'WE', 'mod1':'L', 'stxt':'FS', 'mod2':'', 'sstxt':'S', 'line':'117', 'pos':1.5, 'sym':'K'},
  'Adirondack': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'BYV', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Adjidaumo': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'0', 'pos':5.5, 'sym':''},
  'Adrian': { 'great_grou':'Medisaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':7, 'sym':'M'},
  'Agawam': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'97', 'pos':2, 'sym':'G'},
  'Albia': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'21', 'pos':4, 'sym':'BW'},
  'Albrights': { 'great_grou':'Fragiudalf', 'li':'L', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'23', 'pos':3, 'sym':'B'},
  'Alden': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'V', 'mod1':'MK', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'20', 'pos':6, 'sym':'B'},
  'Allagash': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'S', 'line':'122', 'pos':2, 'sym':'K'},
  'Allard': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'102', 'pos':2, 'sym':'G'},
  'Allendale': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':3, 'dr':'PV', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':5.5, 'sym':''},
  'Allis': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'PM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'57', 'pos':4.5, 'sym':'D'},
  'Alluvial land': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Almond': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Alps': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'L', 'line':'22', 'pos':3, 'sym':'B'},
  'Altmar': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'MS', 'mod1':'GR', 'stxt':'FSL', 'mod2':'GR', 'sstxt':'S', 'line':'93', 'pos':3.5, 'sym':'G'},
  'Alton': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':5, 'dr':'WE', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'L', 'line':'106', 'pos':1.5, 'sym':'G'},
  'Amboy': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'FSL', 'line':'129', 'pos':2, 'sym':'L'},
  'Amenia': { 'great_grou':'Eutrochrept', 'li':'H', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'SL', 'line':'27', 'pos':3, 'sym':'C'},
  'Angola': { 'great_grou':'Ochraqualf', 'li':'L', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'10', 'pos':4, 'sym':'AW'},
  'Appleton': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'2', 'pos':4, 'sym':'AW'},
  'Arkport': { 'great_grou':'Hapludalf', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'111', 'pos':2, 'sym':'H'},
  'Armagh': { 'great_grou':'Ochraquult', 'li':'L', 'mgt':2, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'19', 'pos':5, 'sym':'AW'},
  'Arnot': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'WM', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'38', 'pos':2.5, 'sym':'D'},
  'Ashville': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'V', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':6, 'sym':''},
  'Atherton': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'98', 'pos':5.5, 'sym':'GW'},
  'Atkins': { 'great_grou':'Fluvaquent', 'li':'L', 'mgt':3, 'dr':'V', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'145', 'pos':6, 'sym':'G'},
  'Atsion': { 'great_grou':'Haplaquod', 'li':'L', 'mgt':5, 'dr':'P', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'FS', 'line':'120', 'pos':5, 'sym':'KW'},
  'Au gres': { 'great_grou':'Haplaquod', 'li':'L', 'mgt':5, 'dr':'SP', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'117', 'pos':4.5, 'sym':'KW'},
  'Aurelie': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5, 'sym':''},
  'Aurora': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'10', 'pos':3, 'sym':'A'},
  'Barbour': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'143', 'pos':2, 'sym':'G'},
  'Barcelona': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Barre': { 'great_grou':'Ochraqualf', 'li':'L', 'mgt':1, 'dr':'P', 'mod1':'MK', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'16', 'pos':5, 'sym':'AW'},
  'Bash': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Basher': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'SIL', 'line':'144', 'pos':3, 'sym':'G'},
  'Bath': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'66', 'pos':2, 'sym':'E'},
  'Becket': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'BY', 'stxt':'L', 'mod2':'BY', 'sstxt':'L', 'line':'77', 'pos':2, 'sym':'F'},
  'Becraft': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':3, 'sym':''},
  'Belgrade': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'127', 'pos':3, 'sym':'L'},
  'Benson': { 'great_grou':'Eutrochrept', 'li':'H', 'mgt':4, 'dr':'E', 'mod1':'R', 'stxt':'L', 'mod2':'R', 'sstxt':'L', 'line':'25', 'pos':1, 'sym':'C'},
  'Berkshire': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'W', 'mod1':'BY', 'stxt':'FSL', 'mod2':'BY', 'sstxt':'SL', 'line':'74', 'pos':2, 'sym':'F'},
  'Bernardston': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'63', 'pos':2, 'sym':'E'},
  'Berrien': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'90', 'pos':3, 'sym':'G'},
  'Berryland': { 'great_grou':'Haplaquod', 'li':'M', 'mgt':5, 'dr':'V', 'mod1':'MK', 'stxt':'S', 'mod2':'', 'sstxt':'LS', 'line':'120', 'pos':6, 'sym':'K'},
  'Beseman': { 'great_grou':'Borosaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':7, 'sym':'M'},
  'Bice': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':5, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':2, 'sym':''},
  'Biddeford': { 'great_grou':'Humaquept', 'li':'M', 'mgt':2, 'dr':'V', 'mod1':'MK', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'128', 'pos':6, 'sym':'L'},
  'Birdsall': { 'great_grou':'Humaquept', 'li':'L', 'mgt':3, 'dr':'VP', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'103', 'pos':5.5, 'sym':'GW'},
  'Blasdell': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'SH', 'stxt':'SIL', 'mod2':'SH', 'sstxt':'SIL', 'line':'100', 'pos':2, 'sym':'G'},
  'Bombay': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'1', 'pos':3, 'sym':'A'},
  'Bonaparte': { 'great_grou':'Udorthent', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'GR', 'stxt':'SL', 'mod2':'GR', 'sstxt':'SL', 'line':'86', 'pos':1, 'sym':'G'},
  'Bono': { 'great_grou':'Haplaquoll', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SIC', 'line':'0', 'pos':7, 'sym':'M'},
  'Boots': { 'great_grou':'Medihemist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'IC', 'line':'0', 'pos':7, 'sym':'M'},
  'Borosaprists': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Boynton': { 'great_grou':'Fragiaqualf', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'20', 'pos':5, 'sym':'BW'},
  'Braceville': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'108', 'pos':3, 'sym':'G'},
  'Brayton': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'ST', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'FSL', 'line':'80', 'pos':4, 'sym':'FW'},
  'Bridgehampton': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'WM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'104', 'pos':2.5, 'sym':'G'},
  'Bridport': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'128', 'pos':4, 'sym':'LW'},
  'Briggs': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'CB', 'stxt':'FSL', 'mod2':'CB', 'sstxt':'FSL', 'line':'124', 'pos':1.5, 'sym':'K'},
  'Brinkerton': { 'great_grou':'Fragiaqualf', 'li':'L', 'mgt':2, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'24', 'pos':5, 'sym':'BW'},
  'Broadalbin': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'WM', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'SL', 'line':'62', 'pos':2.5, 'sym':'E'},
  'Brockport': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':1, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'14', 'pos':4, 'sym':'AW'},
  'Brookfield': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Buckland': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':3, 'dr':'MW', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'0', 'pos':2.5, 'sym':''},
  'Bucksport': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Budd': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'SL', 'mod2':'', 'sstxt':'SL', 'line':'101', 'pos':2, 'sym':'G'},
  'Burdett': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'11', 'pos':4, 'sym':'AW'},
  'Burnham': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':5, 'sym':''},
  'Busti': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'GR', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Buxton': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'128', 'pos':3, 'sym':'L'},
  'Cambria': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'2', 'pos':5.5, 'sym':'AW'},
  'Cambridge': { 'great_grou':'Fragiudalf', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'GR', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'21', 'pos':3, 'sym':'B'},
  'Camillus': { 'great_grou':'Eutrochrept', 'li':'H', 'mgt':3, 'dr':'WM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'34', 'pos':2.5, 'sym':'C'},
  'Camroden': { 'great_grou':'Fragiaquod', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'83', 'pos':4, 'sym':'FW'},
  'Canaan': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'ST', 'stxt':'SIL', 'mod2':'ST', 'sstxt':'SIL', 'line':'73', 'pos':1, 'sym':'F'},
  'Canaan-rock out': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':' ST', 'stxt':'SIL', 'mod2':'ST', 'sstxt':'SIL', 'line':'', 'pos':1, 'sym':''},
  'Canadice': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'133', 'pos':5, 'sym':'LW'},
  'Canandaigua': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'MK', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'131', 'pos':5, 'sym':'LW'},
  'Canaseraga': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'WM', 'mod1':'VF', 'stxt':'SL', 'mod2':'', 'sstxt':'SL', 'line':'71', 'pos':2.5, 'sym':'E'},
  'Canastota': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'9', 'pos':3, 'sym':'A'},
  'Caneadea': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'132', 'pos':4, 'sym':'LW'},
  'Canfield': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':'E'},
  'Canton': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'ST', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'FSL', 'line':'43', 'pos':2, 'sym':'D'},
  'Carbondale': { 'great_grou':'Borosaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':7, 'sym':'M'},
  'Carlisle': { 'great_grou':'Medisaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SIL', 'line':'155', 'pos':7, 'sym':'M'},
  'Carrollton': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Carver': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'S', 'mod2':'', 'sstxt':'S', 'line':'87', 'pos':1, 'sym':'G'},
  'Carver-plymouth': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':' S', 'mod2':'', 'sstxt':'S', 'line':'', 'pos':1, 'sym':''},
  'Castile': { 'great_grou':'Dystrochrept', 'li':'M', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'98', 'pos':2, 'sym':'G'},
  'Cathro': { 'great_grou':'Borosaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'159', 'pos':7, 'sym':'M'},
  'Cathro-greenwoo': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Cattaraugus': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'67', 'pos':2, 'sym':'E'},
  'Cavode': { 'great_grou':'Ochraquult', 'li':'L', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'19', 'pos':4, 'sym':'AW'},
  'Cayuga': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'WM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'16', 'pos':2.5, 'sym':'A'},
  'Cazenovia': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'WM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'12', 'pos':2.5, 'sym':'A'},
  'Ceresco': { 'great_grou':'', 'li':'M', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'149', 'pos':3, 'sym':'G'},
  'Chadakoin': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Chagrin': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'148', 'pos':2, 'sym':'G'},
  'Champlain': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Charles': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5, 'sym':''},
  'Charlton': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'ST', 'stxt':'L', 'mod2':'ST', 'sstxt':'L', 'line':'44', 'pos':2, 'sym':'D'},
  'Chatfield': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'45', 'pos':1, 'sym':'D'},
  'Chatfield2': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'', 'stxt':'RL', 'mod2':'', 'sstxt':'RL', 'line':'0', 'pos':1.5, 'sym':''},
  'Chaumont': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':1, 'dr':'SP', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'138', 'pos':4.5, 'sym':'LW'},
  'Chautauqua': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'MW', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':2.5, 'sym':''},
  'Cheektowaga': { 'great_grou':'Haplaquoll', 'li':'M', 'mgt':5, 'dr':'PV', 'mod1':'', 'stxt':'SL', 'mod2':'', 'sstxt':'SL', 'line':'125', 'pos':5.5, 'sym':'LW'},
  'Chenango': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'WE', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'L', 'line':'98', 'pos':1.5, 'sym':'G'},
  'Cheshire': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'ST', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'FSL', 'line':'50', 'pos':2, 'sym':'D'},
  'Chippeny': { 'great_grou':'Borosaprist', 'li':'0', 'mgt':9, 'dr':'P', 'mod1':'', 'stxt':'ICL', 'mod2':'', 'sstxt':'ICL', 'line':'160', 'pos':5, 'sym':'M'},
  'Chippewa': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'20', 'pos':5, 'sym':'BW'},
  'Churchville': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'16', 'pos':4, 'sym':'AW'},
  'Cicero': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'9', 'pos':4, 'sym':'AW'},
  'Clarkson': { 'great_grou':'', 'li':'H', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'GRL', 'mod2':'', 'sstxt':'GRL', 'line':'2', 'pos':3, 'sym':'A'},
  'Claverack': { 'great_grou':'Udorthent', 'li':'L', 'mgt':4, 'dr':'MW', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'125', 'pos':2.5, 'sym':'L'},
  'Clinburg': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'CB', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Clymer': { 'great_grou':'Hapludult', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'17', 'pos':2, 'sym':'A'},
  'Cohoctah': { 'great_grou':'Haplaquoll', 'li':'L', 'mgt':4, 'dr':'PV', 'mod1':'MK', 'stxt':'VFSL', 'mod2':'', 'sstxt':'VFSL', 'line':'149', 'pos':5.5, 'sym':'GW'},
  'Collamer': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'131', 'pos':3, 'sym':'L'},
  'Colonie': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'WE', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'90', 'pos':1.5, 'sym':'G'},
  'Colosse': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'GR', 'stxt':'LFS', 'mod2':'GR', 'sstxt':'LFS', 'line':'123', 'pos':1, 'sym':'K'},
  'Colrain': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'FSL', 'mod2':'GR', 'sstxt':'FSL', 'line':'0', 'pos':2, 'sym':''},
  'Colton': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'GR', 'stxt':'LS', 'mod2':'GR', 'sstxt':'LS', 'line':'114', 'pos':1, 'sym':'K'},
  'Colwood': { 'great_grou':'Haplaquoll', 'li':'L', 'mgt':3, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'131', 'pos':5.5, 'sym':'LW'},
  'Conesus': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'5', 'pos':3, 'sym':'A'},
  'Conotton': { 'great_grou':'Hapludalf', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'100', 'pos':2, 'sym':'G'},
  'Constable': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'WE', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'S', 'line':'115', 'pos':1.5, 'sym':'K'},
  'Cook': { 'great_grou':'Haplaquent', 'li':'M', 'mgt':5, 'dr':'VP', 'mod1':'CB', 'stxt':'LS', 'mod2':'CB', 'sstxt':'LS', 'line':'37', 'pos':5.5, 'sym':'DW'},
  'Copake': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':4, 'dr':'WE', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'L', 'line':'105', 'pos':1.5, 'sym':'G'},
  'Cornish': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Cosad': { 'great_grou':'Udorthent', 'li':'M', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'125', 'pos':4, 'sym':'LW'},
  'Cossayuna': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'63', 'pos':2, 'sym':'E'},
  'Covert': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Coveytown': { 'great_grou':'Haplaquent', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'GR', 'stxt':'LS', 'mod2':'GR', 'sstxt':'LS', 'line':'37', 'pos':4, 'sym':'DW'},
  'Covington': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':1, 'dr':'P', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'136', 'pos':5, 'sym':'LW'},
  'Crary': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'MS', 'mod1':'BY', 'stxt':'SIL', 'mod2':'BY', 'sstxt':'L', 'line':'79', 'pos':3.5, 'sym':'F'},
  'Croghan': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'S', 'line':'117', 'pos':3, 'sym':'K'},
  'Culvers': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'L', 'line':'67', 'pos':3, 'sym':'E'},
  'Dalbo': { 'great_grou':'Glossoboralf', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'0', 'pos':3, 'sym':''},
  'Dalton': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'71', 'pos':4, 'sym':'EW'},
  'Danley': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'9', 'pos':3, 'sym':'A'},
  'Dannemora': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':4, 'dr':'P', 'mod1':'ST', 'stxt':'SIL', 'mod2':'ST', 'sstxt':'SIL', 'line':'77', 'pos':5, 'sym':'FW'},
  'Darien': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'9', 'pos':4, 'sym':'AW'},
  'Dawson': { 'great_grou':'Borosaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SICL', 'line':'0', 'pos':7, 'sym':'M'},
  'Deerfield': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'S', 'line':'89', 'pos':3, 'sym':'G'},
  'Deford': { 'great_grou':'Psammaquent', 'li':'L', 'mgt':4, 'dr':'PV', 'mod1':'MK', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'0', 'pos':5.5, 'sym':''},
  'Dekalb': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'LS', 'line':'42', 'pos':2, 'sym':'D'},
  'Depeyster': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':3, 'sym':''},
  'Deposit': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'GR', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Derb': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Dixmont': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'BY', 'stxt':'L', 'mod2':'BY', 'sstxt':'L', 'line':'74', 'pos':3, 'sym':'F'},
  'Dorval': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Dover': { 'great_grou':'Eutrochrept', 'li':'H', 'mgt':4, 'dr':'W', 'mod1':'ST', 'stxt':'L', 'mod2':'ST', 'sstxt':'L', 'line':'29', 'pos':2, 'sym':'C'},
  'Duane': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'LS', 'mod2':'GR', 'sstxt':'LS', 'line':'115', 'pos':3, 'sym':'K'},
  'Dunkirk': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'131', 'pos':2, 'sym':'L'},
  'Dutchess': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'L', 'line':'63', 'pos':2, 'sym':'E'},
  'Duxbury': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Edwards': { 'great_grou':'Medisaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'158', 'pos':6, 'sym':'M'},
  'Eel': { 'great_grou':'Udifluvent', 'li':'M', 'mgt':2, 'dr':'MS', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'150', 'pos':3.5, 'sym':'G'},
  'Eelweir': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Elka': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Ellery': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'20', 'pos':5, 'sym':'BW'},
  'Elmridge': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':3, 'sym':''},
  'Elmwood': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'126', 'pos':3, 'sym':'L'},
  'Elnora': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'90', 'pos':3, 'sym':'G'},
  'Empeyville': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'ST', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'FSL', 'line':'77', 'pos':3, 'sym':'F'},
  'Enfield': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Ensley': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':5.5, 'sym':''},
  'Erie': { 'great_grou':'Fragiaqualf', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'20', 'pos':4, 'sym':'BW'},
  'Ernest': { 'great_grou':'Fragiudalf', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SICL', 'line':'0', 'pos':2, 'sym':''},
  'Essex': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':5, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'FSL', 'line':'58', 'pos':2, 'sym':'E'},
  'Fahey': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'CB', 'stxt':'LS', 'mod2':'GR', 'sstxt':'LS', 'line':'116', 'pos':3, 'sym':'K'},
  'Farmington': { 'great_grou':'Eutrochrept', 'li':'H', 'mgt':3, 'dr':'WE', 'mod1':'', 'stxt':'L', 'mod2':'R', 'sstxt':'L', 'line':'33', 'pos':1.5, 'sym':'C'},
  'Farnham': { 'great_grou':'Dystrochrept', 'li':'M', 'mgt':4, 'dr':'M', 'mod1':'SH', 'stxt':'SIL', 'mod2':'SH', 'sstxt':'SIL', 'line':'100', 'pos':3, 'sym':'G'},
  'Fernlake': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Flackville': { 'great_grou':'Udorthent', 'li':'L', 'mgt':4, 'dr':'MW', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'0', 'pos':2.5, 'sym':''},
  'Fonda': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':2, 'dr':'V', 'mod1':'MK', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'132', 'pos':6, 'sym':'L'},
  'Franklinville': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Fredon': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':4, 'dr':'PS', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'105', 'pos':4.5, 'sym':'G'},
  'Freetown': { 'great_grou':'Medisaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'N', 'sstxt':'ICL', 'line':'0', 'pos':7, 'sym':'M'},
  'Fremont': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':2, 'dr':'S', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'53', 'pos':4, 'sym':'DW'},
  'Frenchtown': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'21', 'pos':5.5, 'sym':'BW'},
  'Frewsburg': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Fryeburg': { 'great_grou':'', 'li':'H', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Fulton': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'13', 'pos':5.5, 'sym':'AW'},
  'Gage': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'83', 'pos':5, 'sym':'FW'},
  'Galen': { 'great_grou':'Hapludalf', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'VFSL', 'line':'111', 'pos':3, 'sym':'H'},
  'Galestown': { 'great_grou':'Hapludult', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'S', 'mod2':'', 'sstxt':'S', 'line':'87', 'pos':1, 'sym':'G'},
  'Galoo': { 'great_grou':'Udorthent', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'', 'stxt':'L', 'mod2':'R', 'sstxt':'L', 'line':'0', 'pos':1.5, 'sym':''},
  'Galoo-rock outc': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'', 'stxt':'L', 'mod2':'R', 'sstxt':'', 'line':'', 'pos':1.5, 'sym':''},
  'Galway': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'SIL', 'line':'30', 'pos':2, 'sym':'C'},
  'Genesee': { 'great_grou':'Udifluvent', 'li':'M', 'mgt':2, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'150', 'pos':2, 'sym':'G'},
  'Georgia': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'31', 'pos':3, 'sym':'C'},
  'Getzville': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':5.5, 'sym':''},
  'Gilpen': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'SH', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Gilpin': { 'great_grou':'Hapludult', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'SH', 'stxt':'SIL', 'mod2':'SH', 'sstxt':'SIL', 'line':'18', 'pos':2, 'sym':'A'},
  'Glebe': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'BYV', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1.5, 'sym':''},
  'Glebe-saddlebac': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'BYV', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1.5, 'sym':''},
  'Glendora': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'MK', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Glenfield': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'VP', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'112', 'pos':5.5, 'sym':'HW'},
  'Gloucester': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'ST', 'stxt':'SL', 'mod2':'ST', 'sstxt':'SL', 'line':'35', 'pos':1, 'sym':'D'},
  'Glover': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'CN', 'stxt':'L', 'mod2':'R', 'sstxt':'L', 'line':'0', 'pos':1, 'sym':''},
  'Gougeville': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'V', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':6, 'sym':''},
  'Granby': { 'great_grou':'Haplaquoll', 'li':'M', 'mgt':5, 'dr':'PV', 'mod1':'MK', 'stxt':'LFS', 'mod2':'', 'sstxt':'S', 'line':'92', 'pos':5.5, 'sym':'GW'},
  'Grattan': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Greene': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Greenwood': { 'great_grou':'Borohemist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'H', 'sstxt':'SIL', 'line':'154', 'pos':7, 'sym':'M'},
  'Grenville': { 'great_grou':'Eutrochrept', 'li':'H', 'mgt':4, 'dr':'W', 'mod1':'ST', 'stxt':'L', 'mod2':'ST', 'sstxt':'L', 'line':'28', 'pos':2, 'sym':'C'},
  'Gretor': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Groton': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'L', 'line':'0', 'pos':3, 'sym':''},
  'Groveton': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'EW', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'95', 'pos':1.5, 'sym':'G'},
  'Guff': { 'great_grou':'', 'li':'L', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5.5, 'sym':''},
  'Guffin': { 'great_grou':'Haplaquept', 'li':'H', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'SIC', 'mod2':'', 'sstxt':'SIC', 'line':'139', 'pos':5.5, 'sym':'LW'},
  'Gulf': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':4, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':5.5, 'sym':''},
  'Hadley': { 'great_grou':'Udifluvent', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'147', 'pos':2, 'sym':'G'},
  'Haights': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'WP', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':2.5, 'sym':''},
  'Haights-gulf': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'WP', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2.5, 'sym':''},
  'Hailesboro': { 'great_grou':'Ochraqualf', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Halcott': { 'great_grou':'', 'li':'L', 'mgt':2, 'dr':'MW', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2.5, 'sym':''},
  'Halsey': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':4, 'dr':'V', 'mod1':'MK', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'105', 'pos':6, 'sym':'G'},
  'Hamlin': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':2, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'150', 'pos':2, 'sym':'G'},
  'Hamplain': { 'great_grou':'', 'li':'L', 'mgt':2, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Hannawa': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'P', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5, 'sym':''},
  'Hartland': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'VFSL', 'line':'127', 'pos':2, 'sym':'L'},
  'Haven': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'96', 'pos':2, 'sym':'G'},
  'Hawksnest': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'MW', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2.5, 'sym':''},
  'Hempstead': { 'great_grou':'Hapludoll', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'96', 'pos':2, 'sym':'G'},
  'Henrietta': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Herkimer': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':3, 'dr':'WM', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'107', 'pos':2.5, 'sym':'G'},
  'Hermon': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'ST', 'stxt':'FSL', 'mod2':'BY', 'sstxt':'FSL', 'line':'72', 'pos':1.5, 'sym':'F'},
  'Hero': { 'great_grou':'', 'li':'M', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'105', 'pos':3, 'sym':'G'},
  'Heuvelton': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'MW', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'0', 'pos':2.5, 'sym':''},
  'Hilton': { 'great_grou':'Hapludalf', 'li':'H', 'mgt':2, 'dr':'M', 'mod1':'GR', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'2', 'pos':3, 'sym':'A'},
  'Hinckley': { 'great_grou':'Udorthent', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'GR', 'stxt':'LS', 'mod2':'GR', 'sstxt':'LS', 'line':'84', 'pos':1, 'sym':'G'},
  'Hinesburg': { 'great_grou':'Udorthent', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'37', 'pos':2, 'sym':'D'},
  'Histic humaquep': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Hogansburg': { 'great_grou':'Eutrochrept', 'li':'H', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'28', 'pos':3, 'sym':'C'},
  'Hogback': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'RV', 'stxt':'SL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Hogback-ricker': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'', 'stxt':'SL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Holderton': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Hollis': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'ST', 'stxt':'SL', 'mod2':'', 'sstxt':'SL', 'line':'46', 'pos':4, 'sym':'DW'},
  'Holly': { 'great_grou':'Fluvaquent', 'li':'M', 'mgt':2, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'144', 'pos':5.5, 'sym':'GW'},
  'Holyoke': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'WE', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'47', 'pos':1.5, 'sym':'D'},
  'Holyoke-rock ou': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'WE', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1.5, 'sym':''},
  'Homer': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'GR', 'stxt':'L', 'mod2':'', 'sstxt':'SIL', 'line':'112', 'pos':4, 'sym':'HW'},
  'Honeoye': { 'great_grou':'Hapludalf', 'li':'H', 'mgt':2, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'4', 'pos':2, 'sym':'A'},
  'Hoosic': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'EW', 'mod1':'GR', 'stxt':'SL', 'mod2':'GR', 'sstxt':'SL', 'line':'94', 'pos':1.5, 'sym':'G'},
  'Hornell': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':2, 'dr':'SM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'57', 'pos':3.5, 'sym':'DW'},
  'Hornellsville': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Houghtonville': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'W', 'mod1':'BYV', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Houghtonville-r': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'W', 'mod1':'BYV', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Housatonic': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'SP', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'147', 'pos':4.5, 'sym':'GW'},
  'Houseville': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'112', 'pos':4, 'sym':'HW'},
  'Howard': { 'great_grou':'Howard', 'li':'M', 'mgt':3, 'dr':'WE', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'L', 'line':'110', 'pos':1.5, 'sym':'H'},
  'Hudson': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'132', 'pos':3, 'sym':'L'},
  'Hulberton': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'2', 'pos':4, 'sym':'AW'},
  'Humaquepts': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Humaquepts-fibr': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Hunter': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'BY', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'0', 'pos':2, 'sym':''},
  'Hydraquents': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Hydraquents-med': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Ilion': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'8', 'pos':5, 'sym':'AW'},
  'Insula': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'R', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':2, 'sym':''},
  'Ipswich': { 'great_grou':'Sulfihemist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SL', 'line':'0', 'pos':7, 'sym':'M'},
  'Ira': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'FSL', 'mod2':'GR', 'sstxt':'FSL', 'line':'68', 'pos':3, 'sym':'E'},
  'Ischua': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Ivory': { 'great_grou':'', 'li':'L', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Jebavy': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'P', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5, 'sym':''},
  'Joliet': { 'great_grou':'Haplaquoll', 'li':'M', 'mgt':4, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'33', 'pos':5, 'sym':'CLW'},
  'Junius': { 'great_grou':'Psammaquent', 'li':'L', 'mgt':5, 'dr':'P', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'92', 'pos':5, 'sym':'GW'},
  'Kalurah': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'0', 'pos':3, 'sym':''},
  'Kanona': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':2, 'dr':'PS', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'56', 'pos':4.5, 'sym':'D'},
  'Kars': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':4, 'dr':'WE', 'mod1':'GR', 'stxt':'SL', 'mod2':'GR', 'sstxt':'SL', 'line':'109', 'pos':1.5, 'sym':'H'},
  'Kearsarge': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'E', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Kendaia': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'6', 'pos':4, 'sym':'AW'},
  'Kibbie': { 'great_grou':'Hapludalf', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'131', 'pos':4, 'sym':'LW'},
  'Kingsbury': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':1, 'dr':'S', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'C', 'line':'136', 'pos':4, 'sym':'LW'},
  'Kinzua': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Knickerbocker': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':1, 'sym':''},
  'Lackawanna': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'FL', 'stxt':'SIL', 'mod2':'FL', 'sstxt':'SIL', 'line':'67', 'pos':2, 'sym':'E'},
  'Lagross': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'SH', 'stxt':'SIL', 'mod2':'SH', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Lagross-haights': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'SH', 'stxt':'SIL', 'mod2':'SH', 'sstxt':'SIL', 'line':'', 'pos':2, 'sym':''},
  'Lairdsville': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'MW', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'15', 'pos':2.5, 'sym':'A'},
  'Lakemont': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'134', 'pos':5.5, 'sym':'LW'},
  'Lakewood': { 'great_grou':'Quartzipsamment', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'S', 'mod2':'', 'sstxt':'S', 'line':'87', 'pos':1, 'sym':'G'},
  'Lamson': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':4, 'dr':'PV', 'mod1':'MK', 'stxt':'VFSL', 'mod2':'', 'sstxt':'FSL', 'line':'111', 'pos':5.5, 'sym':'HW'},
  'Lanesboro': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'MW', 'mod1':'STCN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2.5, 'sym':''},
  'Langford': { 'great_grou':'Fragiudalf', 'li':'L', 'mgt':3, 'dr':'MW', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'20', 'pos':2.5, 'sym':'B'},
  'Lansing': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'W', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'5', 'pos':2, 'sym':'A'},
  'Leck kill': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Leicester': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':4, 'dr':'P', 'mod1':'ST', 'stxt':'SIL', 'mod2':'BY', 'sstxt':'FSL', 'line':'44', 'pos':5, 'sym':'DW'},
  'Leon': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'P', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'S', 'line':'120', 'pos':5, 'sym':'KW'},
  'Lewbath': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Lewbeach': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'FL', 'stxt':'SIL', 'mod2':'FL', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Leyden': { 'great_grou':'Argiustoll', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'9', 'pos':3, 'sym':'A'},
  'Lima': { 'great_grou':'Hapludalf', 'li':'H', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'4', 'pos':3, 'sym':'A'},
  'Limerick': { 'great_grou':'Fluvaquent', 'li':'L', 'mgt':3, 'dr':'SP', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'147', 'pos':4.5, 'sym':'GW'},
  'Linden': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'144', 'pos':2, 'sym':'G'},
  'Linlithgo': { 'great_grou':'Fluvaquent', 'li':'L', 'mgt':3, 'dr':'MS', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':3.5, 'sym':''},
  'Livingston': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':1, 'dr':'V', 'mod1':'MK', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'136', 'pos':6, 'sym':'L'},
  'Lobdell': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':3, 'dr':'MS', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'148', 'pos':3.5, 'sym':'G'},
  'Lockport': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'15', 'pos':4, 'sym':'AW'},
  'Londonderry': { 'great_grou':'', 'li':'', 'mgt':0, 'dr':'Y', 'mod1':'R', 'stxt':'L', 'mod2':'R', 'sstxt':'', 'line':'', 'pos':8, 'sym':''},
  'Lorain': { 'great_grou':'Ochraqualf', 'li':'L', 'mgt':1, 'dr':'P', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'0', 'pos':5, 'sym':''},
  'Lordstown': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'49', 'pos':2, 'sym':'D'},
  'Lovewell': { 'great_grou':'', 'li':'L', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Lowville': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'52', 'pos':2, 'sym':'D'},
  'Loxley': { 'great_grou':'Borosaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'ICL', 'line':'0', 'pos':7, 'sym':'M'},
  'Lucas': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'CL', 'line':'132', 'pos':3, 'sym':'L'},
  'Ludlow': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'65', 'pos':3, 'sym':'E'},
  'Lupton': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Lyman': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'', 'stxt':'L', 'mod2':'R', 'sstxt':'SL', 'line':'75', 'pos':1, 'sym':'F'},
  'Lyman-becket-be': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'', 'stxt':'L', 'mod2':'R', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Lyme': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':5, 'dr':'SP', 'mod1':'', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'FSL', 'line':'0', 'pos':4.5, 'sym':''},
  'Lyons': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':2, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'2', 'pos':5.5, 'sym':'AW'},
  'Machias': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'S', 'line':'121', 'pos':3, 'sym':'K'},
  'Macomber': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'R', 'stxt':'SIL', 'mod2':'R', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Macomber-taconi': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Madalin': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'13', 'pos':5.5, 'sym':'AW'},
  'Madawaska': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'S', 'line':'122', 'pos':3, 'sym':'K'},
  'Made land': { 'great_grou':'', 'li':'', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':8, 'sym':''},
  'Madrid': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'1', 'pos':2, 'sym':'A'},
  'Malone': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Manahawkin': { 'great_grou':'Medisaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':7, 'sym':'M'},
  'Mandy': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Manheim': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'8', 'pos':4, 'sym':'AW'},
  'Manhoning': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'C', 'line':'13', 'pos':4, 'sym':'AW'},
  'Manlius': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'WE', 'mod1':'SH', 'stxt':'SIL', 'mod2':'SH', 'sstxt':'SIL', 'line':'39', 'pos':1.5, 'sym':'D'},
  'Mansfield': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'V', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'20', 'pos':6, 'sym':'B'},
  'Maplecrest': { 'great_grou':'', 'li':'L', 'mgt':2, 'dr':'W', 'mod1':'GR', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Marcy': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'83', 'pos':5, 'sym':'FW'},
  'Mardin': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'MW', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'66', 'pos':2.5, 'sym':'E'},
  'Marilla': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'SH', 'stxt':'SIL', 'mod2':'SH', 'sstxt':'SIL', 'line':'70', 'pos':3, 'sym':'E'},
  'Markey': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Marlow': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'BY', 'stxt':'VFSL', 'mod2':'BY', 'sstxt':'VFSL', 'line':'81', 'pos':2, 'sym':'F'},
  'Martisco': { 'great_grou':'Humaquept', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'Y', 'sstxt':'FSL', 'line':'153', 'pos':7, 'sym':'M'},
  'Massena': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'SIL', 'line':'1', 'pos':4, 'sym':'AW'},
  'Matoon': { 'great_grou':'', 'li':'L', 'mgt':1, 'dr':'S', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Matunuck': { 'great_grou':'Sulfaquent', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'IL', 'line':'0', 'pos':7, 'sym':'M'},
  'Medihemists': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Medihmeists-hyd': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':' MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Medina': { 'great_grou':'', 'li':'M', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'CL', 'line':'113', 'pos':2, 'sym':'H'},
  'Medomak': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'V', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':6, 'sym':''},
  'Melrose': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'SL', 'mod2':'', 'sstxt':'SL', 'line':'126', 'pos':2, 'sym':'L'},
  'Menlo': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':4, 'dr':'PV', 'mod1':'BY', 'stxt':'SIL', 'mod2':'BY', 'sstxt':'SIL', 'line':'65', 'pos':5.5, 'sym':'EW'},
  'Mentor': { 'great_grou':'Hapludalf', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':2, 'sym':''},
  'Merrimac': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'EW', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'95', 'pos':1.5, 'sym':'G'},
  'Middlebrook': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Middlebrook-mon': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Middlebury': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':3, 'dr':'MS', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'148', 'pos':3.5, 'sym':'G'},
  'Millis': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'VFSL', 'line':'76', 'pos':2, 'sym':'F'},
  'Millsite': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'', 'stxt':'RL', 'mod2':'', 'sstxt':'RL', 'line':'0', 'pos':1.5, 'sym':''},
  'Mineola': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':3, 'sym':''},
  'Miner': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'CL', 'mod2':'', 'sstxt':'C', 'line':'13', 'pos':5.5, 'sym':'AW'},
  'Mino': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'VFSL', 'line':'0', 'pos':4, 'sym':''},
  'Minoa': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'VFSL', 'line':'111', 'pos':4, 'sym':'HW'},
  'Mohawk': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'WM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'8', 'pos':2.5, 'sym':'A'},
  'Mohican': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':3, 'sym':''},
  'Moira': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'ST', 'stxt':'L', 'mod2':'ST', 'sstxt':'L', 'line':'80', 'pos':3, 'sym':'F'},
  'Monadnock': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'BYV', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Monarda': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'VFSL', 'line':'74', 'pos':4, 'sym':'FW'},
  'Mongaup': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Montauk': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'WM', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'VFSL', 'line':'60', 'pos':2.5, 'sym':'E'},
  'Mooers': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'M', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Morocco': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':4, 'dr':'P', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':5, 'sym':''},
  'Morris': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'CN', 'stxt':'SIL', 'mod2':'FL', 'sstxt':'SIL', 'line':'67', 'pos':4, 'sym':'EW'},
  'Mosherville': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'VFSL', 'line':'62', 'pos':4, 'sym':'EW'},
  'Muck': { 'great_grou':'Medisaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'', 'line':'161', 'pos':7, 'sym':'M'},
  'Muck-peat': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Mundal': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'BYV', 'stxt':'SL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Mundalite': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'BYV', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Mundalite-rawso': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'BYV', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Munson': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'SIL', 'line':'128', 'pos':4, 'sym':'LW'},
  'Munuscong': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':4, 'dr':'PV', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':5.5, 'sym':''},
  'Muskego': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Muskellunge': { 'great_grou':'Ochraqualf', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Napoleon': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Napoli': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Nassau': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'SH', 'stxt':'SIL', 'mod2':'R', 'sstxt':'SIL', 'line':'40', 'pos':1, 'sym':'D'},
  'Naumburg': { 'great_grou':'Haplaquod', 'li':'L', 'mgt':5, 'dr':'SP', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'117', 'pos':4.5, 'sym':'KW'},
  'Nehasne': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Nellis': { 'great_grou':'Eutrochrept', 'li':'H', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'27', 'pos':2, 'sym':'C'},
  'Neversink': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'PV', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5.5, 'sym':''},
  'Newfane': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'VFSL', 'line':'111', 'pos':2, 'sym':'H'},
  'Newstead': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':4, 'dr':'SP', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'30', 'pos':4.5, 'sym':'CLW'},
  'Newton': { 'great_grou':'Humaquept', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'S', 'line':'0', 'pos':7, 'sym':'M'},
  'Niagara': { 'great_grou':'Ochraqualf', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'131', 'pos':4, 'sym':'LW'},
  'Nicholville': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'VFSL', 'line':'140', 'pos':3, 'sym':'G'},
  'Ninigret': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'SIL', 'line':'97', 'pos':3, 'sym':'G'},
  'Norchip': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5, 'sym':''},
  'Norwell': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'S', 'mod1':'', 'stxt':'LSF', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Norwich': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'VP', 'mod1':'ST', 'stxt':'SIL', 'mod2':'ST', 'sstxt':'SIL', 'line':'67', 'pos':5.5, 'sym':'EW'},
  'Nunda': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'11', 'pos':3, 'sym':'A'},
  'Oakville': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'WM', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'S', 'line':'92', 'pos':2.5, 'sym':'G'},
  'Occum': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':2, 'sym':''},
  'Odessa': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'134', 'pos':4, 'sym':'LW'},
  'Ogdensburg': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':4, 'dr':'SP', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'0', 'pos':4.5, 'sym':''},
  'Olean': { 'great_grou':'', 'li':'L', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Ondawa': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'146', 'pos':2, 'sym':'G'},
  'Oneida': { 'great_grou':'', 'li':'M', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'VFSL', 'line':'1', 'pos':4, 'sym':'AW'},
  'Onoville': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Ontario': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'W', 'mod1':'GR', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'2', 'pos':2, 'sym':'A'},
  'Onteora': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Ontusia': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Oquaga': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'WE', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'41', 'pos':1.5, 'sym':'D'},
  'Oramel': { 'great_grou':'', 'li':'L', 'mgt':2, 'dr':'WM', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'130', 'pos':2.5, 'sym':'L'},
  'Organic': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SL', 'line':'161', 'pos':7, 'sym':'M'},
  'Orpark': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'54', 'pos':4, 'sym':'DW'},
  'Orwell': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'128', 'pos':5, 'sym':'LW'},
  'Ossipee': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Otego': { 'great_grou':'', 'li':'L', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Otisville': { 'great_grou':'Udorthent', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'GR', 'stxt':'LS', 'mod2':'', 'sstxt':'LS', 'line':'85', 'pos':1, 'sym':'G'},
  'Otsego': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'MW', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'L', 'line':'66', 'pos':2.5, 'sym':'E'},
  'Ottawa': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'WE', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'0', 'pos':1.5, 'sym':''},
  'Ovid': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'SM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'12', 'pos':3.5, 'sym':'AW'},
  'Palatine': { 'great_grou':'Hapludoll', 'li':'H', 'mgt':2, 'dr':'WS', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'26', 'pos':2.5, 'sym':'2'},
  'Palms': { 'great_grou':'Medisaprist', 'li':'0', 'mgt':9, 'dr':'S', 'mod1':'H', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'156', 'pos':4, 'sym':'M'},
  'Palmyra': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':3, 'dr':'WE', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'L', 'line':'112', 'pos':1.5, 'sym':'H'},
  'Panton': { 'great_grou':'Ochraqualf', 'li':'L', 'mgt':1, 'dr':'SP', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'137', 'pos':4.5, 'sym':'LW'},
  'Papakating': { 'great_grou':'Fluvaquent', 'li':'M', 'mgt':2, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'144', 'pos':5.5, 'sym':'GW'},
  'Parishville': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'ST', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'FSL', 'line':'80', 'pos':3, 'sym':'F'},
  'Parsippany': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'CL', 'mod2':'', 'sstxt':'C', 'line':'134', 'pos':5.5, 'sym':'LW'},
  'Patchin': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':5.5, 'sym':''},
  'Pawcatuck': { 'great_grou':'Sulfihemist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':7, 'sym':'M'},
  'Pawling': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':4, 'dr':'MW', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'0', 'pos':2.5, 'sym':''},
  'Paxton': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'L', 'mod2':'ST', 'sstxt':'SIL', 'line':'61', 'pos':2, 'sym':'E'},
  'Peacham': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5, 'sym':''},
  'Peat': { 'great_grou':'Fibrist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'', 'line':'0', 'pos':7, 'sym':'M'},
  'Peat-muck': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Peru': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'BY', 'stxt':'L', 'mod2':'BY', 'sstxt':'L', 'line':'81', 'pos':3, 'sym':'F'},
  'Petoskey': { 'great_grou':'Hapludalf', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'111', 'pos':2, 'sym':'H'},
  'Phelps': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':3, 'dr':'M', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'SIL', 'line':'112', 'pos':3, 'sym':'H'},
  'Philo': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'145', 'pos':3, 'sym':'G'},
  'Pillsbury': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Pinckney': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'WM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'82', 'pos':2.5, 'sym':'F'},
  'Pipestone': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'S', 'mod1':'', 'stxt':'FS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Pittsfield': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'FSL', 'mod2':'GR', 'sstxt':'L', 'line':'32', 'pos':2, 'sym':'C'},
  'Pittstown': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'MS', 'mod1':'GR', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'63', 'pos':3.5, 'sym':'E'},
  'Plainbo': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Plainfield': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'S', 'line':'88', 'pos':1, 'sym':'G'},
  'Plessis': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'38', 'pos':4, 'sym':'DW'},
  'Plymouth': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'S', 'line':'91', 'pos':1, 'sym':'G'},
  'Podunk': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'SL', 'line':'146', 'pos':3, 'sym':'G'},
  'Poland': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'5', 'pos':2, 'sym':'A'},
  'Pompton': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'101', 'pos':3, 'sym':'G'},
  'Pootatuck': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':3, 'sym':''},
  'Pope': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Potsdam': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':4, 'dr':'WM', 'mod1':'', 'stxt':'VFSL', 'mod2':'BY', 'sstxt':'SL', 'line':'79', 'pos':2.5, 'sym':'F'},
  'Poygan': { 'great_grou':'Haplaquoll', 'li':'L', 'mgt':1, 'dr':'P', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'0', 'pos':5, 'sym':''},
  'Punsit': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Pyrities': { 'great_grou':'Eutrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'FSL', 'mod2':'GR', 'sstxt':'L', 'line':'0', 'pos':2, 'sym':''},
  'Quetico': { 'great_grou':'Udorthent', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'', 'stxt':'FSL', 'mod2':'R', 'sstxt':'SL', 'line':'0', 'pos':1.5, 'sym':''},
  'Quetico-rock ou': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1.5, 'sym':''},
  'Raquette': { 'great_grou':'Udorthent', 'li':'M', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'0', 'pos':4, 'sym':''},
  'Rawsonville': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'W', 'mod1':'RV', 'stxt':'SL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Rawsonville-bes': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'W', 'mod1':'RV', 'stxt':'SL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Rayne': { 'great_grou':'Hapludult', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CH', 'stxt':'SIL', 'mod2':'BY', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Raynham': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':3, 'dr':'PS', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'103', 'pos':4.5, 'sym':'G'},
  'Raypol': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':5, 'sym':''},
  'Red hook': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'GR', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'98', 'pos':4, 'sym':'GW'},
  'Redwater': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':3, 'dr':'MS', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':3.5, 'sym':''},
  'Remsen': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'13', 'pos':4, 'sym':'AW'},
  'Retsof': { 'great_grou':'', 'li':'L', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Rexford': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':4, 'dr':'SP', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'108', 'pos':4.5, 'sym':'GW'},
  'Rhinebeck': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'132', 'pos':4, 'sym':'LW'},
  'Ricker': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Ricker-lyman': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Ridgebury': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'PS', 'mod1':'ST', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'61', 'pos':4.5, 'sym':'E'},
  'Rifle': { 'great_grou':'Borohemist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':7, 'sym':'M'},
  'Riga': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'MW', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'14', 'pos':2.5, 'sym':'A'},
  'Rippowam': { 'great_grou':'Fluvaquent', 'li':'L', 'mgt':4, 'dr':'P', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':5, 'sym':''},
  'Riverhead': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'SL', 'line':'101', 'pos':2, 'sym':'G'},
  'Rockaway': { 'great_grou':'Fragiudult', 'li':'M', 'mgt':2, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'61', 'pos':2, 'sym':'E'},
  'Rock-londonderr': { 'great_grou':'', 'li':'', 'mgt':0, 'dr':'E', 'mod1':'R', 'stxt':'L', 'mod2':'R', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Rock-ricker-hog': { 'great_grou':'', 'li':'L', 'mgt':0, 'dr':'E', 'mod1':'R', 'stxt':'L', 'mod2':'R', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Rock-ricker-lym': { 'great_grou':'', 'li':'L', 'mgt':0, 'dr':'E', 'mod1':'R', 'stxt':'L', 'mod2':'R', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Romulus': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'12', 'pos':5, 'sym':'AW'},
  'Ross': { 'great_grou':'Hapludoll', 'li':'M', 'mgt':2, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Roundabout': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'SP', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4.5, 'sym':''},
  'Rumney': { 'great_grou':'Fluvaquent', 'li':'L', 'mgt':2, 'dr':'SP', 'mod1':'', 'stxt':'SL', 'mod2':'', 'sstxt':'L', 'line':'146', 'pos':4.5, 'sym':'GW'},
  'Runeberg': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'PV', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5.5, 'sym':''},
  'Ruse': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':4, 'dr':'PV', 'mod1':'GR', 'stxt':'L', 'mod2':'R', 'sstxt':'L', 'line':'0', 'pos':5.5, 'sym':''},
  'Rushford': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Saco': { 'great_grou':'Humaquept', 'li':'M', 'mgt':3, 'dr':'V', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'SIL', 'line':'147', 'pos':6, 'sym':'G'},
  'Salamanca': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Salmon': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'ST', 'stxt':'VFSL', 'mod2':'', 'sstxt':'VFSL', 'line':'140', 'pos':2, 'sym':'G'},
  'Saprists': { 'great_grou':'', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'MK', 'line':'0', 'pos':7, 'sym':'M'},
  'Saugatuck': { 'great_grou':'Haplaquod', 'li':'L', 'mgt':5, 'dr':'SP', 'mod1':'', 'stxt':'S', 'mod2':'', 'sstxt':'S', 'line':'119', 'pos':4.5, 'sym':'KW'},
  'Scantic': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':2, 'dr':'P', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'SICL', 'line':'128', 'pos':5, 'sym':'LW'},
  'Scarboro': { 'great_grou':'Humaquept', 'li':'M', 'mgt':4, 'dr':'P', 'mod1':'MK', 'stxt':'FSL', 'mod2':'', 'sstxt':'SL', 'line':'89', 'pos':5, 'sym':'GW'},
  'Schoharie': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':1, 'dr':'MW', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'134', 'pos':2.5, 'sym':'L'},
  'Schroon': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':5, 'dr':'MW', 'mod1':'', 'stxt':'FSL', 'mod2':'BY', 'sstxt':'FSL', 'line':'0', 'pos':2.5, 'sym':''},
  'Schuyler': { 'great_grou':'Dystrochrept', 'li':'M', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'53', 'pos':3, 'sym':'D'},
  'Scio': { 'great_grou':'Dystrochrept', 'li':'M', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'103', 'pos':3, 'sym':'G'},
  'Scituate': { 'great_grou':'Fragiochrept', 'li':'M', 'mgt':4, 'dr':'M', 'mod1':'ST', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'L', 'line':'59', 'pos':3, 'sym':'E'},
  'Scriba': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'GR', 'stxt':'SIL', 'mod2':'ST', 'sstxt':'SIL', 'line':'68', 'pos':4, 'sym':'EW'},
  'Searsport': { 'great_grou':'Humaquept', 'li':'M', 'mgt':4, 'dr':'P', 'mod1':'MK', 'stxt':'FSL', 'mod2':'', 'sstxt':'SL', 'line':'0', 'pos':5, 'sym':''},
  'Shaker': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':2, 'dr':'SP', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':4.5, 'sym':''},
  'Shoreham': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'V', 'mod1':'MK', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'128', 'pos':6, 'sym':'L'},
  'Sisk': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'V', 'mod1':'BYV', 'stxt':'SL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':6, 'sym':''},
  'Skerry': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':5, 'dr':'SM', 'mod1':'ST', 'stxt':'FSL', 'mod2':'BY', 'sstxt':'FSL', 'line':'77', 'pos':3.5, 'sym':'FW'},
  'Sloan': { 'great_grou':'Haplaquoll', 'li':'M', 'mgt':3, 'dr':'V', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'151', 'pos':6, 'sym':'G'},
  'Sodus': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'FSL', 'mod2':'GR', 'sstxt':'L', 'line':'68', 'pos':2, 'sym':'E'},
  'Somerset': { 'great_grou':'', 'li':'M', 'mgt':5, 'dr':'SP', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'LS', 'line':'89', 'pos':4.5, 'sym':'GW'},
  'St johns': { 'great_grou':'', 'li':'M', 'mgt':4, 'dr':'P', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'SL', 'line':'89', 'pos':5, 'sym':'GW'},
  'Staatsburg': { 'great_grou':'', 'li':'H', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'FSL', 'line':'33', 'pos':2, 'sym':'C'},
  'Stafford': { 'great_grou':'Psammaquent', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LFS', 'line':'90', 'pos':4, 'sym':'GW'},
  'Steamburg': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Stetson': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'S', 'line':'121', 'pos':2, 'sym':'K'},
  'Stissing': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':4, 'dr':'SP', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'63', 'pos':4.5, 'sym':'EW'},
  'Stockbridge': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':3, 'dr':'W', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'SIL', 'line':'31', 'pos':2, 'sym':'C'},
  'Stockholm': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'SP', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4.5, 'sym':''},
  'Stowe': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'0', 'pos':1.5, 'sym':''},
  'Sudbury': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'MS', 'mod1':'GR', 'stxt':'FSL', 'mod2':'GR', 'sstxt':'SL', 'line':'95', 'pos':3.5, 'sym':'G'},
  'Suffield': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'132', 'pos':3, 'sym':'L'},
  'Summerville': { 'great_grou':'', 'li':'H', 'mgt':4, 'dr':'E', 'mod1':'R', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Sun': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':4, 'dr':'VP', 'mod1':'', 'stxt':'L', 'mod2':'ST', 'sstxt':'SIL', 'line':'1', 'pos':5.5, 'sym':'AW'},
  'Sunapee': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''},
  'Suncook': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'SL', 'line':'141', 'pos':1, 'sym':'G'},
  'Suny': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'PV', 'mod1':'STV', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5.5, 'sym':''},
  'Surplus': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'V', 'mod1':'BYV', 'stxt':'L', 'mod2':'BYV', 'sstxt':'', 'line':'', 'pos':6, 'sym':''},
  'Surplus-sisk': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'V', 'mod1':'BYV', 'stxt':'L', 'mod2':'BYV', 'sstxt':'', 'line':'', 'pos':6, 'sym':''},
  'Sutton': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'ST', 'stxt':'FSL', 'mod2':'', 'sstxt':'SIL', 'line':'44', 'pos':3, 'sym':'D'},
  'Swanton': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':4, 'dr':'SP', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'126', 'pos':4.5, 'sym':'LW'},
  'Swartswood': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'L', 'line':'64', 'pos':2, 'sym':'E'},
  'Swormville': { 'great_grou':'Ochraqualf', 'li':'L', 'mgt':1, 'dr':'S', 'mod1':'', 'stxt':'CL', 'mod2':'', 'sstxt':'CL', 'line':'0', 'pos':4, 'sym':''},
  'Taconic': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'RV', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Taconic-macombe': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'RV', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Tawas': { 'great_grou':'Borosaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'L', 'line':'0', 'pos':7, 'sym':'M'},
  'Teel': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':2, 'dr':'MS', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'150', 'pos':3.5, 'sym':'G'},
  'Tioga': { 'great_grou':'Eutrochrept', 'li':'M', 'mgt':3, 'dr':'W', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'SIL', 'line':'148', 'pos':2, 'sym':'G'},
  'Toledo': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':2, 'dr':'VP', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'132', 'pos':5.5, 'sym':'LW'},
  'Tonawanda': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'132', 'pos':4, 'sym':'LW'},
  'Tor': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'SP', 'mod1':'BYV', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4.5, 'sym':''},
  'Torull': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Towerville': { 'great_grou':'Dystrochrept', 'li':'M', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':3, 'sym':''},
  'Trestle': { 'great_grou':'Dystrochrept', 'li':'M', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Trout river': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'CB', 'stxt':'LS', 'mod2':'CB', 'sstxt':'LS', 'line':'116', 'pos':1, 'sym':'K'},
  'Troy': { 'great_grou':'Fragiudalf', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'21', 'pos':3, 'sym':'B'},
  'Trumbull': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'13', 'pos':5.5, 'sym':'AW'},
  'Tughill': { 'great_grou':'Humaquept', 'li':'L', 'mgt':4, 'dr':'V', 'mod1':'ST', 'stxt':'L', 'mod2':'ST', 'sstxt':'SIL', 'line':'72', 'pos':6, 'sym':'F'},
  'Tuller': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'38', 'pos':4, 'sym':'DW'},
  'Tunbridge': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'RV', 'stxt':'L', 'mod2':'R', 'sstxt':'L', 'line':'0', 'pos':2, 'sym':''},
  'Tunbridge-adiro': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'RV', 'stxt':'L', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Tunkhannock': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'L', 'line':'99', 'pos':2, 'sym':'G'},
  'Turin': { 'great_grou':'', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'2', 'pos':4, 'sym':'AW'},
  'Tuscarora': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'SL', 'mod2':'', 'sstxt':'SL', 'line':'0', 'pos':3, 'sym':''},
  'Unadilla': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'103', 'pos':2, 'sym':'G'},
  'Valois': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'SIL', 'line':'48', 'pos':2, 'sym':'D'},
  'Varick': { 'great_grou':'Ochraqualf', 'li':'M', 'mgt':2, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'10', 'pos':5, 'sym':'AW'},
  'Varysburg': { 'great_grou':'Hapludalf', 'li':'L', 'mgt':2, 'dr':'WM', 'mod1':'GR', 'stxt':'L', 'mod2':'GR', 'sstxt':'SIL', 'line':'130', 'pos':2.5, 'sym':'L'},
  'Venango': { 'great_grou':'Fragiaqualf', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'GR', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'21', 'pos':4, 'sym':'BW'},
  'Vergennes': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':1, 'dr':'M', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'C', 'line':'136', 'pos':3, 'sym':'L'},
  'Vly': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CNV', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Volusia': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'66', 'pos':4, 'sym':'EW'},
  'Waddington': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'GR', 'stxt':'SL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Wainola': { 'great_grou':'', 'li':'L', 'mgt':5, 'dr':'S', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Wakeland': { 'great_grou':'Fluvaquent', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':4, 'sym':''},
  'Wakeville': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4, 'sym':''},
  'Wallace': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'S', 'mod2':'', 'sstxt':'SL', 'line':'118', 'pos':1, 'sym':'K'},
  'Wallington': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':3, 'dr':'SP', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'129', 'pos':4.5, 'sym':'LW'},
  'Wallkill': { 'great_grou':'Fluvaquent', 'li':'M', 'mgt':3, 'dr':'V', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'142', 'pos':6, 'sym':'G'},
  'Walpole': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':4, 'dr':'SP', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'SL', 'line':'95', 'pos':4.5, 'sym':'GW'},
  'Walton': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'L', 'line':'67', 'pos':2, 'sym':'E'},
  'Wampsville': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':3, 'dr':'W', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'113', 'pos':2, 'sym':'H'},
  'Wappinger': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'143', 'pos':2, 'sym':'G'},
  'Wareham': { 'great_grou':'Psammaquent', 'li':'M', 'mgt':5, 'dr':'SP', 'mod1':'', 'stxt':'LFS', 'mod2':'', 'sstxt':'LS', 'line':'89', 'pos':4.5, 'sym':'GW'},
  'Warners': { 'great_grou':'Haplaquoll', 'li':'M', 'mgt':3, 'dr':'VP', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'SIL', 'line':'152', 'pos':5.5, 'sym':'M'},
  'Wassaic': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':4, 'dr':'WM', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SL', 'line':'3', 'pos':2.5, 'sym':'A'},
  'Watchaug': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'50', 'pos':3, 'sym':'D'},
  'Waumbeck': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'BYV', 'stxt':'FSL', 'mod2':'BY', 'sstxt':'FSL', 'line':'72', 'pos':3, 'sym':'F'},
  'Waumbeck-hermon': { 'great_grou':'', 'li':'', 'mgt':0, 'dr':'WE', 'mod1':'BYV', 'stxt':'FSL', 'mod2':'BY', 'sstxt':'', 'line':'', 'pos':8, 'sym':''},
  'Wayland': { 'great_grou':'Fluvaquent', 'li':'M', 'mgt':2, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'144', 'pos':5.5, 'sym':'GW'},
  'Weaver': { 'great_grou':'Eutrochrept', 'li':'H', 'mgt':3, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'152', 'pos':3, 'sym':'M'},
  'Wegatchie': { 'great_grou':'Haplaquept', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'MK', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':5, 'sym':''},
  'Wellsboro': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'67', 'pos':3, 'sym':'E'},
  'Wenonah': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':2, 'sym':''},
  'Westbury': { 'great_grou':'Fragiaquod', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'ST', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'L', 'line':'78', 'pos':4, 'sym':'FW'},
  'Westland': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':2, 'dr':'V', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'112', 'pos':6, 'sym':'H'},
  'Wethersfield': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'L', 'line':'65', 'pos':2, 'sym':'E'},
  'Wharton': { 'great_grou':'Hapludult', 'li':'L', 'mgt':2, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'19', 'pos':3, 'sym':'A'},
  'Whately': { 'great_grou':'Haplaquept', 'li':'M', 'mgt':4, 'dr':'V', 'mod1':'', 'stxt':'FSL', 'mod2':'', 'sstxt':'FSL', 'line':'126', 'pos':6, 'sym':'L'},
  'Whippany': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':2, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'C', 'line':'135', 'pos':4, 'sym':'LW'},
  'Whitelaw': { 'great_grou':'', 'li':'M', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'SL', 'mod2':'', 'sstxt':'SL', 'line':'1', 'pos':2, 'sym':'A'},
  'Whitman': { 'great_grou':'Fragiaquept', 'li':'L', 'mgt':4, 'dr':'V', 'mod1':'ST', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'L', 'line':'61', 'pos':6, 'sym':'E'},
  'Wilbraham': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'S', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'L', 'line':'65', 'pos':4, 'sym':'EW'},
  'Willdin': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'MW', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'0', 'pos':2.5, 'sym':''},
  'Willette': { 'great_grou':'Medisaprist', 'li':'0', 'mgt':9, 'dr':'V', 'mod1':'MK', 'stxt':'MK', 'mod2':'', 'sstxt':'IL', 'line':'157', 'pos':7, 'sym':'M'},
  'Williamson': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'VFSL', 'mod2':'', 'sstxt':'SIL', 'line':'129', 'pos':3, 'sym':'L'},
  'Willowemoc': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'0', 'pos':3, 'sym':''},
  'Wilmington': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'P', 'mod1':'BYV', 'stxt':'SL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':5, 'sym':''},
  'Wilpoint': { 'great_grou':'Hapludalf', 'li':'M', 'mgt':1, 'dr':'M', 'mod1':'', 'stxt':'SICL', 'mod2':'', 'sstxt':'SICL', 'line':'138', 'pos':3, 'sym':'L'},
  'Windsor': { 'great_grou':'Udipsamment', 'li':'L', 'mgt':5, 'dr':'E', 'mod1':'', 'stxt':'LS', 'mod2':'', 'sstxt':'LS', 'line':'89', 'pos':1, 'sym':'G'},
  'Winooski': { 'great_grou':'Udifluvent', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'147', 'pos':3, 'sym':'G'},
  'Wolcottsburg': { 'great_grou':'', 'li':'M', 'mgt':1, 'dr':'PV', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SICL', 'line':'13', 'pos':5.5, 'sym':'AW'},
  'Woodbridge': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'', 'stxt':'L', 'mod2':'', 'sstxt':'SIL', 'line':'61', 'pos':3, 'sym':'E'},
  'Woodland': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'SH', 'stxt':'SIL', 'mod2':'SH', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Woodlawn': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'WE', 'mod1':'SH', 'stxt':'SIL', 'mod2':'SH', 'sstxt':'SIL', 'line':'0', 'pos':1.5, 'sym':''},
  'Woodstock': { 'great_grou':'Haplorthod', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'L', 'line':'0', 'pos':1, 'sym':''},
  'Woodstock-rock': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'E', 'mod1':'', 'stxt':'', 'mod2':'', 'sstxt':'', 'line':'', 'pos':1, 'sym':''},
  'Wooster': { 'great_grou':'Fragiudalf', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'CN', 'stxt':'SIL', 'mod2':'CN', 'sstxt':'SIL', 'line':'66', 'pos':2, 'sym':'E'},
  'Woostern': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'0', 'pos':2, 'sym':''},
  'Woostern-bath-v': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'W', 'mod1':'GR', 'stxt':'SIL', 'mod2':'GR', 'sstxt':'SIL', 'line':'', 'pos':2, 'sym':''},
  'Worden': { 'great_grou':'', 'li':'L', 'mgt':4, 'dr':'SP', 'mod1':'BYV', 'stxt':'SL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':4.5, 'sym':''},
  'Worth': { 'great_grou':'Fragiorthod', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'ST', 'stxt':'FSL', 'mod2':'ST', 'sstxt':'L', 'line':'78', 'pos':2, 'sym':'F'},
  'Wurtsboro': { 'great_grou':'Fragiochrept', 'li':'L', 'mgt':4, 'dr':'M', 'mod1':'GR', 'stxt':'L', 'mod2':'ST', 'sstxt':'L', 'line':'64', 'pos':3, 'sym':'E'},
  'Wyalusing': { 'great_grou':'Fluvaquent', 'li':'L', 'mgt':3, 'dr':'P', 'mod1':'', 'stxt':'SIL', 'mod2':'', 'sstxt':'SIL', 'line':'0', 'pos':5, 'sym':''},
  'Yalesville': { 'great_grou':'Dystrochrept', 'li':'L', 'mgt':4, 'dr':'W', 'mod1':'', 'stxt':'SL', 'mod2':'', 'sstxt':'SL', 'line':'51', 'pos':2, 'sym':'D'},
  'Yorkshire': { 'great_grou':'', 'li':'L', 'mgt':3, 'dr':'M', 'mod1':'CN', 'stxt':'SIL', 'mod2':'', 'sstxt':'', 'line':'', 'pos':3, 'sym':''}
};

export default soilInfo;