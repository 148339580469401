import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles (() => ({
  footerCont: {
    color: 'white',
    padding: '8px',
    backgroundColor: 'rgb(0,60,0)'
  },
  footerText: {
    width: 'fit-content',
    margin: '0 auto',
    fontSize: '12px',
    color: 'white'
  },
  footerSubText: {
    width: 'fit-content',
    margin: '0 auto',
    fontSize: '10px',
    color: 'white'
  },
  contact: {
    textDecoration: 'none',
    color: 'white',
    fontWeight: 'bold'
  }
}));

export default function Footer() {
  let classes = useStyles();
  
  return (
    <footer className={classes.footerCont}>
      <div className={classes.footerText}>Questions or Concerns? <a href='mailto:gollands@cornell.edu?subject=Attn:%20Forages.org%20query' className={classes.contact}>Contact us</a></div>
      {/* <div className={classes.footerSubText}>&copy;Organization. All rights reserved.</div> */}
    </footer>
  );
}