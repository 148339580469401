const soilRemaps = {
  'Bergen': 'Henrietta',
  'Buchanan': 'Mardin',
  'Burnt Vly': 'Dawson',
  'Cardigan': 'Yalesville',
  'Ceres': 'Carrollton',
  'Covertfalls': 'Covert',
  'Deinache': 'Cook',
  'Eldred': 'Yorkshire',
  'Elko': 'Yorkshire',
  'Factoryville': 'Windsor',
  'Geneseo': 'Hamlin',
  'Guyanoga': 'Herkimer',
  'Hemlock': 'Teel',
  'Henniker': 'Stowe',
  'Highmarket': 'Monadnock',
  'Lyonmounten': 'Burnham',
  'Metacomet': 'Middlebrook',
  'Naples Creek': 'Wakeville',
  'Northway': 'Pipestone',
  'Oatka': 'Rhinebeck',
  'Occur': 'Covert',
  'Pavilion': 'Menlo',
  'Peasleeville': 'Mino',
  'Portville': 'Venango',
  'Sciota': 'Elnora',
  'Sheddenbrook': 'Fahey',
  'Shongo': 'Napoli',
  'Wiscoy': 'Volusia'
};

export default soilRemaps;