import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { 
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListSubheader
} from '@material-ui/core';



export default function CustomSelector({ infoObj, selected, setSelected }) {
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`${infoObj.label.replaceAll(' ', '-')}-label`} style={{fontSize: '14px'}}>{infoObj.label}</InputLabel>
      <Select
        labelId={`${infoObj.label.replaceAll(' ', '-')}-label`}
        value={selected}
        label={infoObj.label}
        onChange={handleChange}
        style={{ textAlign: 'center', fontSize: '14px' }}
      >
        {
          infoObj.items.map(item => {
            let text = <div style={{display: 'flex', justifyContent: 'space-between', width: '154px'}}>
              <div>{item.value}</div>
              <div>({item.percent}%)</div>
            </div>;
            
            return (
              item.value === 'break' ? 
                <ListSubheader key={uuidv4()} style={{lineHeight: '15px', paddingTop: '10px'}}>{text}</ListSubheader>
                :
                <MenuItem key={uuidv4()} value={item.value}>{text}</MenuItem>
            );
          })
        }
      </Select>
    </FormControl>
  );
}




CustomSelector.propTypes = {
  infoObj: PropTypes.object,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};