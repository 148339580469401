import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Modal, Typography } from '@material-ui/core';

import ForageSpeciesSelectorOptions from './ForageSpeciesSelectorOptions';


const useStyles = makeStyles ((theme) => ({
  panelCont: {
    height: 775,
    width: '200px',
    minWidth: '200px',
    boxSizing: 'border-box',
    padding: '6px',
    paddingBottom: 0,
    border: '1px solid black',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: 'white',
    position: 'relative'
  },
  section: {
    padding: '8px 0px',
    borderBottom: `3px double ${theme.palette.primary.light}`
  },
  help: {
    position: 'absolute',
    bottom: 5,
    left: 5,
    color: 'rgb(100,100,255)',
    '&:hover': {
      color: 'rgb(70,70,255)',
      cursor: 'pointer'
    }
  },
  modalContent: {
    backgroundColor: 'white',
    height: 'fit-content',
    maxHeight: '80vh',
    minHeight: '20vh',
    width: '60vw',
    margin: '10vh auto',
    borderRadius: '6px',
    padding: '12px 20px',
    boxSizing: 'border-box',
    overflow: 'auto'
  },
  modalText: {
    width: '80%',
    margin: '10px auto',
    textAlign: 'justify',
    lineHeight: '20px'
  },
  modalEndText: {
    width: '100%',
    marginTop: '20px',
    textAlign: 'justify',
    lineHeight: '20px'
  }
}));



export default function OptionsPanel({ options, soilsAtPlace, className, handleChangeOptions, token, path }) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className={`${classes.panelCont} ${className}`} style={{borderRadius: path === '/' ? '6px 0px 0px 6px' : '6px'}}>
      <ForageSpeciesSelectorOptions
        section={classes.section}
        options={options}
        handleChangeOptions={handleChangeOptions}
        soilsAtPlace={soilsAtPlace}
        token={token}
      />

      <div className={classes.help} onClick={handleOpen}>Need Help?</div>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={classes.modalContent}>
          <Typography variant='h5'>Steps to Get Recommendations</Typography>

          <Typography variant='h6'>1. Select Address</Typography>
          <p className={classes.modalText}>Begin by clicking on the map to select a location or by typing in your address and clicking &apos;Address Search&apos;. This will result in the map zooming into your desired location and displaying a soil map overlay. Each time you change your location a check is performed to see if the desired location is in an area known to be infested with Alfalfa Snout Beetles. If so, a box will pop up asking if you have treated the field with ASB nematodes.</p>
          <Typography variant='h6'>2. Select Soil</Typography>
          <p className={classes.modalText}>Once you have a location selected you will see a dropdown list of soils populate. Select a soil from this list.</p>
          <Typography variant='h6'>3. Select pH</Typography>
          <p className={classes.modalText}>Input the pH level of your soil. This should be the pH recieved from getting your soil tested. If you are not sure what your soil pH is leave this value at 0, this cause the tool to use the native pH of the selected soil.</p>
          <p className={classes.modalText}><b>Note:</b> To ensure the best results using this tool it is recommended that you determine and use your own soil pH.</p>
          <Typography variant='h6'>4. Select Drainage</Typography>
          <p className={classes.modalText}>If you have any artificial drainage solutions, make a selection in from the &apos;Artificial Drainage Situation&apos; selector.</p>
          <Typography variant='h6'>5. Select Forage Use</Typography>
          <p className={classes.modalText}>Select whether your crop will be used for hay/silage, as pasture, or if your intended land use is conservation.</p>
          <Typography variant='h6'>6. Select Intention</Typography>
          <p className={classes.modalText}>If you selected hay/silage or pasture, select the animals that will be consuming the crop. Otherwise, select a conservation goal.</p>

          <p className={classes.modalEndText}>After all of these steps are complete the boxes at the bottom of the page will populate with your recommendations, you may have to scroll down to see them. If you change your selected options these recommendations will automatically update. If you see &apos;No species found for the options selected.&apos; repeatedly, try choosing a different area or a different soil type.</p>

          <p className={classes.modalEndText}>After your options have been selected you can share the URL to directly link others to the results that you see.</p>
        </div>
      </Modal>
    </div>
  );
}



OptionsPanel.propTypes = {
  options: PropTypes.object,
  soilsAtPlace: PropTypes.object,
  handleChangeOptions: PropTypes.func,
  token: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.string
};