import { buffer, point, bbox, polygon, centerOfMass } from '@turf/turf';
import roundXDigits from './rounding';
import soilRemaps from '../StaticData/soilRemaps';
import soilInfo from '../StaticData/soilInfo';

export const getPlaceData = (lat, lon, token) => {
  let results = fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?limit=1&access_token=${token}`, { method: 'GET' })
    .then(response => response.json())
    .catch(e => {
      console.log(e);
      return false;
    });

  return results;
};

export const getCoordinates = async (location, token) => {
  let results = fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${location.replaceAll(' ', '%20')}.json?proximity=-75.37,43.21&limit=1&access_token=${token}`, { method: 'GET' })
    .then(response => response.json())
    .then(jData => jData.features[0].center)
    .catch(e => {
      console.log(e);
      return false;
    });

  return results;
};

const getSoilItem = (arr) => {
  const soilsToRemap = Object.keys(soilRemaps);
  
  let { phWeights, compPercent, nullPh} = arr.reduce((acc, s) => {
    if (!acc.compPercent.includes(s[1])) {
      acc.compPercent.push(s[1]);
    }
    
    if (acc.totalWeight === 1) {
      return acc;
    }
    
    let ph = parseFloat(s[4]);
    let top = parseInt(s[5]);
    let bottom = parseInt(s[6]);

    if (bottom > 20) bottom = 20;

    let weight = (bottom - top) / 20;
    acc.totalWeight += weight;

    if (isNaN(ph)) {
      acc.nullPh += weight;
    } else {
      acc.phWeights.push([ph, weight]);
    }

    return acc;
  }, { phWeights: [], nullPh: 0, totalWeight: 0, compPercent: [] });

  if (nullPh) {
    const portion = nullPh / phWeights.length;
    phWeights = phWeights.map(arr => [arr[0], arr[1] + portion]);
  }

  const weightedPH = phWeights.reduce((acc, arr) => {
    acc += arr[0] * arr[1];
    return acc;
  }, 0);
  compPercent = compPercent.reduce((acc, sNum) => acc + parseInt(sNum), 0);

  return {
    ph: roundXDigits(weightedPH, 1),
    value: soilsToRemap.includes(arr[0][2]) ? soilRemaps[arr[0][2]] : arr[0][2],
    drainageClass: arr[0][3],
    percent: compPercent
  };
};

const consolidateSoilOptions = (soilsList) => {
  let items;
  try {
    if (soilsList.length > 0) {
      let allNames = soilsList.map(arr => arr[2]);
      let uniqueNames = allNames.reduce((acc, name) => {
        if (!acc.includes(name)) {
          acc.push(name);
        }
        
        return acc;
      }, []);
      
      const soilNames = Object.keys(soilInfo).map(name => name.toLowerCase());
      items = uniqueNames.reduce((acc, name) => {
        if (soilNames.includes(name.toLowerCase())) {
          let itemsWithName = soilsList.filter(soil => soil[2] === name);
          itemsWithName.sort((a,b) => parseInt(b[1]) - parseInt(a[1]));
          
          acc.push(getSoilItem(itemsWithName));
        }

        return acc;
      }, []);

      items.sort((a,b) => b.percent - a.percent);
    } else {
      items = [];
    }
  } catch {
    items = [];
  }

  return items;
};

export const getSoilData = (lat, lon) => {
  // LEFT OUTER JOIN component AS c ON mu.mukey = c.mukey AND compkind = 'Series'
  let query = `SELECT mu.mukey, comppct_r, compname, drainagecl, ph1to1h2o_r, hzdept_r, hzdepb_r
  
  FROM legend AS l

  INNER JOIN sacatalog AS sac ON l.areasymbol = sac.areasymbol AND l.areatypename = 'Non-MLRA Soil Survey Area'

  INNER JOIN mapunit AS mu ON l.lkey = mu.lkey
  
  LEFT OUTER JOIN component AS c ON mu.mukey = c.mukey AND (compkind = 'Series' OR compkind = 'Variant')

  INNER JOIN chorizon AS ch ON c.cokey = ch.cokey
  
  WHERE mu.mukey IN (SELECT * from SDA_Get_Mukey_from_intersection_with_WktWgs84('point (${lon} ${lat})')) AND hzdept_r < 20
  
  ORDER BY comppct_r DESC`;


  // SELECT mu.mukey, comppct_r, compname, drainagecl, ph1to1h2o_r, hzdept_r, hzdepb_r
  
  // FROM legend AS l

  // INNER JOIN sacatalog AS sac ON l.areasymbol = sac.areasymbol AND l.areatypename = 'Non-MLRA Soil Survey Area'

  // INNER JOIN mapunit AS mu ON l.lkey = mu.lkey
  
  // LEFT OUTER JOIN component AS c ON mu.mukey = c.mukey AND compkind = 'Series'

  // INNER JOIN chorizon AS ch ON c.cokey = ch.cokey
  
  // WHERE mu.mukey IN (SELECT * from SDA_Get_Mukey_from_intersection_with_WktWgs84('point (-76.452 42.460)')) AND hzdept_r < 20
  
  // ORDER BY comppct_r DESC
  

  let results = fetch('https://SDMDataAccess.sc.egov.usda.gov/Tabular/post.rest', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 
      format: 'JSON',
      query: query
    })
  })
    .then(res => res.json())
    .then(jData => jData.Table)
    .then(soilsList => {
      return { label: 'Soil Type', items: consolidateSoilOptions(soilsList) };
    });

  return results;
};

function bboxQuery(lat, lon) {
  let bboxCoords = bbox(buffer(point([lon, lat]), 1, {units: 'miles'}));
  
  let query = `-- Define a triangular AOI in WGS84
  ~DeclareGeometry(@aoi)~
  select @aoi = geometry::STPolyFromText('polygon((
    ${bboxCoords[0]} ${bboxCoords[1]}, ${bboxCoords[0]} ${bboxCoords[3]},${bboxCoords[2]} ${bboxCoords[3]},${bboxCoords[2]} ${bboxCoords[1]},${bboxCoords[0]} ${bboxCoords[1]}
    ))', 4326)

  -- Extract all intersected polygons
  ~DeclareIdGeomTable(@polygons)~
  ~GetClippedMapunits(@aoi,polygon,geo,@polygons)~

  select * into #aggids from @polygons

  select musym, geom from #aggids inner join mapunit on #aggids.id = mapunit.mukey`;

  let results = fetch('https://SDMDataAccess.sc.egov.usda.gov/Tabular/post.rest', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ 
      format: 'JSON',
      query: query
    })
  })
    .then(res => res.json())
    .then(jData => jData.Table)
    .then(dataArr => {
      return dataArr.reduce((acc,arr) => {
        let strPoly = arr[1].replaceAll('))', '').replace('POLYGON ((', '').split('), (');
        let polyCoords = strPoly.map(str => str.split(', ').map(pair => pair.split(' ').map(val => parseFloat(val))));
        let poly = polygon(polyCoords);
        let point = centerOfMass(poly);
        point.properties.title = arr[0];

        acc.lines.push(poly);
        acc.points.push(point);

        return acc;
      }, { lines: [], points: [] });
    });

  return results;
}

export const getGeojsonData = async (lat, lon) => {
  let results = await bboxQuery(lat, lon);

  let newGeojson = {
    lines : {
      'type': 'FeatureCollection',
      'features': results.lines
    },
    points : {
      'type': 'FeatureCollection',
      'features': results.points
    }
  };

  return newGeojson;
};