import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  makeStyles,
  Modal,
  Box
} from '@material-ui/core';

import speciesHTML from '../StaticData/speciesHTML';

const useStyles = makeStyles (() => ({
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 12,
    '& h1': {
      fontSize: 16,
      marginBottom: 16,
      color: 'rgb(150,0,0)'
    },
    '& h2': {
      fontWeight: 'bold',
      fontSize: 14,
      marginTop: 8,
      marginBottom: 4
    },
    '& ul': {
      listStyleType: 'disc',
      paddingLeft: 30,
    }
  },
  link: {
    fontSize: 14,
    padding: '2px 0px',
    margin: '4px 0px',
    color: 'rgb(150,0,0)',
    borderLeft: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  lists: {
    display: 'flex',
    overflow: 'auto',
    height: 'calc(100vh - 167px)',
    minHeight: '278px',
    boxSizing: 'border-box',
    gap: 6,
    border: '1px solid rgba(100,100,100,0.5)',
    justifyContent: 'space-between',
    padding: '8px 14px'
  },
  title: {
    width: 'fit-content',
    margin: '0 auto',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 6,
    paddingBottom: 2,
    borderBottom: '1px solid black'
  },
  descriptions: {
    backgroundColor: 'white',
    position: 'relative',
    height: 'calc(100vh - 120px)',
    minHeight: '325px',
    width: '100%',
    marginLeft: '6px',
    border: '1px solid black',
    boxSizing: 'border-box',
    borderRadius: '6px',
    padding: '5px 12px'
  }
}));



export default function SpeciesList() {
  const [modalContent, setModalContent] = useState('');
  const [open, setOpen] = useState(false);
  const [species, setSpecies] = useState('');

  const classes = useStyles();


  const handleClose = () => setOpen(false);

  const handleModal = (title) => {
    setSpecies(title);
    setModalContent(speciesHTML[title]);
    setOpen(true);
  };

  const renderList = () => {
    let keys = Object.keys(speciesHTML).sort();
    let list = keys.map(name => <li className={classes.link} key={uuidv4()} onClick={() => handleModal(name)}>{name}</li>);
    let mid = Math.ceil(list.length / 2);
    return (
      <div className={classes.lists}>
        <ul>{list.slice(0,mid)}</ul>
        <ul>{list.slice(mid)}</ul>
      </div>
    );
  };

  
  return (
    <div className={classes.descriptions}>
      <h1 className={classes.title}>Forage Species</h1>

      {renderList()}

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.box}>
          {modalContent !== '' && modalContent.props.children.type === 'ul' && <>
            <h1>Combination of Species</h1>
            <h2>{species}</h2>
          </>}
          {modalContent}
        </Box>
      </Modal>
    </div>
  );
}