import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Button } from '@material-ui/core';

import TextResults from './TextResults';

import roundXDigits from '../Functions/rounding';

const useStyles = makeStyles((theme) => ({
  phDisclaimer: {
    color: 'red',
    textAlign: 'center',
    padding: '6px',
    fontSize: '14px',
    backgroundColor: 'white',
    border: '1px solid black',
    width: 500,
    margin: '0 auto',
  },
  warning: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  cornYield: {
    backgroundColor: 'white',
    border: '1px solid black',
    width: 400,
    margin: '0 auto',
    padding: 6,
    textAlign: 'center',
    fontSize: '14px',
  },
  results: {
    height: '100%',
    width: 'calc(100vw - 40px)',
    minWidth: '513px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 6,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  exportBtn: {
    position: 'fixed',
    bottom: 30,
    right: 3,
    width: 133,
    backgroundColor: 'rgb(209,6,6)',
    color: 'white',
    border: '2px solid #740606',
    fontFamily:
      '"-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"',
    '&:hover': {
      backgroundColor: 'rgb(130,0,0)',
    },
  },
  snout: {
    backgroundColor: 'white',
    border: '1px solid black',
    width: '75%',
    minWidth: 400,
    margin: '0 auto',
    padding: 6,
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'italic',
  },
  yieldDisclaimer: {
    backgroundColor: 'white',
    border: '1px solid black',
    padding: '6px',
    textAlign: 'center',
    fontSize: '14px',
    width: 400,
    margin: '0 auto',
  },
}));

export default function FSSResults({
  options,
  isResults,
  handleExport,
  getPHUsed,
  speciesResults,
  resultContainer,
}) {
  const classes = useStyles();

  let ph;
  try {
    ph = getPHUsed();
  } catch {
    ph = false;
  }

  return (
    <>
      {options.ph === '0' && ph && (
        <div className={classes.phDisclaimer}>
          <span className={classes.warning}>WARNING</span>
          <br />
          The tool is using the native &apos;Soil pH&apos;{ph} for &apos;
          {options.soil}&apos; soil at the selected location to determine these
          recommendations. Please be warned that accurate soil pH is important
          for this tool to provide accurate suggestions. Consider getting your
          soil pH tested to improve your experience with this tool.
        </div>
      )}

      <div className={classes.results} ref={resultContainer}>
        <TextResults
          speciesList={speciesResults.recommendedArr}
          landUse={options.landUse}
          title='Recommended species for this application:'
        />

        <TextResults
          speciesList={speciesResults.acceptableArr}
          landUse={options.landUse}
          title='Acceptable but not recommended species for this application:'
        />
      </div>

      {speciesResults.cornGrainYield >= 0 && isResults && (
        <div className={classes.cornYield}>
          As a reference, the corn yield index for this soil is{' '}
          <b>
            {roundXDigits(speciesResults.cornGrainYield, 0)} bushels per acre
          </b>{' '}
          or <b>{roundXDigits(speciesResults.cornSilageYield, 1)} tons</b> of
          corn silage per acre.
          <br />
          <hr />
          <i>
            Corn grain and silage yield potential data supplied by the Nutrient
            Management Spear Program, Cornell University, last updated 2022
          </i>
        </div>
      )}

      {(options.landUse === 'pasture' || options.landUse === 'feed') &&
        isResults && (
          <div className={classes.yieldDisclaimer}>
            <i>
              * Yields listed here are approximate and reflect forage trial
              results under ideal conditions.
            </i>
          </div>
        )}

      {speciesResults.warning &&
        isResults &&
        (speciesResults.warning === 1 ? (
          <div className={classes.snout}>
            <b>Note:</b> Alfalfa is included in the acceptable list, but you
            should be aware that there is a possibility of Snout Beetle
            infestation in this area.
          </div>
        ) : (
          <div className={classes.snout}>
            <b>Note:</b> Alfalfa would normally be included in the recommended
            list, but due to possible Snout Beetle infestation in this area, it
            has been moved to the acceptable but not recommended list. While the
            Alfalfa species are included in this list, be aware that they still
            may not be suitable given the presence of Snout Beetles.
          </div>
        ))}

      {speciesResults.recommendedArr.length !== 0 &&
        speciesResults.recommendedArr[0] !== 'error' &&
        speciesResults.recommendedArr[0] !== 'not found' && (
          <Button className={classes.exportBtn} onClick={handleExport}>
            Export Results as PDF
          </Button>
        )}
    </>
  );
}

FSSResults.propTypes = {
  options: PropTypes.object,
  speciesResults: PropTypes.object,
  isResults: PropTypes.bool,
  handleExport: PropTypes.func,
  getPHUsed: PropTypes.func,
  resultContainer: PropTypes.object,
};
