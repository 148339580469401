const combo = {
  '11': { 'species1':0.7, 'species2':0.3, 'species3':0, 'species4':0},
  '4': { 'species1':0.5, 'species2':0.5, 'species3':0, 'species4':0},
  '8': { 'species1':0.7, 'species2':0.3, 'species3':0, 'species4':0},
  '10': { 'species1':0.6, 'species2':0.4, 'species3':0, 'species4':0},
  '81': { 'species1':0.6, 'species2':0.4, 'species3':0, 'species4':0},
  '9': { 'species1':0.7, 'species2':0.3, 'species3':0, 'species4':0},
  '7': { 'species1':0.6, 'species2':0.4, 'species3':0, 'species4':0},
  '2': { 'species1':0.6, 'species2':0.1, 'species3':0.3, 'species4':0},
  '3': { 'species1':0.6, 'species2':0.1, 'species3':0.3, 'species4':0},
  '5': { 'species1':0.4, 'species2':0.5, 'species3':0.1, 'species4':0},
  '12': { 'species1':0.5, 'species2':0.2, 'species3':0.2, 'species4':0.1},
  '6': { 'species1':0.4, 'species2':0.3, 'species3':0.2, 'species4':0.1},
  '16': { 'species1':0.5, 'species2':0.5, 'species3':0, 'species4':0},
  '20': { 'species1':0.3, 'species2':0.7, 'species3':0, 'species4':0},
  '23': { 'species1':0.4, 'species2':0.6, 'species3':0, 'species4':0},
  '22': { 'species1':0.3, 'species2':0.7, 'species3':0, 'species4':0},
  '18': { 'species1':0.3, 'species2':0.7, 'species3':0, 'species4':0},
  '21': { 'species1':0.3, 'species2':0.7, 'species3':0, 'species4':0},
  '17': { 'species1':0.4, 'species2':0.6, 'species3':0, 'species4':0},
  '24': { 'species1':0.3, 'species2':0.5, 'species3':0.2, 'species4':0},
  '19': { 'species1':0.3, 'species2':0.6, 'species3':0.1, 'species4':0},
  '29': { 'species1':0.3, 'species2':0.7, 'species3':0, 'species4':0},
  '30': { 'species1':0.3, 'species2':0.7, 'species3':0, 'species4':0},
  '31': { 'species1':0.4, 'species2':0.6, 'species3':0, 'species4':0},
  '45': { 'species1':0.3, 'species2':0.7, 'species3':0, 'species4':0},
  '55': { 'species1':0.4, 'species2':0.6, 'species3':0, 'species4':0},
  '51': { 'species1':0.2, 'species2':0.3, 'species3':0.5, 'species4':0},
  '49': { 'species1':0.2, 'species2':0.3, 'species3':0.1, 'species4':0.4},
  '43': { 'species1':0.2, 'species2':0.1, 'species3':0.2, 'species4':0.5},
  '47': { 'species1':0.2, 'species2':0.3, 'species3':0.1, 'species4':0.4},
  '46': { 'species1':0.1, 'species2':0.3, 'species3':0.1, 'species4':0.5},
  '50': { 'species1':0.1, 'species2':0.3, 'species3':0.4, 'species4':0.2},
  '52': { 'species1':0.1, 'species2':0.3, 'species3':0.2, 'species4':0.4},
  '48': { 'species1':0.1, 'species2':0.3, 'species3':0.2, 'species4':0.4},
  '44': { 'species1':0.2, 'species2':0.3, 'species3':0.5, 'species4':0},
  '53': { 'species1':0.2, 'species2':0.5, 'species3':0.3, 'species4':0},
  '54': { 'species1':0.2, 'species2':0.3, 'species3':0.5, 'species4':0},
  '65': { 'species1':0.5, 'species2':0.5, 'species3':0, 'species4':0},
  '64': { 'species1':0.4, 'species2':0.6, 'species3':0, 'species4':0},
  '63': { 'species1':0.4, 'species2':0.1, 'species3':0.5, 'species4':0},
  '75': { 'species1':0.4, 'species2':0.6, 'species3':0, 'species4':0},
  '76': { 'species1':0.3, 'species2':0.7, 'species3':0, 'species4':0},
  '77': { 'species1':0.4, 'species2':0.6, 'species3':0, 'species4':0},
  '40': { 'species1':0.2, 'species2':0.5, 'species3':0.3, 'species4':0}
};

export default combo;